import styled from 'styled-components/macro';
import SearchResultsItem from 'components/features/search/results/item/SearchResultsItem';
import NameTile from 'components/common/nameTile/NameTile';
import { NameTileCaptionText } from 'components/common/nameTile/NameTileStyles';

const SearchResultsCategoryCompaniesItem = styled(SearchResultsItem)`
  gap: 20px;
`;
const SearchResultsCategoryCompaniesItemTile = styled(NameTile)`
  width: calc(40% - 60px);
  margin-right: auto;
`;
const SearchResultsCategoryCompaniesItemStatus = styled(NameTile)`
  width: 20%;
`;
const SearchResultsCategoryCompaniesItemEndDate = styled(NameTile)`
  width: 20%;
`;
// TODO Вынести в какой-то отдельный элемент код с ${NameTileCaptionText}?!
const SearchResultsCategoryCompaniesItemSync = styled(NameTile)`
  width: 20%;
  ${NameTileCaptionText} {
    overflow: unset;
  }
`;

export {
  SearchResultsCategoryCompaniesItem,
  SearchResultsCategoryCompaniesItemTile,
  SearchResultsCategoryCompaniesItemStatus,
  SearchResultsCategoryCompaniesItemEndDate,
  SearchResultsCategoryCompaniesItemSync,
};
