import styled, { css } from 'styled-components/macro';
import { emergenceFromBottomKeyframes, emergenceFromRightKeyframes } from 'helpers/keyframes';
import { AnimationSide, IAnimationSC } from './AnimationTypes';

const Animation = styled.div<IAnimationSC>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  ${({ side }) => {
    const ani = (() => {
      switch (side) {
        case AnimationSide.RIGHT: return emergenceFromRightKeyframes;
        default: return emergenceFromBottomKeyframes;
      }
    })();
    return css`
      animation: ${ani} .25s linear;
    `;
  }};
  z-index: 100;
  & > * {
    pointer-events: auto;
  }
`;

export default Animation;
