import styled from 'styled-components/macro';

const ExportCompaniesTypesFieldsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 10px;
  & > * {
    width: calc((100% - 20px) / 3);
  }
`;

export {
  ExportCompaniesTypesFieldsContainer,
};
