const AnimationSide = {
  BOTTOM: 'bottom',
  RIGHT: 'right',
} as const;
type IAnimationSide = typeof AnimationSide[keyof typeof AnimationSide];
interface IAnimationSC {
  side?: IAnimationSide
}

export {
  AnimationSide,
};

export type {
  IAnimationSC,
};
