import { FunctionComponent, SVGProps } from 'react';
import { ReactComponent as UsersGroupsIcon } from 'media/icons/users.svg';
import { ReactComponent as UsersUsersIcon } from 'media/icons/user-circle.svg';
import { ReactComponent as BillingContributionsIcon } from 'media/icons/sale.svg';
import { ReactComponent as BillingCompanyCategoriesIcon } from 'media/icons/tag.svg';
import { ReactComponent as GeoCountriesIcon } from 'media/icons/countries.svg';
import { ReactComponent as MarkerPingIcon } from 'media/icons/marker-pin.svg';
import { ReactComponent as FlagIcon } from 'media/icons/flag.svg';
import { ReactComponent as FileCheckIcon } from 'media/icons/file-check.svg';
import { ReactComponent as FileIcon } from 'media/icons/file.svg';
import { ReactComponent as DefaultIcon } from 'media/icons/settings-default.svg';
import { ReactComponent as BanksIcon } from 'media/icons/banks.svg';
import { ITabsList } from 'components/common/tabs/TabsTypes';

const SettingsAlias = {
  USERS: 'users',
  NOTIFICATIONS: 'notifications',
  BILLING: 'billing',
  GEO: 'geo',
  SYSTEM: 'system',
} as const;
type ISettingsAlias = typeof SettingsAlias[keyof typeof SettingsAlias];
const SettingsSubAlias = {
  USERS_GROUPS: 'groups',
  USERS_USERS: 'users',
  NOTIFICATIONS_SMS: 'sms',
  BILLING_CONTRIBUTIONS: 'contributions',
  BILLING_COMPANY_CATEGORIES: 'company-categories',
  GEO_COUNTRIES: 'countries',
  GEO_REGIONS: 'regions',
  GEO_CITIES: 'cities',
  GEO_DISTRICTS: 'districts',
  GEO_BRANCHES: 'branches',
  SYSTEM_AGREEMENT: 'agreement',
  SYSTEM_CONTRACTS: 'contracts',
  SYSTEM_OWNERSHIPS: 'ownerships',
  SYSTEM_BUSINESS_ACTIVITIES: 'business-activities',
  SYSTEM_ECONOMIC_ACTIVITIES: 'economic-activities',
  SYSTEM_PRODUCT_TYPES: 'product-types',
  SYSTEM_BANKS: 'banks',
} as const;
type ISettingsSubAlias = typeof SettingsSubAlias[keyof typeof SettingsSubAlias];
interface ISettingsListItemSub {
  label: string
  description: string
  value: ISettingsSubAlias
  icon: FunctionComponent<SVGProps<SVGSVGElement>>
}
interface ISettingsListItem {
  label: string
  value: ISettingsAlias
  sub: ISettingsListItemSub[]
}

const settingsList: ISettingsListItem[] = [
  {
    label: 'Пользователи',
    value: SettingsAlias.USERS,
    sub: [
      {
        label: 'Группы пользователей',
        description: 'Настройка прав доступа целой группе людей',
        icon: UsersGroupsIcon,
        value: SettingsSubAlias.USERS_GROUPS,
      },
      {
        label: 'Пользователи',
        description: 'Управление пользователями системы',
        icon: UsersUsersIcon,
        value: SettingsSubAlias.USERS_USERS,
      },
    ],
  },
  {
    label: 'Биллинг',
    value: SettingsAlias.BILLING,
    sub: [
      {
        label: 'Взносы',
        description: 'Настройка типа и суммы взноса',
        icon: BillingContributionsIcon,
        value: SettingsSubAlias.BILLING_CONTRIBUTIONS,
      },
      {
        label: 'Категории предприятий',
        description: 'Список типов предприятий',
        icon: BillingCompanyCategoriesIcon,
        value: SettingsSubAlias.BILLING_COMPANY_CATEGORIES,
      },
    ],
  },
  {
    label: 'ГЕО данные',
    value: SettingsAlias.GEO,
    sub: [
      {
        label: 'Страны',
        description: 'Список стран',
        icon: GeoCountriesIcon,
        value: SettingsSubAlias.GEO_COUNTRIES,
      },
      {
        label: 'Области',
        description: 'Список областей',
        icon: MarkerPingIcon,
        value: SettingsSubAlias.GEO_REGIONS,
      },
      {
        label: 'Города',
        description: 'Список городов',
        icon: MarkerPingIcon,
        value: SettingsSubAlias.GEO_CITIES,
      },
      {
        label: 'Районы',
        description: 'Список районов',
        icon: MarkerPingIcon,
        value: SettingsSubAlias.GEO_DISTRICTS,
      },
      {
        label: 'Филиалы',
        description: 'Список филиалов',
        icon: FlagIcon,
        value: SettingsSubAlias.GEO_BRANCHES,
      },
    ],
  },
  {
    label: 'Система',
    value: SettingsAlias.SYSTEM,
    sub: [
      {
        label: 'Соглашение',
        description: 'Текст публичного соглашения',
        icon: FileCheckIcon,
        value: SettingsSubAlias.SYSTEM_AGREEMENT,
      },
      {
        label: 'Договора',
        description: 'Шаблоны договоров',
        icon: FileIcon,
        value: SettingsSubAlias.SYSTEM_CONTRACTS,
      },
      {
        label: 'Формы собственности',
        description: 'Управление формами собственноси',
        icon: DefaultIcon,
        value: SettingsSubAlias.SYSTEM_OWNERSHIPS,
      },
      {
        label: 'Вид деятельности по КВЭД',
        description: 'Управление по КВЭД',
        icon: DefaultIcon,
        value: SettingsSubAlias.SYSTEM_BUSINESS_ACTIVITIES,
      },
      {
        label: 'Вид деятельности по ОКЭД',
        description: 'Управление по ОКЭД',
        icon: DefaultIcon,
        value: SettingsSubAlias.SYSTEM_ECONOMIC_ACTIVITIES,
      },
      {
        label: 'Классификатор видов и подвидов продукции',
        description: 'Список типов продуктов',
        icon: DefaultIcon,
        value: SettingsSubAlias.SYSTEM_PRODUCT_TYPES,
      },
      {
        label: 'Банки',
        description: 'Список банков',
        icon: BanksIcon,
        value: SettingsSubAlias.SYSTEM_BANKS,
      },
    ],
  },
];
const settingsAliases: ITabsList<ISettingsAlias> = settingsList
  ?.map(({ label, value }) => ({ label, value }));

export type {
  ISettingsAlias,
};
export {
  SettingsAlias,
  SettingsSubAlias,
  settingsList,
  settingsAliases,
};
