import { PropsWithChildren, ReactElement } from 'react';
import usePermissions from 'hooks/core/usePermissions';
import { IPermissionsEqArgs } from 'helpers/permissions';

type IPermissionsProps = PropsWithChildren<{
  current?: IPermissionsEqArgs['currentCodenames']
  compareBy?: IPermissionsEqArgs['compareBy']
}>;

function Permissions(props: IPermissionsProps) {
  const { current = [], compareBy, children } = props;

  const { has } = usePermissions({ current, compareBy });

  if (has) {
    return children as ReactElement;
  }
  return null;
}

export default Permissions;
