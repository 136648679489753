import React from 'react';
import { useNavigate } from 'react-router-dom';
import { vsprintf } from 'sprintf-js';
import { ReactComponent as ContractsIcon } from 'media/icons/menu-contracts.svg';
import { contractsApi } from 'components/services/contracts/contractsService';
import useQuery from 'hooks/router/useQuery';
import SearchQuery from 'helpers/searchQuery';
import Loader from 'components/common/loader/Loader';
import { LoaderSize } from 'components/common/loader/LoaderTypes';
import NoData from 'components/common/noData/NoData';
import SearchResultsItem from 'components/features/search/results/item/SearchResultsItem';
import RoutePath from 'helpers/routePath';
import Alt from 'helpers/alt';
import { SearchResultsCategoryContractsItem, SearchResultsCategoryContractsItemTile } from './SearchResultsCategoryContractsStyles';

function SearchResultsCategoryContracts() {
  const navigate = useNavigate();

  const { getQueryByKey } = useQuery();

  const searchQueryName = SearchQuery.GLOBAL_SEARCH;

  const searchQuery = getQueryByKey(searchQueryName);

  const { useSearchQuery } = contractsApi;
  const { data, isLoading, isFetching } = useSearchQuery({ search: searchQuery });

  const results = data?.results || [];
  const loading = isLoading || isFetching;

  if (loading) {
    return (
      <SearchResultsItem>
        <Loader size={LoaderSize.MEDIUM} />
      </SearchResultsItem>
    );
  }
  if (Array.isArray(results) && results.length > 0) {
    return (
      <>
        {results.map((item) => {
          const { id, contractNumber, companyName = Alt.NAME } = item;

          const handleClick = () => navigate(vsprintf(RoutePath.CONTRACTS_DETAIL, [ id ]));

          return (
            <SearchResultsCategoryContractsItem
              onClick={handleClick}
              key={id}
            >
              <SearchResultsCategoryContractsItemTile
                name={`Договор №${contractNumber}`}
                description={`Компания: ${companyName}`}
                icon={ContractsIcon}
                id={id}
              />
            </SearchResultsCategoryContractsItem>
          );
        })}
      </>
    );
  }
  return (
    <SearchResultsItem>
      <NoData />
    </SearchResultsItem>
  );
}

export default SearchResultsCategoryContracts;
