const RouteMatch = {
  COMPANY_ID: 'company_id',
  COMPANY_STATUS: 'company_status',
  CONTRACT_ID: 'contract_id',
  CONTRACT_STATUS: 'contract_status',
  INVOICE_ID: 'invoice_id',
  PRODUCT_ID: 'product_id',
  PRODUCT_STATUS: 'company_status',
  SETTINGS_ALIAS: 'settings_alias',
  USERS_GROUPS_ID: 'users_groups_id',
  USERS_USERS_ID: 'users_users_id',
  BILLING_CONTRIBUTIONS_ID: 'billing_contributions_id',
  BILLING_COMPANY_CATEGORIES_ID: 'company_categories_id',
  GEO_COUNTRIES_ID: 'geo_countries_id',
  GEO_REGIONS_ID: 'geo_regions_id',
  GEO_CITIES_ID: 'geo_cities_id',
  GEO_DISTRICTS_ID: 'geo_districts_id',
  GEO_BRANCHES_ID: 'geo_branches_id',
  SYSTEM_CONTRACTS_ID: 'system_contracts_id',
  SYSTEM_OWNERSHIPS_ID: 'system_ownerships_id',
  SYSTEM_BUSINESS_ACTIVITIES_ID: 'system_business_activities_id',
  SYSTEM_ECONOMIC_ACTIVITIES_ID: 'system_economic_activities_id',
  SYSTEM_BANKS_ID: 'system_banks_id',
} as const;
type IRouteMatch = typeof RouteMatch[keyof typeof RouteMatch];

export type {
  IRouteMatch,
};
export default RouteMatch;
