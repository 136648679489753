import React, { useEffect, useState } from 'react';
import { PortalSelector } from 'helpers/portals';
import Portal from 'components/common/portal/Portal';
import { ITooltipState } from './TooltipTypes';
import { TooltipWrap } from './TooltipStyles';

function Tooltip() {
  const [ state, setState ] = useState<ITooltipState>({});

  useEffect(() => {
    const listener = (ev: MouseEvent) => {
      const target = ev?.target as HTMLElement;
      const t = target.closest('[data-tooltip]') as HTMLElement;
      const tAtr = t?.dataset?.tooltip;
      const hasTAtr = !!tAtr;

      if (((state.text && hasTAtr) && (state.text !== tAtr)) || !hasTAtr) {
        setState({});
      } else if (hasTAtr) {
        setState({
          text: tAtr,
          isLeft: (window.innerWidth - (ev?.clientX || 0)) > 550,
          coords: { top: ev?.clientY, left: ev?.clientX },
        });
      }
    };

    window.addEventListener('mousemove', listener);
    return () => {
      window.removeEventListener('mousemove', listener);
    };
  }, [ state?.text ]);

  if (state?.text) {
    return (
      <Portal selector={PortalSelector.TOOLTIP}>
        <TooltipWrap
          isLeft={state?.isLeft}
          style={state?.coords}
        >
          {state.text}
        </TooltipWrap>
      </Portal>
    );
  }

  return null;
}

export default Tooltip;
