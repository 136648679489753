import React from 'react';
import { ReactComponent as CheckIcon } from 'media/icons/check.svg';
import { ReactComponent as MinusIcon } from 'media/icons/minus.svg';
import { isNil } from 'ramda';
import { CheckboxCheckedType, ICheckboxProps } from './CheckboxTypes';
import { CheckboxInput, CheckboxWrap, CheckboxLabel } from './CheckboxStyles';

function Checkbox(props: ICheckboxProps) {
  const {
    label,
    checkedType = CheckboxCheckedType.ALL,
    name: nameBase,
    required,
    input,
    className,
    disabled,
    onChange,
    onClick,
    checked,
    tooltip,
  } = props;

  const isCheckedAll = checkedType === CheckboxCheckedType.ALL;

  const name = input?.name || nameBase;

  return (
    <CheckboxLabel
      data-tooltip={tooltip}
      className={className}
    >
      <CheckboxWrap>
        <CheckboxInput
          {...input}
          name={name}
          onClick={onClick}
          checked={!isNil(checked) ? checked : input?.checked}
          required={required}
          readOnly={!!disabled || !isNil(checked)}
          type="checkbox"
          onChange={(ev) => {
            if (onChange) onChange(ev);
            input?.onChange(ev);
          }}
        />
        {isCheckedAll ? (
          <CheckIcon />
        ) : (
          <MinusIcon />
        )}
      </CheckboxWrap>
      {label ? (
        <span>{label}</span>
      ) : null}
    </CheckboxLabel>
  );
}

export default Checkbox;
