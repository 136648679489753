import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import StateName from 'helpers/stateNames';
import { IErrorState, IErrorItem, IAddErrorPayload } from 'components/services/errors/errorsTypes';

const initialState: IErrorState = [];

const errorsSlice = createSlice({
  name: StateName.ERRORS,
  initialState,
  reducers: {
    add: (state: IErrorState, action: PayloadAction<IAddErrorPayload>) => {
      const { data, status } = action.payload;

      const errorLength = state.length || 0;
      const lastErrorId = state[errorLength - 1]?.id || 0;
      const id: IErrorItem['id'] = lastErrorId + 1;

      state.push({
        status,
        data,
        id,
      });
    },
    removeById: (state: IErrorState, action: PayloadAction<IErrorItem['id']>) => {
      const rejectedId = action.payload;

      return state.filter(({ id }) => id !== rejectedId);
    },
    removeAll: () => initialState,
  },
});

export {
  errorsSlice,
};
