import styled, { css } from 'styled-components/macro';
import { emergenceFromOpacityKeyframes } from 'helpers/keyframes';
import {
  IStatusBoolWrap,
  IStatusBoolLabel,
  IStatusBoolMark,
  IStatusBoolDropdownSC,
  IStatusBoolDropdownItemBoolSC,
  StatusBoolSubPosition,
} from './StatusBoolTypes';

const StatusBoolMarkBaseCSS = css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  width: 8px;
  min-width: 8px;
  height: 8px;
  min-height: 8px;
  border-radius: 100%;
`;
const StatusBoolDropdown = styled.div<IStatusBoolDropdownSC>`
  display: none;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 10px 10px;
  pointer-events: none;
  background: ${({ theme }) => theme.palette.grey[30]};
  color: ${({ theme }) => theme.palette.white[10]};
  border-radius: 10px;
  width: 172px;
  z-index: 100;
  animation: ${emergenceFromOpacityKeyframes} 1.5s linear;
  ${({ position }) => (position === StatusBoolSubPosition.TOP ? css`
    bottom: calc(100% + 6px);
  ` : css`
    top: calc(100% + 6px);
  `)}
`;
const StatusBoolDropdownItem = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.01em;
  padding: 10px 0 8px;
  border-bottom: 1px solid #5f5f5f;
`;
const StatusBoolDropdownItemBool = styled.div<IStatusBoolDropdownItemBoolSC>`
  position: relative;
  width: 38px;
  padding: 0 0 0 15px;
  color: ${({ theme, state }) => theme.fixed.status.bool.subMark[state]};
  &:before {
    ${StatusBoolMarkBaseCSS};
    left: 0;
    background: ${({ theme, state }) => theme.fixed.status.bool.subMark[state]};
  }
`;
const StatusBoolLabel = styled.span<IStatusBoolLabel>`
  font-size: 14px;
  line-height: 1.2;
  font-weight: 400;
  color: ${({ theme, state }) => theme.fixed.status.bool.color[state]};
`;
const StatusBoolMark = styled.b<IStatusBoolMark>`
  ${StatusBoolMarkBaseCSS};
  left: ${({ isMain }) => (isMain ? '10px' : 0)};
  background: ${({ theme, state }) => theme.fixed.status.bool.mark[state]};
`;
const StatusBoolWrap = styled.div<IStatusBoolWrap>`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  flex-shrink: 0;
  align-items: center;
  padding: ${({ isMain }) => (isMain ? '0 0 0 25px' : '0 0 0 15px')};
  width: ${({ isMain }) => isMain && '56px'};
  min-width: 56px;
  height: ${({ isMain }) => isMain && '28px'};
  min-height: ${({ isMain }) => isMain && '28px'};
  border-radius: ${({ isMain }) => isMain && '50px'};
  background: ${({ theme, state, isMain }) => isMain && theme.fixed.status.bool.background[state]};
  &:hover {
    ${StatusBoolDropdown} {
      display: flex;
    }
    ${StatusBoolLabel} {
      color: ${({ theme, isMain }) => !isMain && theme.palette.blue[20]};
    }
    ${StatusBoolMark} {
      background: ${({ theme, isMain }) => !isMain && theme.palette.blue[20]};
    }
  }
`;

export {
  StatusBoolWrap,
  StatusBoolMark,
  StatusBoolLabel,
  StatusBoolDropdown,
  StatusBoolDropdownItem,
  StatusBoolDropdownItemBool,
};
