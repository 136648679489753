import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import StateName from 'helpers/stateNames';
import { IModalState } from './modalTypes';

const initialState: IModalState = {
  isOpen: false,
  title: null,
  onConfirm: () => {},
};

const modalSlice = createSlice({
  name: StateName.MODAL,
  initialState,
  reducers: {
    open: (state: IModalState, action: PayloadAction<Omit<IModalState, 'isOpen'>>) => ({
      isOpen: true,
      ...action.payload,
    }),
    close: () => initialState,
  },
});

export {
  modalSlice,
};
