import React from 'react';
import Portal from 'components/common/portal/Portal';
import { PortalSelector } from 'helpers/portals';
import Mask from 'components/common/mask/Mask';
import Animation from 'components/common/animation/Animation';
import StateName from 'helpers/stateNames';
import useSelector from 'hooks/redux/useSelector';
import { ButtonSize, ButtonThemeType } from 'components/common/button/ButtonTypes';
import useDispatch from 'hooks/redux/useDispatch';
import Button from 'components/common/button/Button';
import { modalSlice } from 'components/services/modal/modalService';
import {
  ModalWrap,
  ModalTitle,
  ModalButtons,
} from './ModalStyles';

function Modal() {
  const dispatch = useDispatch();

  const modalState = useSelector((state) => state[StateName.MODAL]);
  const {
    isOpen,
    title: titleBase,
    onConfirm,
    submitIcon: SubmitIcon,
    buttons,
  } = modalState;

  const title = titleBase || 'Вы действительно хотите это сделать?';
  const buttonSubmit = buttons?.submit || 'Да';
  const buttonReject = buttons?.reject || 'Нет';

  const handleClose = () => dispatch(modalSlice.actions.close());
  const handleConfirm = () => {
    onConfirm();
    handleClose();
  };

  if (isOpen) {
    return (
      <Portal selector={PortalSelector.MODAL}>
        <Mask onClick={handleClose} />
        <Animation>
          <ModalWrap>
            <ModalTitle>{title}</ModalTitle>
            <ModalButtons>
              <Button
                themeType={ButtonThemeType.SECONDARY}
                size={ButtonSize.MEDIUM}
                icon={SubmitIcon}
                onClick={handleConfirm}
                type="button"
              >
                {buttonSubmit}
              </Button>
              <Button
                themeType={ButtonThemeType.MAIN_PRIMARY}
                size={ButtonSize.MEDIUM}
                onClick={handleClose}
                type="button"
              >
                {buttonReject}
              </Button>
            </ModalButtons>
          </ModalWrap>
        </Animation>
      </Portal>
    );
  }

  return null;
}

export default Modal;
