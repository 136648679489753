import styled from 'styled-components/macro';

const CountWrap = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  max-height: 20px;
  min-width: 24px;
  padding: 2px 8px 1px;
  font-size: 13px;
  line-height: 1.2;
  white-space: nowrap;
  border-radius: 50px;
  background: ${({ theme }) => theme.palette.orange[20]};
  color: ${({ theme }) => theme.palette.white[10]};
`;

export {
  CountWrap,
};
