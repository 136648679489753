import React from 'react';
import useMatchPath from 'hooks/router/useMatchPath';
import Sidebar from 'components/features/sidebar/Sidebar';
import useRedirect from 'hooks/router/useRedirect';
import useProtectPath from 'hooks/router/useProtectPath';
import { IMainLayoutProps } from './MainLayoutTypes';
import { MainLayoutWrap, MainLayoutInner } from './MainLayoutStyles';

function MainLayout(props: IMainLayoutProps) {
  const { children } = props;
  const { matchRoute } = useMatchPath();

  useRedirect();

  useProtectPath();

  return (
    <MainLayoutWrap>
      <Sidebar type={matchRoute?.route?.sidebar} />
      <MainLayoutInner>
        {children}
      </MainLayoutInner>
    </MainLayoutWrap>
  );
}

export default MainLayout;
