import styled, { css } from 'styled-components/macro';
import { IExportBlockContainerSC } from './ExportBlockTypes';

const ExportBlockContainer = styled.div<IExportBlockContainerSC>`
  display: flex;
  flex-flow: column nowrap;
  ${({ withUnderline }) => withUnderline && css`
    padding-bottom: 20px;
    border-bottom: ${({ theme }) => `1px solid ${theme.palette.main[190]}`};
  `}}
`;
const ExportBlockTitle = styled.h5`
  color: ${({ theme }) => theme.palette.main[310]};
  margin-bottom: 14px;
  letter-spacing: 0.15px;
`;
const ExportBlockBody = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 8px;
`;
const ExportBlockDescription = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.palette.main[300]};
  margin-top: 12px;
`;

export {
  ExportBlockContainer,
  ExportBlockTitle,
  ExportBlockBody,
  ExportBlockDescription,
};
