import { ICompaniesPlatformResponse } from 'types/response';
import { isEmpty, isNil } from 'ramda';
import { IStatusBoolSubItem, StatusBoolState } from 'components/common/status/bool/StatusBoolTypes';
import { profileApi } from 'components/services/profile/profileService';
import { SyncStatus } from 'helpers/sync';

interface ISyncByPlatformResultsArgs {
  platformResults: ICompaniesPlatformResponse[]
}
interface IUseSync {
  syncByPlatformResults: (args: ISyncByPlatformResultsArgs) => { state: IStatusBoolSubItem['state'], list: IStatusBoolSubItem[] }
}

function useSync(): IUseSync {
  const { useDetailQuery } = profileApi;

  const { data: profileData } = useDetailQuery();

  const services = profileData?.settings?.services || [];

  const failedStatus = SyncStatus.FAILED;

  const syncByPlatformResults = ({ platformResults }: ISyncByPlatformResultsArgs) => {
    const state = (() => {
      if (isEmpty(platformResults)) return StatusBoolState.NOT_AVAILABLE;
      if (!platformResults?.some(({ status: pRStatus }) => pRStatus === failedStatus)) return StatusBoolState.ACTIVE;
      return StatusBoolState.INACTIVE;
    })();

    const list = services?.map(({ label: syncLabel, kind: syncKind }) => ({
      label: syncLabel,
      state: (() => {
        const currentService = platformResults?.find(({ kind: fSyncKind }) => fSyncKind === syncKind);
        const currentServiceStatus = currentService?.status;
        if (isNil(currentServiceStatus)) return StatusBoolState.NOT_AVAILABLE;
        if (currentServiceStatus === failedStatus) return StatusBoolState.INACTIVE;
        return StatusBoolState.ACTIVE;
      })(),
    }));

    return {
      state,
      list,
    };
  };

  return { syncByPlatformResults };
}

export default useSync;
