import React from 'react';
import Loader from 'components/common/loader/Loader';
import { useTheme } from 'styled-components/macro';
import { isNil } from 'ramda';
import { ContentBackground, IContentProps } from './ContentTypes';
import { ContentWrap, ContentButton } from './ContentStyles';

function Content(props: IContentProps) {
  const {
    background: bg = ContentBackground.TRANSPARENT,
    isLoading = false,
    button,
    children,
    className,
    ...rest
  } = props;

  const theme = useTheme();

  const background = () => {
    switch (bg) {
      case ContentBackground.TRANSPARENT: return 'transparent';
      case ContentBackground.SECONDARY: return theme.palette.main[10];
      default: return 'transparent';
    }
  };

  const hasButton = !isNil(button);

  const buttonEl = hasButton ? (
    <ContentButton {...button} />
  ) : null;

  if (isLoading) {
    return (
      <Loader />
    );
  }

  return (
    <ContentWrap
      background={background()}
      hasButton={hasButton}
      className={className}
      {...rest}
    >
      {children}
      {buttonEl}
    </ContentWrap>
  );
}

export default Content;
