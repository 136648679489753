import styled from 'styled-components/macro';
import { ReactComponent as ChevronRightIcon } from 'media/icons/chevron-right.svg';
import Avatar from 'components/common/avatar/Avatar';
import { emergenceFromOpacityKeyframes } from 'helpers/keyframes';

const SidebarProfileWrap = styled.div`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  flex-shrink: 0;
  align-items: center;
  padding: 10px;
  height: ${({ theme }) => theme.height.sidebar.footer};
  background: ${({ theme }) => theme.palette.main[160]};
  border-radius: 10px;
  transition: .2s background-color;
  cursor: pointer;
`;
const SidebarProfileArrow = styled(ChevronRightIcon)`
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  color: ${({ theme }) => theme.palette.main[260]};
  animation: ${emergenceFromOpacityKeyframes} .2s linear;
`;
const SidebarProfileAvatar = styled(Avatar)`
  margin-right: 10px;
`;
const SidebarProfileName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 140px;
  line-height: 1.4;
  animation: ${emergenceFromOpacityKeyframes} .2s linear;
`;
const SidebarProfileFirstName = styled(SidebarProfileName)`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.main[360]};
`;
const SidebarProfileUsername = styled(SidebarProfileName)`
  font-size: 13px;
  color: ${({ theme }) => theme.palette.main[260]};
`;

export {
  SidebarProfileWrap,
  SidebarProfileAvatar,
  SidebarProfileArrow,
  SidebarProfileFirstName,
  SidebarProfileUsername,
};
