const StateName = {
  THEMES: 'themes',
  ERRORS: 'errors',
  MODAL: 'modal',
  MENU: 'menu',
  SEARCH: 'search',
  EXPORT: 'export',
  EXPORT_API: 'export-api',
  TOASTS: 'toasts',
  NAVIGATE: 'navigate',
  AUTH: 'auth',
  AUTH_API: 'auth-api',
  PROFILE_API: 'profile-api',
  COMPANIES_API: 'companies-api',
  CONTRACTS_API: 'contracts-api',
  FILTERS_API: 'filters-api',
  PRODUCTS_API: 'products-api',
  UPLOAD_API: 'upload-api',
  MESSAGES_API: 'messages-api',
  SETTINGS_API: 'settings-api',
  STATISTICS_API: 'statistics-api',
} as const;
type IStateName = typeof StateName[keyof typeof StateName];

export type {
  IStateName,
};

export default StateName;
