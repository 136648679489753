import { createApi } from '@reduxjs/toolkit/query/react';
import StateName from 'helpers/stateNames';
import { RequestAPI } from 'helpers/requests';
import { axiosBaseQuery } from 'helpers/axios';
import { dataToSnakeCase } from 'helpers/register';
import { vsprintf } from 'sprintf-js';
import { IMessagesListResponse } from 'types/response';
import { IMessagesListArgs, IMessageDoneArgs } from 'components/services/messages/messagesTypes';

const messagesApi = createApi({
  reducerPath: StateName.MESSAGES_API,
  baseQuery: axiosBaseQuery(),
  tagTypes: [ 'MessagesList' ],
  endpoints: (build) => ({
    list: build.query<IMessagesListResponse, IMessagesListArgs>({
      query(args) {
        return {
          url: RequestAPI.NOTIFICATIONS,
          params: dataToSnakeCase(args),
        };
      },
      providesTags: [ 'MessagesList' ],
    }),
    done: build.mutation<void, IMessageDoneArgs>({
      query({ id }) {
        return {
          url: vsprintf(RequestAPI.NOTIFICATIONS_DONE, [ id ]),
          method: 'PUT',
        };
      },
      invalidatesTags: [ 'MessagesList' ],
    }),
  }),
});

export {
  messagesApi,
};
