import { createGlobalStyle } from 'styled-components/macro';
import { ITheme } from 'helpers/theme';
import hexToRGBA from 'helpers/hexToRGBA';

interface IMainCSS {
  theme: ITheme,
}

const MainStyles = createGlobalStyle<IMainCSS>`
  #root {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    min-height: 100vh;
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
    z-index: 1;
    min-width: 320px;
  }
  body {
    font-family: 'Vela Sans', Arial, sans-serif;
    background-color: ${({ theme }) => theme.palette.main[70]};
    color: ${({ theme }) => theme.palette.main[360]};
    font-size: 14px;
    line-height: 1.2;
    overflow: hidden;
    min-width: 320px;
  }
  * {
    scroll-behavior: smooth;
    scrollbar-width: thin;
    scrollbar-color: transparent;
    transition: background-color .3s;
    ::-webkit-scrollbar{
      width: ${({ theme }) => theme.width.scrollbar};
      height: ${({ theme }) => theme.width.scrollbar};
      background-color: transparent;
      transition: background-color 2s;
    }
    ::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 50px;
      transition: background-color 2s;
    }
    &:hover {
      ::-webkit-scrollbar{
        background-color: ${({ theme }) => hexToRGBA(theme.palette.main[210], 0.1)};
        transition: background-color 2s;
      }
      ::-webkit-scrollbar-thumb {
        background-color: ${({ theme }) => hexToRGBA(theme.palette.main[210], 0.7)};
        transition: background-color 2s;
      }
    }
  }
  *,
  *:before,
  *:after {
    box-sizing: border-box;
    overflow-wrap: break-word;
  }
  *:focus {
    outline: none !important;
  }
  *:active {
    outline:none !important;
  }
  button{
    cursor: pointer;
    border-radius: 0;
    padding: 0;
    margin: 0;
    border: none;
    -webkit-appearance: none;
    &:focus {
      outline: none !important;
    }
  }
  audio, video {
    display: block;
  }
  img {
    border: none;
  }
  * > img{
    max-width: 100%;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  p, dd, dl, figure, blockquote {
    margin: 0;
  }
  ul, ol{
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-size: inherit;
    font-weight: 500;
    color: inherit;
  }
  button, input, optgroup, select, textarea {
    padding: 0;
    margin: 0;
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
    font: inherit;
    color: inherit;
    letter-spacing: inherit;
    appearance: none !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
  }
  button{
    cursor: pointer;
    border-radius: 0;
    user-select: none;
    -webkit-appearance: none;
    &:focus {
      outline: none !important;
    }
  }
`;

export default MainStyles;
