import { TableBodyRowSize } from 'components/common/table/body/TableBodyTypes';
import { ToastItemType } from 'components/common/toasts/item/ToastsItemTypes';
import { StatusBoolState } from 'components/common/status/bool/StatusBoolTypes';
import { ButtonThemeType } from 'components/common/button/ButtonTypes';
import { StatusState } from 'helpers/statuses';
import hexToRGBA from 'helpers/hexToRGBA';
import authLightSrc from 'media/videos/auth-bg-light.mp4';
import authDarkSrc from 'media/videos/auth-bg-dark.mp4';
import { IThemeAlias, ThemeAlias } from './themes';
import { CompanyStatusAlias } from './companies';

const mainLight = {
  10: '#ffffff',
  20: '#fdfdfd',
  30: '#fbfbfb',
  40: '#f8f8f8',
  50: '#f4f4f5',
  60: '#fbfbfc',
  70: '#fafafa',
  80: '#f9f9f9',
  90: '#f8f6f9',
  100: '#f7f7f8',
  120: '#f2f2f2',
  130: '#f0eff2',
  140: '#efeff0',
  150: '#ededed',
  160: '#ececec',
  170: '#eaf9e9',
  180: '#e8e8e9',
  190: '#e6e6e6',
  200: '#dbdbdb',
  210: '#cccccc',
  220: '#cbcbd1',
  230: '#c6c5cc',
  240: '#bfbfc6',
  250: '#b8b7be',
  260: '#9fa0a1',
  270: '#9695a5',
  280: '#888f97',
  290: '#888895',
  300: '#7d848e',
  310: '#7d7c90',
  320: '#707273',
  330: '#555555',
  340: '#444444',
  350: '#333333',
  360: '#202020',
};

const mainDark = {
  // 10: '#101112',
  // 10: '#1c1c1c',
  10: '#1b1b1b',
  20: '#fdfdfd', // ?
  30: '#48494b',
  40: '#f8f8f8', // ?
  50: '#2e3032',
  60: '#fbfbfc', // ?
  70: '#222222',
  80: '#1b1b1b',
  90: '#222222',
  100: '#2e3032',
  120: '#2e3032',
  130: '#2e3032',
  140: '#2e3032',
  150: '#373838',
  160: '#2e3032',
  170: '#eaf9e9', // ?
  180: '#e8e8e9', // ?
  190: '#898a8d',
  200: '#898a8d',
  210: '#cccccc',
  220: '#898a8d',
  230: '#898a8d',
  240: '#898a8d',
  250: '#898a8d',
  260: '#898a8d',
  270: '#898989',
  280: '#898a8d',
  290: '#898a8d',
  300: '#898a8d',
  310: '#898a8d',
  320: '#ccc',
  330: '#555555', // ?
  340: '#f9f9f9',
  350: '#f9f9f9',
  360: '#ffffff',
};

const main = (alias: IThemeAlias) => (alias === ThemeAlias.DARK ? mainDark : mainLight);

const palette = (alias: IThemeAlias) => ({
  main: main(alias),
  black: {
    10: '#202020',
    20: '#000',
  },
  white: {
    10: '#fff',
    20: hexToRGBA('#fff', 0.7),
  },
  grey: {
    10: '#e6e6e6',
    20: '#888f97',
    30: '#333333',
    40: '#eaf9e9',
    50: '#ededed',
  },
  green: {
    10: '#70d669',
    20: '#486146',
    30: hexToRGBA('#70d669', 0.1),
    40: hexToRGBA('#70d669', 0.8),
    50: '#00a000',
  },
  red: {
    10: '#ff0000',
    20: '#f52943',
    30: '#704848',
    40: '#ffe7e8',
    50: hexToRGBA('#ff0000', 0.1),
    60: hexToRGBA('#ff0000', 0.7),
  },
  orange: {
    10: '#ff3b3c',
    20: '#f26334',
    30: '#cf5128',
    40: '#ee7751',
    50: '#fbcbbc',
    60: '#eb6337',
    70: '#e37d3b',
  },
  yellow: {
    10: '#ffe900',
    20: hexToRGBA('#ffe900', 0.8),
    30: '#ffc300',
  },
  blue: {
    10: '#5467c8',
    20: '#5ca0e2',
    30: '#6eabc1',
    40: '#1e4bdd',
    50: '#006ab4',
    60: '#00bccf',
  },
  purple: {
    10: '#a357d7',
  },
}) as const;

const fixed = (alias: IThemeAlias) => ({
  avatar: [
    'linear-gradient(180deg, #c7dcef 0%, #d2e5f2 100%)',
    'linear-gradient(180deg, #c7efc9 0%, #d2f2d4 100%)',
    'linear-gradient(180deg, #c8c7ef 0%, #d2dbf2 100%)',
  ],
  statistics: {
    random: [
      '#1e90ff',
      '#32cd32',
      '#ffd700',
      '#ff0000',
      '#66cdaa',
      '#FF4500',
      '#5933d2',
      '#ff4500',
      '#d950ff',
      '#ff8800',
      '#223fd0',
      '#3cb371',
      '#ff8000',
      '#c70039',
      '#27ae60',
      '#2980b9',
      '#8e44ad',
      '#f39c12',
    ],
    companiesStatus: {
      [CompanyStatusAlias.ACTIVE]: palette(alias).green[50],
      [CompanyStatusAlias.INACTIVE]: palette(alias).grey[30],
      [CompanyStatusAlias.LESS_30]: palette(alias).yellow[30],
      [CompanyStatusAlias.DEBTOR]: palette(alias).red[10],
      [CompanyStatusAlias.EXCLUDED]: palette(alias).blue[50],
    },
    months: [
      '#223fd0',
      '#1e90ff',
      '#32cd32',
      '#3cb371',
      '#66cdaa',
      '#ffd700',
      '#fc9146',
      '#ff8800',
      '#ff8000',
      '#ff4500',
      '#d950ff',
      '#5933d2',
    ],
    unknown: palette(alias).grey[20],
  },
  dropdown: [
    palette(alias).orange[60],
    palette(alias).main[360],
    palette(alias).purple[10],
    palette(alias).blue[30],
    palette(alias).blue[10],
  ],
  toasts: {
    [ToastItemType.INFO]: palette(alias).blue[20],
    [ToastItemType.SUCCESS]: palette(alias).green[10],
    [ToastItemType.ERROR]: palette(alias).red[20],
  },
  button: {
    color: {
      [ButtonThemeType.MAIN_PRIMARY]: palette(alias).main[10],
      [ButtonThemeType.MAIN_SECONDARY]: palette(alias).main[10],
      [ButtonThemeType.SECONDARY]: palette(alias).main[360],
      [ButtonThemeType.MINOR_PRIMARY]: palette(alias).main[360],
      [ButtonThemeType.MINOR_SECONDARY]: palette(alias).main[360],
    },
    background: {
      [ButtonThemeType.MAIN_PRIMARY]: palette(alias).main[360],
      [ButtonThemeType.MAIN_SECONDARY]: palette(alias).main[360],
      [ButtonThemeType.SECONDARY]: palette(alias).main[190],
      [ButtonThemeType.MINOR_PRIMARY]: 'transparent',
      [ButtonThemeType.MINOR_SECONDARY]: 'transparent',
    },
    border: {
      [ButtonThemeType.MAIN_PRIMARY]: 'transparent',
      [ButtonThemeType.MAIN_SECONDARY]: 'transparent',
      [ButtonThemeType.SECONDARY]: 'transparent',
      [ButtonThemeType.MINOR_PRIMARY]: hexToRGBA(palette(alias).main[360], 0.2),
      [ButtonThemeType.MINOR_SECONDARY]: 'transparent',
    },
  },
  status: {
    label: {
      color: {
        [StatusState.ACTIVE]: palette(alias).main[360],
        [StatusState.INACTIVE]: palette(alias).main[280],
        [StatusState.PARTIALLY_ACTIVE]: palette(alias).main[360],
        [StatusState.PENDING]: palette(alias).main[360],
      },
      background: {
        [StatusState.ACTIVE]: palette(alias).green[10],
        [StatusState.INACTIVE]: 'transparent',
        [StatusState.PARTIALLY_ACTIVE]: 'transparent',
        [StatusState.PENDING]: palette(alias).yellow[10],
      },
      border: {
        [StatusState.ACTIVE]: palette(alias).green[10],
        [StatusState.INACTIVE]: palette(alias).orange[20],
        [StatusState.PARTIALLY_ACTIVE]: palette(alias).green[10],
        [StatusState.PENDING]: palette(alias).yellow[10],
      },
    },
    bool: {
      color: {
        [StatusBoolState.ACTIVE]: palette(alias).green[20],
        [StatusBoolState.INACTIVE]: palette(alias).red[30],
        [StatusBoolState.NOT_AVAILABLE]: palette(alias).grey[20],
      },
      background: {
        [StatusBoolState.ACTIVE]: palette(alias).green[30],
        [StatusBoolState.INACTIVE]: palette(alias).red[50],
        [StatusBoolState.NOT_AVAILABLE]: palette(alias).main[150],
      },
      mark: {
        [StatusBoolState.ACTIVE]: palette(alias).green[10],
        [StatusBoolState.INACTIVE]: palette(alias).orange[10],
        [StatusBoolState.NOT_AVAILABLE]: palette(alias).main[240],
      },
      subMark: {
        [StatusBoolState.ACTIVE]: palette(alias).white[10],
        [StatusBoolState.INACTIVE]: palette(alias).orange[10],
        [StatusBoolState.NOT_AVAILABLE]: palette(alias).main[240],
      },
    },
  },
}) as const;

const width = {
  scrollbar: '8px',
  frame: '1920px',
  modal: '470px',
  sidebar: {
    primary: '278px',
    short: '70px',
  },
  form: {
    inner: '1220px',
    center: '588px',
  },
} as const;

const height = {
  frame: '1080px',
  form: {
    header: '80px',
  },
  table: {
    thead: {
      tr: '63px',
    },
    tbody: {
      tr: {
        [TableBodyRowSize.MEDIUM]: '73px',
        [TableBodyRowSize.SMALL]: '50px',
      },
    },
    tfoot: {
      tr: '60px',
    },
  },
  field: {
    primary: '62px',
    secondary: '44px',
    mini: '25px',
    area: '170px',
  },
  sidebar: {
    header: '124px',
    footer: '60px',
  },
} as const;

const common = (alias: IThemeAlias) => ({
  palette: palette(alias),
  fixed: fixed(alias),
  width,
  height,
});

const lightMedia = {
  auth: authLightSrc,
};

const lightTheme = {
  ...common(ThemeAlias.LIGHT),
  media: lightMedia,
} as const;

const darkMedia = {
  auth: authDarkSrc,
};

const darkTheme = {
  ...common(ThemeAlias.DARK),
  media: darkMedia,
} as const;

type ITheme = typeof lightTheme & typeof darkTheme;

export type {
  ITheme,
};
export {
  lightTheme,
  darkTheme,
};
