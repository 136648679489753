import { StylesConfig } from 'react-select';
import { ITheme } from 'helpers/theme';
import hexToRGBA from 'helpers/hexToRGBA';
import { SelectTheme, ISelectTheme } from './SelectStaticTypes';

const defaultStyles = (theme: ITheme, type: ISelectTheme): StylesConfig => {
  const isPrimary = type === SelectTheme.PRIMARY;

  return {
    container: (current) => ({
      ...current,
      minHeight: theme.height.field[type],
      flexShrink: 0,
      pointerEvents: 'unset',
      fontSize: '14px',
    }),
    control: (current, state) => {
      const {
        isFocused,
        hasValue,
        isDisabled,
        isMulti,
      } = state;

      const isUpLabel: boolean = isFocused || hasValue;

      const labelColor = (() => {
        if (isDisabled) return theme.palette.main[250];
        if (isUpLabel) return theme.palette.main[340];
        return theme.palette.main[270];
      })();

      const background = (() => {
        if (isDisabled) return theme.palette.main[50];
        if (isFocused) return theme.palette.main[10];
        return theme.palette.main[50];
      })();

      const borderColor = (() => {
        if (isDisabled) return 'transparent';
        if (isFocused) return theme.palette.main[360];
        return theme.palette.main[190];
      })();

      const labelTop = (() => {
        if (isUpLabel) {
          if (isMulti) return '10px';
          return '12px';
        }
        return `calc(${theme.height.field.primary} / 2)`;
      })();

      return {
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        flexShrink: 0,
        width: '100%',
        maxWidth: '100%',
        minHeight: 'inherit',
        height: 'inherit',
        maxHeight: 'inherit',
        background,
        border: `1px solid ${borderColor}`,
        borderRadius: '10px',
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        '& > label': {
          position: 'absolute',
          top: labelTop,
          left: '14px',
          transform: isUpLabel ? 'unset' : 'translateY(-50%)',
          fontSize: isUpLabel ? '13px' : 'inherit',
          color: labelColor,
          transition: 'color .2s,top .2s,transform .2s,font-size .2s',
          userSelect: 'none',
          pointerEvents: 'none',
        },
      };
    },
    indicatorsContainer: () => ({
      display: 'flex',
      flexFlow: 'row nowrap',
      alignSelf: 'flex-start',
      alignItems: 'center',
      height: theme.height.field[type],
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: (current, { isDisabled, isFocused }) => ({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexShrink: 0,
      width: `calc(${theme.height.field[type]} / 1.5)`,
      maxWidth: `calc(${theme.height.field[type]} / 1.5)`,
      marginLeft: '3px',
      '& svg': {
        width: `calc(${theme.height.field[type]} / 2)`,
        maxWidth: `calc(${theme.height.field[type]} / 2)`,
        color: theme.palette.main[isDisabled ? 250 : 340],
        transform: isFocused ? 'rotate(180deg)' : 'unset',
      },
    }),
    loadingIndicator: () => ({
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'center',
      alignItems: 'center',
      flexShrink: 0,
      width: `calc(${theme.height.field[type]} / 2.5)`,
      maxWidth: `calc(${theme.height.field[type]} / 2.5)`,
      '& > span': {
        flexShrink: 0,
        minWidth: '3px',
        width: '3px',
        height: '3px',
        borderRadius: '100%',
        margin: '0 1px',
      },
    }),
    valueContainer: (current, { isMulti }) => {
      const padding = () => {
        if (isPrimary) {
          if (isMulti) return '31px 14px 3px 14px';
          return '32px 14px 11px 14px';
        }
        return '4px 2px 4px 7px';
      };

      return {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexFlow: 'row wrap',
        flexGrow: 1,
        width: '100%',
        maxWidth: '100%',
        height: 'inherit',
        minHeight: 'inherit',
        margin: 0,
        padding: padding(),
        overflow: 'visible',
      };
    },
    singleValue: (current, { isDisabled }) => ({
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      maxWidth: '100%',
      margin: 0,
      color: theme.palette.main[isDisabled ? 250 : 340],
    }),
    multiValue: (current, { isDisabled }) => ({
      display: 'flex',
      flexFlow: 'row nowrap',
      height: '22px',
      background: theme.palette.main[190],
      borderRadius: '20px',
      margin: '0 6px 6px 0',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      maxWidth: '100%',
      color: theme.palette.main[isDisabled ? 250 : 340],
      pointerEvents: isDisabled ? 'none' : 'unset',
    }),
    input: (current, { isMulti, isDisabled }) => ({
      ...current,
      color: theme.palette.main[isDisabled ? 250 : 340],
      margin: isPrimary && isMulti ? '0 0 6px 0' : 0,
      padding: 0,
    }),
    menu: (current) => ({
      ...current,
      background: theme.palette.main[10],
      border: `1px solid ${theme.palette.main[100]}`,
      boxShadow: `0px 4px 24px ${hexToRGBA(theme.palette.main[360], 0.09)}`,
      marginTop: '5px',
      marginBottom: '5px',
      padding: '7px',
    }),
    menuList: (current) => ({
      ...current,
      padding: 0,
    }),
    option: (current, { isSelected }) => ({
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      color: theme.palette.main[360],
      background: isSelected ? theme.palette.main[140] : 'transparent',
      minHeight: '32px',
      fontSize: '14px',
      lineHeight: '1.2',
      padding: '7px',
      cursor: 'pointer',
      borderRadius: '7px',
      '&:hover': {
        background: theme.palette.main[140],
      },
      '&:not(:last-child)': {
        marginBottom: '2px',
      },
    }),
    placeholder: (current, { isFocused }) => ({
      ...current,
      display: isFocused ? 'none' : 'block',
    }),
  };
};

export { defaultStyles };
