import { keyframes } from 'styled-components/macro';

const blinkKeyframes = keyframes`
  50% {
    opacity: 0.5;
  }
`;
const emergenceFromOpacityKeyframes = keyframes`
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
const emergenceFromOpacityLinerKeyframes = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
const emergenceFromBottomKeyframes = keyframes`
  0% {
    transform: translateY(70px) translateX(0);
    opacity: 0;
  }
  100% {
    transform: translateY(0) translateX(0);
    opacity: 1;
  }
`;
const emergenceFromRightKeyframes = keyframes`
  0% {
    transform: translateX(70px) translateX(0);
    opacity: 0;
  }
  100% {
    transform: translateY(0) translateX(0);
    opacity: 1;
  }
`;

export {
  blinkKeyframes,
  emergenceFromOpacityKeyframes,
  emergenceFromOpacityLinerKeyframes,
  emergenceFromBottomKeyframes,
  emergenceFromRightKeyframes,
};
