import { ChangeEvent, MouseEvent } from 'react';
import { FieldRenderProps } from 'react-final-form';

const CheckboxCheckedType = {
  ALL: 'all',
  PARTIALLY: 'partially',
} as const;
type ICheckboxCheckedType = typeof CheckboxCheckedType[keyof typeof CheckboxCheckedType];
type ICheckboxProps = Partial<FieldRenderProps<string, HTMLInputElement>> & {
  label?: string
  disabled?: boolean
  required?: boolean
  className?: string
  checkedType?: ICheckboxCheckedType
  onChange?: (ev: ChangeEvent<HTMLInputElement>) => void
  checked?: boolean
  onClick?: (ev: MouseEvent<HTMLElement>) => void
  tooltip?: string
  name?: string
};
interface ICheckboxWrap {
  checked?: ICheckboxProps['checked']
}

export {
  CheckboxCheckedType,
};
export type {
  ICheckboxProps,
  ICheckboxWrap,
};
