import { StorageName, storage } from 'helpers/storage';

const ThemeAlias = {
  LIGHT: 'light',
  DARK: 'dark',
} as const;
type IThemeAlias = typeof ThemeAlias[keyof typeof ThemeAlias];

const ThemeBase = {
  CUSTOM: 'custom',
  SYSTEM: 'system',
} as const;
type IThemeBase = typeof ThemeBase[keyof typeof ThemeBase];

const getThemeAliasFromStorage = (): IThemeAlias => {
  switch (storage(StorageName.THEME_ALIAS).getValue()) {
    case ThemeAlias.LIGHT:
      return ThemeAlias.LIGHT;
    case ThemeAlias.DARK:
      return ThemeAlias.DARK;
    default:
      return ThemeAlias.LIGHT;
  }
};

const getThemeBaseFromStorage = (): IThemeBase => {
  switch (storage(StorageName.THEME_BASE).getValue()) {
    case ThemeBase.SYSTEM:
      return ThemeBase.SYSTEM;
    case ThemeBase.CUSTOM:
      return ThemeBase.CUSTOM;
    default:
      return ThemeBase.SYSTEM;
  }
};

export type {
  IThemeAlias,
  IThemeBase,
};
export {
  ThemeAlias,
  ThemeBase,
  getThemeAliasFromStorage,
  getThemeBaseFromStorage,
};
