import queryString, { ParsedQuery } from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  fromPairs,
  is,
  isNil,
  toPairs,
} from 'ramda';
import SearchQuery, { ISearchQuery } from 'helpers/searchQuery';
import { ActionMeta, OnChangeValue } from 'react-select';
import { getQueryValueFromOnChange } from 'helpers/select';
import { IStorageName, storage } from 'helpers/storage';
import { ISelectStaticProps } from 'components/common/fields/select/static/SelectStaticTypes';
import { ChangeEvent } from 'react';

type ISingleQuery = string;
type IKey = ISearchQuery | string;
type IReplace = boolean;
interface IAdditionalQueries {
  [key: string]: string | number;
}

interface ISetQueryByKeyOptions {
  additionalQueries?: IAdditionalQueries,
  replace?: IReplace
  storageName?: IStorageName
}
interface IRemoveQueryByKeyOptions {
  replace?: IReplace
}
interface IGetQueryByKeyOptions {
  alt?: ISingleQuery
  storageName?: IStorageName
}
interface ISetQueryBySelectOptions {
  additionalQueries?: IAdditionalQueries,
  withPagination?: boolean
  storageName?: IStorageName
  pageQueryName?: ISearchQuery
}
interface ISetQueryByTextFieldOptions {
  additionalQueries?: IAdditionalQueries,
  withPagination?: boolean
  pageQueryName?: ISearchQuery
}
interface IUseQuery {
  queries: ParsedQuery,
  getQueryByKey: (key: IKey, options?: IGetQueryByKeyOptions) => ISingleQuery,
  getQueryBoolByKey: (key: IKey) => boolean,
  setQueryByKey: (key: IKey, value: ISingleQuery, options?: ISetQueryByKeyOptions) => void,
  removeQueryByKey: (key: IKey, options?: IRemoveQueryByKeyOptions) => void,
  setQueryBySelect: (
    option: ISelectStaticProps['option'],
    actionMeta: ISelectStaticProps['actionMeta'],
    options?: ISetQueryBySelectOptions
  ) => void
  setQueryByTextField: (
    ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    options?: ISetQueryByTextFieldOptions
  ) => void
  setQueryByCheckbox: (ev: ChangeEvent<HTMLInputElement>) => void
}

const getStorageKey = (storageName: string, key: string) => `${storageName}_${key}`;

function useQuery(): IUseQuery {
  const navigate = useNavigate();

  const { search } = useLocation();

  const queries = queryString.parse(search);

  const getQueryByKey = (key: IKey, options?: IGetQueryByKeyOptions): ISingleQuery => {
    const altStatic = options?.alt || '';
    const storageName = options?.storageName
      ? storage(getStorageKey(options?.storageName, key)).getValue()
      : undefined;
    const alt = storageName || altStatic;

    const value = queries[key] || null;
    if (is(Array, value)) {
      const filteredValues = value.filter((fValue) => !!fValue);
      const firstValue = filteredValues[0] || alt;
      return firstValue || alt;
    }
    return value || alt;
  };

  const getQueryBoolByKey = (key: IKey): boolean => getQueryByKey(key) === 'true';

  const setQueryByKey = (key: IKey, value: ISingleQuery, options?: ISetQueryByKeyOptions) => {
    const additionalQueries = options?.additionalQueries || {};
    const replace = options?.replace || false;
    const storageName = options?.storageName;

    const newQueries = {
      ...queries,
      ...additionalQueries,
      [key]: value,
    };
    const newSearch = queryString.stringify(newQueries);

    if (storageName) {
      storage(getStorageKey(storageName, key)).setValue(value);
    }

    navigate({ search: newSearch }, { replace });
  };

  const removeQueryByKey = (key: IKey, options?: IRemoveQueryByKeyOptions) => {
    const replace = options?.replace || false;

    const queriesArr = toPairs(queries);
    const queriesArrWithoutKey = queriesArr?.filter(([ fKey ]) => fKey !== key) || [];
    const newQueries = fromPairs(queriesArrWithoutKey);

    const newSearch = queryString.stringify(newQueries);
    navigate({ search: newSearch }, { replace });
  };

  const setQueryBySelect = (
    option: OnChangeValue<unknown, boolean>,
    actionMeta: ActionMeta<unknown>,
    options?: ISetQueryBySelectOptions,
  ) => {
    const pageQueryName = options?.pageQueryName || SearchQuery.PAGE;
    const withPagination = !isNil(options?.withPagination) ? options?.withPagination : true;
    const additionalQueries = withPagination ? { [pageQueryName]: 1, ...options?.additionalQueries } : options?.additionalQueries;

    const { name = '' } = actionMeta;

    setQueryByKey(
      name,
      getQueryValueFromOnChange(option),
      {
        replace: true,
        additionalQueries,
        storageName: options?.storageName,
      },
    );
  };

  const setQueryByTextField = (
    ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    options?: ISetQueryByTextFieldOptions,
  ) => {
    const pageQueryName = options?.pageQueryName || SearchQuery.PAGE;
    const withPagination = !isNil(options?.withPagination) ? options?.withPagination : true;
    const additionalQueries = withPagination ? { [pageQueryName]: 1, ...options?.additionalQueries } : options?.additionalQueries;

    const target = ev?.target;

    const value = target?.value;
    const name = target?.name;

    setQueryByKey(
      name,
      value,
      {
        replace: true,
        additionalQueries,
      },
    );
  };

  const setQueryByCheckbox = (ev: ChangeEvent<HTMLInputElement>) => {
    const name = ev?.target?.name;
    const checked = ev?.target?.checked;
    const value = checked ? 'true' : 'false';
    setQueryByKey(name, value);
  };

  return {
    queries,
    getQueryByKey,
    getQueryBoolByKey,
    setQueryByKey,
    removeQueryByKey,
    setQueryBySelect,
    setQueryByTextField,
    setQueryByCheckbox,
  };
}

export type {
  IAdditionalQueries,
};

export default useQuery;
