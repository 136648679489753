import React from 'react';
import Portal from 'components/common/portal/Portal';
import useBack from 'hooks/router/useBack';
import { ReactComponent as CloseIcon } from 'media/icons/x.svg';
import { ReactComponent as SaveIcon } from 'media/icons/save.svg';
import { PortalSelector } from 'helpers/portals';
import Mask from 'components/common/mask/Mask';
import Animation from 'components/common/animation/Animation';
import Button from 'components/common/button/Button';
import { ButtonSize, ButtonThemeType } from 'components/common/button/ButtonTypes';
import { AnimationSide } from 'components/common/animation/AnimationTypes';
import { FormModalType, IFormModalProps } from './FormModalTypes';
import {
  FormModalBody,
  FormModalButtons,
  FormModalCloseButton,
  FormModalTitle,
  FormModalWrap,
} from './FormModalStyles';

function FormModal(props: IFormModalProps) {
  const {
    type = FormModalType.PRIMARY,
    title,
    handleSubmit,
    isLoading,
    onClose: onCloseBase,
    children,
    submitButton,
  } = props;

  const { goBack } = useBack();

  const onClose = onCloseBase || goBack;

  const side = (() => {
    switch (type) {
      case FormModalType.PRIMARY: return AnimationSide.BOTTOM;
      default: return AnimationSide.RIGHT;
    }
  })();

  const closeEl = (
    <FormModalCloseButton onClick={onClose}>
      <CloseIcon />
    </FormModalCloseButton>
  );

  const titleEl = <FormModalTitle>{title}</FormModalTitle>;

  const bodyEl = (
    <FormModalBody>
      {children}
    </FormModalBody>
  );

  const buttonsEl = (
    <FormModalButtons>
      <Button
        themeType={ButtonThemeType.MINOR_SECONDARY}
        size={ButtonSize.MEDIUM}
        isLoading={isLoading}
        onClick={onClose}
        type="button"
      >
        Отмена
      </Button>
      <Button
        themeType={ButtonThemeType.MAIN_PRIMARY}
        size={ButtonSize.MEDIUM}
        isLoading={isLoading}
        icon={submitButton?.icon || SaveIcon}
        type="submit"
      >
        {submitButton?.text || 'Сохранить'}
      </Button>
    </FormModalButtons>
  );

  return (
    <Portal selector={PortalSelector.FORM_MODAL}>
      <Mask onClick={onClose} />
      <Animation side={side}>
        <FormModalWrap
          onSubmit={handleSubmit}
          type={type}
        >
          {closeEl}
          {titleEl}
          {bodyEl}
          {buttonsEl}
        </FormModalWrap>
      </Animation>
    </Portal>
  );
}

export default FormModal;
