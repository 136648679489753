import styled from 'styled-components/macro';
import { ReactComponent as CloseIcon } from 'media/icons/x.svg';
import SearchFieldBase from 'components/common/fields/search/SearchField';
import hexToRGBA from 'helpers/hexToRGBA';
import { ISearchCategoriesItemSC } from './SearchTypes';

const SearchLabelBase = styled.div`
  color: ${({ theme }) => theme.palette.main[320]};
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
`;
const SearchWrap = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 95%;
  max-width: 860px;
  height: auto;
  max-height: 575px;
  padding: 8px 0 16px;
  background: ${({ theme }) => theme.palette.main[10]};
  border: ${({ theme }) => `1px solid ${theme.palette.main[30]}`};
  box-shadow: ${({ theme }) => `0 4px 18px ${hexToRGBA(theme.palette.main[360], 0.15)}`};
  border-radius: 14px;
  backdrop-filter: blur(75px);
  z-index: 1000;
`;
const SearchHead = styled.div`
  padding: 0 8px;
`;
const SearchField = styled(SearchFieldBase)`
  height: 42px;
  margin: 0 0 10px;
  & input {
    padding: 9px 12px 10px 35px;
  }  
`;
const SearchCategories = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 0 8px;
`;
const SearchCategoriesLabel = styled(SearchLabelBase)`
  margin: 0 0 6px;
`;
const SearchCategoriesList = styled.div`
  display: flex;
  flex-flow: row wrap;
  & > *:not(:last-child) {
    margin: 0 7px 0 0;
  }
`;
const SearchCategoriesListItem = styled.div<ISearchCategoriesItemSC>`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  height: 27px;
  padding: 4px 11px;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  border: ${({ theme, isActive }) => `1px solid ${isActive ? 'transparent' : theme.palette.main[270]}`};
  border-radius: 50px;
  color: ${({ theme, isActive }) => (isActive ? theme.palette.main[10] : theme.palette.main[320])};
  background: ${({ theme, isActive }) => (isActive ? theme.palette.main[350] : 'transparent')};
  user-select: none;
  cursor: pointer;
  & > svg {
    max-width: 13px;
    max-height: 13px;
    color: inherit;
  }
  & > *:not(:last-child) {
    margin-right: 6px;
  }
`;
const SearchCategoriesListItemCloseIcon = styled(CloseIcon)`
  width: 15px;
  height: 15px;
  color: ${({ theme }) => theme.palette.main[270]};
`;
const SearchResults = styled.div`
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden auto;
  margin: 10px 0 -16px;
  & > *:not(:last-child) {
    margin: 0 0 10px;
  }
`;
const SearchResultsCategory = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;
const SearchResultsCategoryLabel = styled(SearchLabelBase)`
  top: 0;
  padding: 0 16px;
  margin: 0 0 4px;
`;
const SearchResultsCategoryList = styled.div`
  display: flex;
  flex-flow: column nowrap;
  & > *:not(:last-child) {
    border-bottom: ${({ theme }) => `1px solid ${theme.palette.main[30]}`};
  }
`;

export {
  SearchWrap,
  SearchHead,
  SearchField,
  SearchCategories,
  SearchCategoriesLabel,
  SearchCategoriesList,
  SearchCategoriesListItem,
  SearchCategoriesListItemCloseIcon,
  SearchResults,
  SearchResultsCategory,
  SearchResultsCategoryLabel,
  SearchResultsCategoryList,
};
