import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { ISidebarCompressSC, ISidebarMenuBase, ISidebarWrap } from './SidebarTypes';

const SidebarWrap = styled.div<ISidebarWrap>`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  flex-shrink: 0;
  width: ${({ theme, isShort, isHide }) => {
    if (isShort) return theme.width.sidebar.short;
    if (isHide) return 0;
    return theme.width.sidebar.primary;
  }};
  padding: ${({ isShort, isHide }) => {
    if (isShort) return '0 5px 10px';
    if (isHide) return 0;
    return '0 12px 10px';
  }};
  color: ${({ theme }) => theme.palette.main[360]};
  background: ${({ theme }) => theme.palette.main[90]};
  border-right: ${({ theme }) => `1px solid ${theme.palette.main[140]}`};
  overflow: ${({ isHide }) => isHide && 'hidden'};
  transition: .2s width;
`;
const SidebarMenuBase = styled.div<ISidebarMenuBase>`
  display: flex;
  flex-flow: column nowrap;
  flex-shrink: 0;
  padding: ${({ isShort }) => isShort && '0 6px'};
`;
const SidebarMenuTop = styled(SidebarMenuBase)`
  flex-shrink: 1;
  overflow-y: auto;
  margin-bottom: 23px;
`;
const SidebarMenuBottom = styled(SidebarMenuBase)`
  margin: auto 0 23px;
`;
const SidebarHeader = styled.div`
  flex-shrink: 0;
  height: ${({ theme }) => theme.height.sidebar.header};
  & > svg {
    height: 41px;
  }
`;
const SidebarHeaderLogo = styled(Link)`
  display: flex;
  margin: ${({ state: { isShort } }) => (isShort ? '30px 0 0 8px' : '30px 13px 0')};
  transition: margin .2s;
`;
const SidebarCompress = styled.div<ISidebarCompressSC>`
  position: absolute;
  top: 67px;
  right: 0;
  transform: ${({ isCompressed }) => (isCompressed ? 'translateX(50%)' : 'translateX(50%) rotate(180deg)')};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background: ${({ theme }) => theme.palette.main[10]};
  color: ${({ theme }) => theme.palette.main[360]};
  border: ${({ theme }) => `1px solid ${theme.palette.main[140]}`};
  border-radius: 100%;
  opacity: .5;
  cursor: pointer;
  z-index: 1000;
  & svg {
    width: 16px;
    height: 16px;
  }
  &:hover {
    opacity: 1;
  }
`;

export {
  SidebarWrap,
  SidebarMenuBase,
  SidebarMenuTop,
  SidebarMenuBottom,
  SidebarHeader,
  SidebarHeaderLogo,
  SidebarCompress,
};
