import { createSlice } from '@reduxjs/toolkit';
import StateName from 'helpers/stateNames';
import { ISearchState } from 'components/services/search/searchTypes';

const initialState: ISearchState = {
  isOpen: false,
};

const searchSlice = createSlice({
  name: StateName.SEARCH,
  initialState,
  reducers: {
    open: (state: ISearchState) => {
      state.isOpen = true;
    },
    close: (state: ISearchState) => {
      state.isOpen = false;
    },
    toggle: (state: ISearchState) => {
      state.isOpen = !state.isOpen;
    },
  },
});

export {
  searchSlice,
};
