import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ReactComponent as ArrowIcon } from 'media/icons/chevron-down.svg';
import { ISidebarMenuItemProps } from './SidebarMenuItemTypes';
import {
  SidebarMenuItemWrap,
  SidebarMenuItemArrow,
  SidebarMenuItemLabel,
  SidebarMenuItemCount,
  SidebarMenuItemChildren,
} from './SidebarMenuItemStyles';

function SidebarMenuItem(props: ISidebarMenuItemProps) {
  const {
    item: {
      label: itemLabel,
      path: itemPath,
      icon: Icon,
      count: iCount,
      children,
    },
    isShort = false,
  } = props;

  const { pathname } = useLocation();

  const hasChildren = (Array.isArray(children) && children.length > 0) && !isShort;

  const hasActiveChildren = hasChildren && !!children?.find(({ path: chPath }) => pathname.slice(0, chPath.length) === chPath);
  const isActive = !hasActiveChildren && (pathname.slice(0, itemPath.length) === itemPath);

  const [ showChildren, setShowChildren ] = useState<boolean>(false);

  useEffect(() => {
    if (isActive || hasActiveChildren) setShowChildren(true);
  }, [ isActive, hasActiveChildren ]);

  const childrenItems = hasChildren ? (
    <SidebarMenuItemChildren>
      {children?.map((chItem) => {
        const { label: chLabel, path: chPath, count: chCount } = chItem;

        const chIsActive = pathname.slice(0, chPath.length) === chPath;

        return (
          <SidebarMenuItemWrap
            to={chPath}
            key={chPath}
            state={{ isActive: chIsActive, isChildren: true }}
            data-tooltip={chLabel}
          >
            <SidebarMenuItemLabel>
              {chLabel}
            </SidebarMenuItemLabel>
            <SidebarMenuItemCount count={chCount} />
          </SidebarMenuItemWrap>
        );
      })}
    </SidebarMenuItemChildren>
  ) : null;

  const arrowEl = !isShort && hasChildren ? (
    <SidebarMenuItemArrow
      onClick={(ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        setShowChildren(!showChildren);
      }}
      isActive={isActive}
      isOpen={showChildren}
    >
      <ArrowIcon />
    </SidebarMenuItemArrow>
  ) : null;

  const labelEl = !isShort ? (
    <SidebarMenuItemLabel hasChildren={hasChildren}>
      {itemLabel}
    </SidebarMenuItemLabel>
  ) : null;

  const countEl = !isShort ? (
    <SidebarMenuItemCount hasChildren={hasChildren} count={iCount} />
  ) : null;

  return (
    <>
      <SidebarMenuItemWrap
        to={itemPath}
        onClick={() => {
          if (hasChildren) setShowChildren(true);
        }}
        state={{ isActive, isShort }}
        data-tooltip={itemLabel}
      >
        <Icon />
        {labelEl}
        {countEl}
        {arrowEl}
      </SidebarMenuItemWrap>
      {showChildren ? childrenItems : null}
    </>
  );
}

export default SidebarMenuItem;
