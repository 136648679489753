import React from 'react';
import { isNil } from 'ramda';
import { ISearchResultsItemProps } from './SearchResultsItemTypes';
import { SearchResultsItemWrap } from './SearchResultsItemStyles';

function SearchResultsItem(props: ISearchResultsItemProps) {
  const {
    onClick,
    children,
    className,
  } = props;

  return (
    <SearchResultsItemWrap
      onClick={onClick}
      hasClick={!isNil(onClick)}
      className={className}
    >
      {children}
    </SearchResultsItemWrap>
  );
}

export default SearchResultsItem;
