import {
  DependencyList,
  EffectCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { equals } from 'ramda';

type UseEffectReturn = ReturnType<typeof useEffect>;

function isPrimitive(val: any) {
  return val == null || /^[sbn]/.test(typeof val);
}

function checkDeps(deps: DependencyList) {
  if (!deps || !deps.length) {
    throw new Error(
      'useDeepEffect should not be used with no dependencies. Use React.useEffect instead.',
    );
  }
  if (deps.every(isPrimitive)) {
    throw new Error(
      'useDeepEffect should not be used with dependencies that are all primitive values. Use React.useEffect instead.',
    );
  }
}

export function useDeepMemoize<T>(value: T) {
  const ref = useRef<T>(value);
  const signalRef = useRef<number>(0);

  if (!equals(value, ref.current)) {
    ref.current = value;
    signalRef.current += 1;
  }

  return useMemo(() => ref.current, [ signalRef.current ]);
}

function useDeepEffect(
  callback: EffectCallback,
  dependencies: DependencyList,
): UseEffectReturn {
  if (process.env.NODE_ENV !== 'production') {
    checkDeps(dependencies);
  }
  return useEffect(callback, useDeepMemoize(dependencies));
}

export default useDeepEffect;
