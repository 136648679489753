import styled from 'styled-components/macro';
import Search from 'components/features/search/Search';

const SearchGlobalElement = styled(Search)`
  position: fixed;
  top: 200px;
  left: 50%;
  transform: translateX(-50%);
`;

export {
  SearchGlobalElement,
};
