import React, { useMemo, useState } from 'react';
import useDispatch from 'hooks/redux/useDispatch';
import { searchSlice } from 'components/services/search/searchService';
import useQuery from 'hooks/router/useQuery';
import SearchQuery from 'helpers/searchQuery';
import { ISearchCategory, searchCategories } from 'helpers/search';
import { TextTheme } from 'components/common/fields/text/TextFieldTypes';
import { ISearchProps } from './SearchTypes';
import {
  SearchWrap,
  SearchHead,
  SearchField,
  SearchCategories,
  SearchCategoriesLabel,
  SearchCategoriesList,
  SearchCategoriesListItem,
  SearchCategoriesListItemCloseIcon,
  SearchResults,
  SearchResultsCategory,
  SearchResultsCategoryLabel,
  SearchResultsCategoryList,
} from './SearchStyles';

function Search(props: ISearchProps) {
  const {
    autoFocus = false,
    className,
  } = props;

  const dispatch = useDispatch();

  const { getQueryByKey } = useQuery();

  const searchQueryName = SearchQuery.GLOBAL_SEARCH;

  const searchQuery = getQueryByKey(searchQueryName);

  const [ selectedCategories, setSelectedCategories ] = useState<ISearchCategory[]>([]);

  const handleClose = () => dispatch(searchSlice.actions.close());

  const checkHasInSelectedCategories = (category: ISearchCategory) => !!selectedCategories?.find((alias) => alias === category);

  const handleToggleSelectedCategories = (category: ISearchCategory) => {
    const hasInSelected = checkHasInSelectedCategories(category);

    const newSelectedCategories = hasInSelected
      ? selectedCategories?.filter((alias) => alias !== category)
      : [ ...selectedCategories, category ];

    setSelectedCategories(newSelectedCategories);
  };

  const searchFieldEl = (
    <SearchField
      searchQueryName={searchQueryName}
      textTheme={TextTheme.PRIMARY}
      isPagination={false}
      autoFocus={autoFocus}
      placeholder="Поиск по названию, GCP"
      // prefix={SearchIcon}
      // type="text"
      // inputMode="text"
      // defaultValue={searchQuery}
      // autoFocus={autoFocus}
      // onChange={handleChange}
      // onKeyDown={handleKeyDown}
    />
  );

  const searchCategoriesList = searchCategories?.map((item) => {
    const { name, alias, icon: Icon } = item;

    const isActive = checkHasInSelectedCategories(alias);
    const handleClick = () => handleToggleSelectedCategories(alias);

    return (
      <SearchCategoriesListItem
        onClick={handleClick}
        isActive={isActive}
        key={alias}
      >
        <Icon />
        <span>{name}</span>
        {isActive ? (
          <SearchCategoriesListItemCloseIcon />
        ) : null}
      </SearchCategoriesListItem>
    );
  });

  const searchCategoriesEl = (
    <SearchCategories>
      <SearchCategoriesLabel>Я ищу среди:</SearchCategoriesLabel>
      <SearchCategoriesList>
        {searchCategoriesList}
      </SearchCategoriesList>
    </SearchCategories>
  );

  const searchResultsCategoriesEl = useMemo(() => searchCategories.map((item) => {
    const { name, alias, results: ResultsList } = item;

    const hasSelectedInAll = Array.isArray(selectedCategories) && selectedCategories.length > 0;
    const isSelected = checkHasInSelectedCategories(alias) || !hasSelectedInAll;

    if (isSelected) {
      return (
        <SearchResultsCategory key={alias}>
          <SearchResultsCategoryLabel>{name}</SearchResultsCategoryLabel>
          <SearchResultsCategoryList onClick={handleClose}>
            <ResultsList />
          </SearchResultsCategoryList>
        </SearchResultsCategory>
      );
    }
    return null;
  }), [ selectedCategories, searchCategories ]);

  const searchResultsEl = searchQuery ? (
    <SearchResults>
      {searchResultsCategoriesEl}
    </SearchResults>
  ) : null;

  return (
    <SearchWrap className={className}>
      <SearchHead>
        {searchFieldEl}
        {searchCategoriesEl}
      </SearchHead>
      {searchResultsEl}
    </SearchWrap>
  );
}

export default Search;
