import { FunctionComponent, SVGProps } from 'react';
import { ReactComponent as CompaniesIcon } from 'media/icons/menu-companies.svg';
import { ReactComponent as ContractsIcon } from 'media/icons/menu-contracts.svg';
import { ReactComponent as ProductsIcon } from 'media/icons/menu-products.svg';
import SearchResultsCategoryCompanies from 'components/features/search/results/category/companies/SearchResultsCategoryCompanies';
import SearchResultsCategoryContracts from 'components/features/search/results/category/contracts/SearchResultsCategoryContracts';
import SearchResultsCategoryProducts from 'components/features/search/results/category/products/SearchResultsCategoryProducts';

const SearchCategory = {
  COMPANIES: 'companies',
  CONTRACTS: 'contracts',
  PRODUCTS: 'products',
} as const;
type ISearchCategory = typeof SearchCategory[keyof typeof SearchCategory];
interface ISearchCategoriesItem {
  name: string
  alias: ISearchCategory
  icon: FunctionComponent<SVGProps<SVGSVGElement>>
  results: FunctionComponent
}

const searchCategories: ISearchCategoriesItem[] = [
  {
    name: 'Компании',
    alias: SearchCategory.COMPANIES,
    icon: CompaniesIcon,
    results: SearchResultsCategoryCompanies,
  },
  {
    name: 'Договора',
    alias: SearchCategory.CONTRACTS,
    icon: ContractsIcon,
    results: SearchResultsCategoryContracts,
  },
  {
    name: 'Продукты',
    alias: SearchCategory.PRODUCTS,
    icon: ProductsIcon,
    results: SearchResultsCategoryProducts,
  },
];

export type {
  ISearchCategory,
  ISearchCategoriesItem,
};

export {
  SearchCategory,
  searchCategories,
};
