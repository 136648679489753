import React from 'react';
import moment from 'moment';
import Alt from 'helpers/alt';
import { IDateProps } from './DateTypes';
import { DateContainer } from './DateStyles';

function Date(props: IDateProps) {
  const {
    date,
    prefix,
    separator = '/',
    withTime = false,
    isInline = false,
    skipInvalidInRange = false,
  } = props;

  const numberFormat = withTime ? 'DD.MM.YYYY, HH:mm' : 'DD.MM.YYYY';
  const dateNumberFormatted = Array.isArray(date)
    ? date
      .map((item) => {
        if (skipInvalidInRange && !item) {
          return null;
        }
        return moment(item ?? null).format(numberFormat);
      })
      .filter((item) => item)
      .join(` ${separator} `) || Alt.DATE
    : moment(date ?? null).format(numberFormat);

  const stringFormat = withTime ? 'DD MMMM YYYY, HH:mm' : 'DD MMMM YYYY';
  const dateStringFormatted = Array.isArray(date)
    ? date
      .map((item) => {
        if (skipInvalidInRange && !item) {
          return null;
        }
        return moment(item ?? null).format(stringFormat);
      })
      .filter((item) => item)
      .join(` ${separator} `) || Alt.DATE
    : moment(date ?? null).format(stringFormat);

  return (
    <DateContainer
      data-tooltip={dateStringFormatted}
      isInline={isInline}
    >
      {prefix ? `${prefix} ` : null}
      {dateNumberFormatted}
    </DateContainer>
  );
}

export default Date;
