import { PayloadAction } from '@reduxjs/toolkit';

const MenuKey = {
  COMPANIES: 'companies',
  CONTRACTS: 'contracts',
} as const;
type IMenuKey = typeof MenuKey[keyof typeof MenuKey];
interface IMenuState {
  [MenuKey.COMPANIES]: string[],
  [MenuKey.CONTRACTS]: string[],
}
type IMenuTogglePayload = PayloadAction<{
  alias: string
  menuKey: IMenuKey
}>;

export type {
  IMenuKey,
  IMenuState,
  IMenuTogglePayload,
};
export {
  MenuKey,
};
