import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter as RouterProvider } from 'react-router-dom';
import ThemeProvider from 'components/providers/theme/ThemeProvider';
import { store } from 'helpers/redux';
import App from 'components/app/App';
import { momentLocalesInit } from 'helpers/moment';

momentLocalesInit();

function Root() {
  return (
    <ReduxProvider store={store}>
      <ThemeProvider>
        <RouterProvider>
          <App />
        </RouterProvider>
      </ThemeProvider>
    </ReduxProvider>
  );
}

export default Root;
