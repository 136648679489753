import styled, { css } from 'styled-components/macro';
import hexToRGBA from 'helpers/hexToRGBA';
import { ExportTooltipContainerSC, IExportTooltipIconSC, IExportTooltipCaptionProgressSC } from './ExportTooltipTypes';

const ExportTooltipContainer = styled.div<ExportTooltipContainerSC>`
  display: flex;
  flex-flow: row nowrap;
  gap: 8px;
  padding: 14px;
  border-radius: 14px;
  background: ${({ theme }) => theme.palette.grey[30]};
  color: ${({ theme }) => theme.palette.white[10]};
  cursor: default;
  ${({ isGlobal }) => isGlobal && css`
    position: fixed;
    right: 20px;
    bottom: 70px;
    width: 268px;
    z-index: 1000;
  `}
`;
const ExportTooltipIcon = styled.div<IExportTooltipIconSC>`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  color: ${({ theme, isFinished }) => isFinished && theme.palette.green[10]};
  & > svg {
    width: 20px;
  }
`;
const ExportTooltipCaption = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex-shrink: 1;
  flex-grow: 1;
`;
const ExportTooltipCaptionTitle = styled.div`
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
  color: ${({ theme }) => theme.palette.white[10]};
  &:not(:last-child) {
    margin-bottom: 4px;
  }
`;
const ExportTooltipCaptionDescription = styled.div`
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 4px;
  color: ${({ theme }) => hexToRGBA(theme.palette.white[10], 0.7)};
  &:not(:last-child) {
    margin-bottom: 9px;
  }
`;
const ExportTooltipCaptionProgress = styled.div<IExportTooltipCaptionProgressSC>`
  position: relative;
  width: 100%;
  height: 6px;
  background: ${({ theme }) => hexToRGBA(theme.palette.white[10], 0.3)};
  border-radius: 6px;
  overflow: hidden;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background: ${({ theme }) => theme.palette.green[10]};
    border-radius: inherit;
    width: ${({ progress }) => `${progress}%`};
    z-index: 1;
    transition: width .2s;
  }
`;
const ExportTooltipButton = styled.a`
  align-self: center;
  flex-grow: 0;
  flex-shrink: 0;
  background: ${({ theme }) => theme.palette.white[10]};
  color: ${({ theme }) => theme.palette.black[10]};
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;
  padding: 9px 14px;
`;

export {
  ExportTooltipContainer,
  ExportTooltipIcon,
  ExportTooltipCaption,
  ExportTooltipCaptionTitle,
  ExportTooltipCaptionDescription,
  ExportTooltipCaptionProgress,
  ExportTooltipButton,
};
