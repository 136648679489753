import styled from 'styled-components/macro';
import hexToRGBA from 'helpers/hexToRGBA';
import { IToastsItemMarkSC } from './ToastsItemTypes';

const ToastsItemWrap = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  padding: 11px;
  border-radius: 16px;
  background: ${({ theme }) => hexToRGBA(theme.palette.main[10], 0.9)};
  border: ${({ theme }) => `1px solid ${theme.palette.main[200]}`};
  box-shadow: ${({ theme }) => `0 4px 30px ${hexToRGBA(theme.palette.main[360], 0.1)}`};
  cursor: default;
`;
const ToastsItemMark = styled.div<IToastsItemMarkSC>`
  position: relative;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: ${({ theme, type }) => hexToRGBA(theme.fixed.toasts[type], 0.2)};
  margin-right: 10px;
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: ${({ theme, type }) => theme.fixed.toasts[type]};
  }
`;
const ToastsItemCaption = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  & > *:not(:last-child) {
    margin-bottom: 1px;
  }
`;
const ToastsItemCaptionTitle = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.01em;
`;
const ToastsItemCaptionDescription = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  letter-spacing: 0.004em;
  color: ${({ theme }) => theme.palette.main[260]};
`;

export {
  ToastsItemWrap,
  ToastsItemMark,
  ToastsItemCaption,
  ToastsItemCaptionTitle,
  ToastsItemCaptionDescription,
};
