import React from 'react';
import { useNavigate } from 'react-router-dom';
import { vsprintf } from 'sprintf-js';
import { productsApi } from 'components/services/products/productsService';
import useQuery from 'hooks/router/useQuery';
import SearchQuery from 'helpers/searchQuery';
import Loader from 'components/common/loader/Loader';
import { LoaderSize } from 'components/common/loader/LoaderTypes';
import NoData from 'components/common/noData/NoData';
import SearchResultsItem from 'components/features/search/results/item/SearchResultsItem';
import RoutePath from 'helpers/routePath';
import Alt from 'helpers/alt';
import { StatusBoolSubPosition, StatusBoolType } from 'components/common/status/bool/StatusBoolTypes';
import StatusBool from 'components/common/status/bool/StatusBool';
import useSync from 'hooks/etc/useSync';
import {
  SearchResultsCategoryProductsItem,
  SearchResultsCategoryProductsItemTileName,
  SearchResultsCategoryProductsItemTileGTIN,
  SearchResultsCategoryProductsItemTileBrickCode,
  SearchResultsCategoryProductsItemTileSync,
} from './SearchResultsCategoryProductsStyles';

function SearchResultsCategoryProducts() {
  const navigate = useNavigate();

  const { syncByPlatformResults } = useSync();

  const { getQueryByKey } = useQuery();

  const searchQueryName = SearchQuery.GLOBAL_SEARCH;

  const searchQuery = getQueryByKey(searchQueryName);

  const { useSearchQuery } = productsApi;
  const { data, isLoading, isFetching } = useSearchQuery({ search: searchQuery });

  const results = data?.results || [];
  const loading = isLoading || isFetching;

  if (loading) {
    return (
      <SearchResultsItem>
        <Loader size={LoaderSize.MEDIUM} />
      </SearchResultsItem>
    );
  }
  if (Array.isArray(results) && results.length > 0) {
    return (
      <>
        {results.map((item, index) => {
          const {
            id,
            name,
            description,
            brickCode,
            eancode,
            company: companyId,
            platformResults,
          } = item;

          const productTypeCode = brickCode || Alt.DATA;

          const { list: syncList, state: syncState } = syncByPlatformResults({ platformResults });

          const subPosition = index >= 2 ? StatusBoolSubPosition.TOP : StatusBoolSubPosition.BOTTOM;

          const syncEl = (
            <StatusBool
              state={syncState}
              sub={{ list: syncList, position: subPosition }}
              type={StatusBoolType.SECONDARY}
            />
          );

          const handleClick = () => navigate(vsprintf(RoutePath.COMPANIES_PRODUCTS_DETAIL, [ companyId, id ]));

          return (
            <SearchResultsCategoryProductsItem
              onClick={handleClick}
              key={id}
            >
              <SearchResultsCategoryProductsItemTileName
                name={description || Alt.NAME}
                description={name || Alt.DATA}
                id={id}
              />
              <SearchResultsCategoryProductsItemTileGTIN
                name={eancode || Alt.DATA}
                description="GTIN"
                withAvatar={false}
              />
              <SearchResultsCategoryProductsItemTileBrickCode
                name={`${productTypeCode}`}
                description="BRICK CODE"
                withAvatar={false}
              />
              <SearchResultsCategoryProductsItemTileSync
                name={syncEl}
                description="СИНХРОНИЗАЦИЯ"
                withAvatar={false}
              />
            </SearchResultsCategoryProductsItem>
          );
        })}
      </>
    );
  }
  return (
    <SearchResultsItem>
      <NoData />
    </SearchResultsItem>
  );
}

export default SearchResultsCategoryProducts;
