import React from 'react';
import { IExportBlockProps } from './ExportBlockTypes';
import {
  ExportBlockContainer,
  ExportBlockTitle,
  ExportBlockBody,
  ExportBlockDescription,
} from './ExportBlockStyles';

function ExportBlock(props: IExportBlockProps) {
  const {
    title,
    description,
    withUnderline = false,
    children,
  } = props;

  return (
    <ExportBlockContainer withUnderline={withUnderline}>
      {title ? <ExportBlockTitle>{title}</ExportBlockTitle> : null}
      <ExportBlockBody>{children}</ExportBlockBody>
      {description ? <ExportBlockDescription>{description}</ExportBlockDescription> : null}
    </ExportBlockContainer>
  );
}

export default ExportBlock;
