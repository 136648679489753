import { ISelectOption } from 'components/common/fields/select/SelectTypes';
import { is, isNil } from 'ramda';
import { OnChangeValue } from 'react-select';

type ISelectOptionValue = ISelectOption['value'];

const CREATED_OPTION_PREFIX = 'created';

enum PrefixCreateLabel {
  COMPANY_NEW = 'Новая: ',
}

const isCreatedOption = (option?: ISelectOption) => {
  const optionValue = option?.value;
  const isCreatedValue = `${optionValue}`.trim()?.startsWith(CREATED_OPTION_PREFIX);

  return optionValue && isCreatedValue;
};

const generateCreatedOption = (text: string, prefixCreateLabel?: string): ISelectOption => {
  const label = prefixCreateLabel ? `${prefixCreateLabel}${text}` : text;
  const value = `${CREATED_OPTION_PREFIX}_${text}`;

  return { label, value };
};

const removeCreatedPrefixFromLabel = (option: ISelectOption, prefixCreateLabel: string) => {
  const optionValue = option?.value;
  const optionLabel = option?.label;
  const isCreatedValue = `${optionValue}`.trim()?.startsWith(CREATED_OPTION_PREFIX);
  if (optionLabel && isCreatedValue) {
    return `${optionLabel}`.replace(prefixCreateLabel, '');
  }
  return optionLabel;
};

const getDefaultOptionByValue = (value?: ISelectOptionValue | null): ISelectOption | undefined => (!isNil(value) && !!value ? ({
  label: `Элемент ${value}`,
  value,
}) : undefined);

const getOptionByValue = (options: ISelectOption[], value?: ISelectOption['value']): ISelectOption | undefined => {
  const currentOption: ISelectOption | undefined = options?.find(({ value: fValue }) => value && `${fValue}` === `${value}`);
  const defaultOption: ISelectOption | undefined = getDefaultOptionByValue(value);

  return !isNil(currentOption) ? currentOption : defaultOption;
};

const getInitOption = (
  label: ISelectOption['label'] | undefined | null,
  value: ISelectOptionValue | undefined | null,
): ISelectOption | undefined => {
  const defaultOption: ISelectOption | undefined = getDefaultOptionByValue(value);
  return !isNil(label) && label && !isNil(value) && value ? {
    label,
    value,
  } : defaultOption;
};

const getQueryValueFromOnChange = (option: OnChangeValue<unknown, boolean>): string => {
  if (is(Array, option)) {
    const values = option.map((o) => (is(Object, o) ? o?.value : ''));
    return values.join('-');
  }
  if (is(Object, option)) {
    const v = option?.value || '';
    return is(String, v) ? v : `${v}`;
  }
  return '';
};

const multiSelectSerializer = (selects: ISelectOption[] = []): ISelectOption['value'][] => selects?.map(({ value }) => value);

export {
  PrefixCreateLabel,
  generateCreatedOption,
  removeCreatedPrefixFromLabel,
  isCreatedOption,
  getOptionByValue,
  getQueryValueFromOnChange,
  getInitOption,
  multiSelectSerializer,
};
