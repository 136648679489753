const ToastItemType = {
  INFO: 'info',
  SUCCESS: 'success',
  ERROR: 'error',
} as const;
type IToastItemType = typeof ToastItemType[keyof typeof ToastItemType];
interface IToastItemProps {
  title: string
  description?: string
  id: number
  type: IToastItemType
}
interface IToastsItemMarkSC {
  type: IToastItemType
}

export {
  ToastItemType,
};
export type {
  IToastItemType,
  IToastItemProps,
  IToastsItemMarkSC,
};
