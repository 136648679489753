import moment, { LocaleSpecification } from 'moment';
import 'moment/locale/ru';
import Alt from 'helpers/alt';

const localeSpec: LocaleSpecification = {
  invalidDate: Alt.DATE,
  monthsShort: [
    'янв', 'фев', 'мар', 'апр', 'май', 'июн',
    'июл', 'авг', 'сен', 'окт', 'ноя', 'дек',
  ],
  weekdaysMin: [
    'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс',
  ],
};

const momentLocalesInit = () => {
  moment.locale('ru');
  moment.updateLocale('ru', localeSpec);
};

export {
  localeSpec,
  momentLocalesInit,
};
