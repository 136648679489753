import { FormRenderProps } from 'react-final-form';
import { FunctionComponent, PropsWithChildren, SVGProps } from 'react';

enum FormModalType {
  // По центру
  PRIMARY = 'primary',
  // Справа во всю высоту
  SECONDARY = 'secondary',
}

interface IFormModalSubmitButton {
  text?: string
  icon?: FunctionComponent<SVGProps<SVGSVGElement>> | null
}
type IFormModalProps = PropsWithChildren<{
  title: string
  handleSubmit: FormRenderProps['handleSubmit']
  submitButton?: IFormModalSubmitButton
  onClose?: () => void
  type?: FormModalType
  isLoading?: boolean
}>;
interface IFormModalWrapSC {
  type: FormModalType
}

export {
  FormModalType,
};
export type {
  IFormModalProps,
  IFormModalWrapSC,
};
