const Alt = {
  FULL_NAME: 'Пользователь',
  USERNAME: 'username',
  PROJECT_ABBREVIATION: 'GS',
  NAME: 'Без названия',
  DATA: 'Нет данных',
  DATE: 'Нет даты',
  DATE_DIFF_MINUTES: '?',
  DATA_MASCULINE: 'Не указан',
  DATA_FEMININE: 'Не указана',
  DATA_NEUTER: 'Не указано',
  SELECT: 'Выберите...',
  ADDRESS_TYPE: 'Дополнительный адрес',
  DOCUMENTS_KIND: 'Файл',
  MESSAGES: 'Нет уведомлений',
  FILE_DATE: 'Без даты',
  FILE_SIZE: '*',
  TOAST_TITLE: 'Уведомление',
  PACKAGE_TYPE: 'Неизвестный тип',
  MEASUREMENT: '?',
  ERROR_CODE: 'Код ошибки не известен',
  SETTINGS: 'Настройки',
  OPTION: '?',
  USERS_GROUPS: 'Без групп',
} as const;

export default Alt;
