import useSelector from 'hooks/redux/useSelector';
import StateName from 'helpers/stateNames';
import { profileApi } from 'components/services/profile/profileService';
import { hasPermissions, IPermissionsEqArgs } from 'helpers/permissions';
import { settingsApi } from '../../components/services/settings/settingsService';

interface IUsePermissionsProps {
  current?: IPermissionsEqArgs['currentCodenames']
  compareBy?: IPermissionsEqArgs['compareBy']
}
interface IUsePermissions {
  has: boolean
}

function usePermissions(props: IUsePermissionsProps): IUsePermissions {
  const { current = [], compareBy } = props;

  const authState = useSelector((state) => state[StateName.AUTH]);
  const isAuth = authState.verify;

  const { useDetailQuery: useProfileQuery, usePermissionsQuery } = profileApi;
  const { useUsersGroupsListQuery } = settingsApi;

  const { data: profileData } = useProfileQuery(undefined, { skip: !isAuth });
  const { data: permissionsData } = usePermissionsQuery(undefined, { skip: !isAuth });
  const { data: groupsData } = useUsersGroupsListQuery({ page: 1, pageSize: 1000 }, { skip: !isAuth });

  const has = hasPermissions({
    currentCodenames: current,
    allPermissions: permissionsData,
    profilePermissionsIds: profileData?.permissions,
    allGroups: groupsData?.results,
    profileGroupsIds: profileData?.groups,
    compareBy,
    isSuperuser: profileData?.isSuperuser,
  });

  return {
    has,
  };
}

export default usePermissions;
