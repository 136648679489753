import styled from 'styled-components/macro';
import Avatar from 'components/common/avatar/Avatar';
import { StatusLabelLabel } from 'components/common/status/label/StatusLabelStyles';
import { INameTileCaptionSC } from './NameTileTypes';

const NameTileWrap = styled.div`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
`;
const NameTileAvatar = styled(Avatar)`
  margin-right: 10px;
`;
const NameTileCaption = styled.div<INameTileCaptionSC>`
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  max-width: ${({ withAvatar }) => (withAvatar ? 'calc(100% - 55px)' : '100%')};
`;
const NameTileCaptionText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const NameTileName = styled(NameTileCaptionText)`
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  margin-bottom: 3px;
  color: ${({ theme }) => theme.palette.main[360]};
`;
const NameTileDescription = styled(NameTileCaptionText)`
  font-weight: 400;
  font-size: 13px;
  line-height: 1.2;
  &, & ${StatusLabelLabel} {
    color: ${({ theme }) => theme.palette.main[260]};
  }
`;

export {
  NameTileWrap,
  NameTileAvatar,
  NameTileCaption,
  NameTileCaptionText,
  NameTileName,
  NameTileDescription,
};
