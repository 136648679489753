import styled from 'styled-components/macro';
import { SidebarMenuBase } from 'components/features/sidebar/SidebarStyles';
import { ISidebarThemeDropdownItemSC } from './SidebarThemeTypes';

const SidebarThemeWrap = styled(SidebarMenuBase)`
  position: relative;
  padding-top: 13px;
  padding-bottom: 13px;
  border-top: ${({ theme }) => `1px solid ${theme.palette.main[140]}`};
  z-index: 1000;
`;
const SidebarThemeDropdown = styled.div`
  position: absolute;
  left: calc(100% + 16px);
  bottom: 13px;
  display: flex;
  flex-flow: column nowrap;
  width: 155px;
  height: auto;
  border-radius: 14px;
  padding: 8px;
  background: ${({ theme }) => theme.palette.main[10]};
  border: ${({ theme }) => `1px solid ${theme.palette.main[140]}`};
  gap: 10px;
  & > *:not(:last-child) {
    padding-bottom: 10px;
    border-bottom: ${({ theme }) => `1px solid ${theme.palette.main[140]}`};
  }
`;
const SidebarThemeDropdownBlock = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 10px;
`;
const SidebarThemeDropdownItem = styled.div<ISidebarThemeDropdownItemSC>`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  border: ${({ theme, isActive }) => `1px solid ${isActive ? theme.palette.orange[10] : theme.palette.main[140]}`};
  border-radius: 6px;
  padding: 6px 8px;
  cursor: pointer;
  & > img {
    width: 123px;
    height: 82px;
    object-fit: cover;
    margin-bottom: 6px;
    border-radius: 2px;
  }
  & > span {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.2;
    & > svg {
      width: 18px;
      height: 18px;
      margin-right: 6px;
    }
    align-self: flex-start;
  }
`;

export {
  SidebarThemeWrap,
  SidebarThemeDropdown,
  SidebarThemeDropdownBlock,
  SidebarThemeDropdownItem,
};
