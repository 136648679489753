import React, { useState } from 'react';
import Alt from 'helpers/alt';
import { IAvatarProps } from './AvatarTypes';
import { AvatarWrap, AvatarImage } from './AvatarStyles';

function Avatar(props: IAvatarProps) {
  const {
    name,
    id = 0,
    src,
    icon: Icon,
    size = '38px',
    letterLength = 2,
    className,
  } = props;

  const [ hasError, setHasError ] = useState<boolean>(false);

  const palette = id % 3;

  const clearName = name?.replace(/[^a-zA-Zа-яА-ЯёЁ0-9\s]/g, '');
  const nameArr = (clearName || Alt.PROJECT_ABBREVIATION).split(' ');
  const isOneMoreString = nameArr.length > 1;
  const nameFirstLetter = nameArr[0]?.slice(0, 1);
  const nameSecondLetter = isOneMoreString ? nameArr[1]?.slice(0, 1) : nameArr[0]?.slice(1, 2);
  const nameLetters = letterLength === 2 ? `${nameFirstLetter} ${nameSecondLetter}` : (nameFirstLetter || nameSecondLetter);

  const imageEl = () => {
    if (hasError) return nameLetters;
    if (src) {
      return (
        <AvatarImage
          src={src}
          onError={() => setHasError(true)}
        />
      );
    }
    if (Icon) return <Icon />;
    return nameLetters;
  };

  return (
    <AvatarWrap
      size={size}
      palette={palette}
      letterLength={letterLength}
      className={className}
    >
      {imageEl()}
    </AvatarWrap>
  );
}

export default Avatar;
