import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ThemeAlias,
  ThemeBase,
  getThemeAliasFromStorage,
  getThemeBaseFromStorage,
} from 'helpers/themes';
import { StorageName, storage } from 'helpers/storage';
import StateName from 'helpers/stateNames';
import { IThemeState, ISetTheme } from 'components/services/themes/themesTypes';

const initialState: IThemeState = {
  alias: getThemeAliasFromStorage(),
  base: getThemeBaseFromStorage(),
};

const themesSlice = createSlice({
  name: StateName.THEMES,
  initialState,
  reducers: {
    setTheme: (state: IThemeState, action: PayloadAction<ISetTheme>) => {
      const { payload: { alias, base = ThemeBase.CUSTOM } } = action;

      storage(StorageName.THEME_BASE).setValue(base);
      storage(StorageName.THEME_ALIAS).setValue(alias);

      state.base = base;
      state.alias = alias;
    },
    setThemeBase: (state: IThemeState, action: PayloadAction<IThemeState['base']>) => {
      const { payload: base } = action;

      if (base === ThemeBase.SYSTEM) {
        const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

        const newAlias = darkModeMediaQuery ? ThemeAlias.DARK : ThemeAlias.LIGHT;

        storage(StorageName.THEME_ALIAS).setValue(newAlias);

        state.alias = newAlias;
      }

      storage(StorageName.THEME_BASE).setValue(base);

      state.base = base;
    },
  },
});

export {
  themesSlice,
};
