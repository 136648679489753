import { AxiosResponse, AxiosResponseHeaders } from 'axios';
import {
  compose,
  is,
  map,
  pipe,
} from 'ramda';
import { mapKeys } from 'helpers/array';
import { RequestContentType } from 'helpers/requests';

const stringToCamelCase = (str: string): string => str
  .replace(/_/g, ' ')
  .replace(/-/g, ' ')
  .replace(/(?:^\w|[A-Z]|_|\b\w)/g, (letter, index) => (index === 0
    ? letter.toLowerCase()
    : letter.toUpperCase()))
  .replace(/\s+/g, '');

const dataToCamelCase = (data: AxiosResponse['data']): AxiosResponse['data'] => {
  if (is(Array, data)) {
    return map(dataToCamelCase, data);
  }

  if (is(Object, data)) {
    return pipe(
      mapKeys(stringToCamelCase),
      map(dataToCamelCase),
    )(data);
  }

  return data;
};

const stringToSnakeCase = (str: string): string => str
  .replace(/\./g, '__')
  .replace(/([A-Z])/g, ($1) => `_${$1.toLowerCase()}`);

const dataToSnakeCase = (data: AxiosResponse['data']): AxiosResponse['data'] => {
  if (is(Array, data)) {
    return map(dataToSnakeCase, data);
  }

  if (is(Object, data)) {
    return compose(
      map(dataToSnakeCase),
      mapKeys(stringToSnakeCase),
    )(data);
  }

  return data;
};

const responseToCamelCase = (data: AxiosResponse['data'], headers?: AxiosResponseHeaders) => {
  const responseContentType = headers?.['content-type'];

  if (responseContentType === RequestContentType.JSON) {
    if (is(String, data)) {
      return dataToCamelCase(JSON.parse(data));
    }
    return dataToCamelCase(data);
  }

  if (is(Object, data) || is(Array, data)) {
    return dataToCamelCase(data);
  }

  return data;
};

export {
  stringToCamelCase,
  dataToCamelCase,
  stringToSnakeCase,
  dataToSnakeCase,
  responseToCamelCase,
};
