import React, { useState } from 'react';
import SelectStatic from 'components/common/fields/select/static/SelectStatic';
import { filtersApi } from 'components/services/filters/filtersService';
import { pathOr } from 'ramda';
import { ISelectOption } from 'components/common/fields/select/SelectTypes';
import useDeepEffect from 'hooks/core/useDeepEffect';
import { ISelectDynamicProps } from './SelectDynamicTypes';

function SelectDynamic(props: ISelectDynamicProps) {
  const {
    api,
    optionPaths,
    isPagination = false,
    isSearchable = false,
    isMulti = false,
    isOnlyStaticSearchable = false,
    ...rest
  } = props;

  const { input } = rest;

  const [ search, setSearch ] = useState<string | undefined>(undefined);

  const apiUrl = typeof api === 'string' ? api : api.url;
  const apiParams = typeof api !== 'string' ? api?.params : {};
  const searchParams = isSearchable && !isOnlyStaticSearchable ? { search } : {};

  const basicParams = {
    ...searchParams,
    ...apiParams,
  };

  const params = isPagination ? {
    pageSize: 100,
    ...basicParams,
  } : basicParams;

  const { useOptionsQuery } = filtersApi;

  const { data, isLoading, isFetching } = useOptionsQuery({ api: apiUrl, params });

  const loading = isLoading || isFetching;

  useDeepEffect(() => () => input?.onChange(undefined), [ apiParams ]);

  const labelPath = optionPaths?.label || [ 'name' ];
  const valuePath = optionPaths?.value || [ 'id' ];

  const dataArr = isPagination ? data?.results : data;
  const options: ISelectOption[] = Array.isArray(dataArr) && dataArr.length > 0
    ? dataArr?.map((item) => ({
      label: pathOr('', labelPath, item),
      value: pathOr('', valuePath, item),
    }))
    : [];

  return (
    <SelectStatic
      isLoading={loading}
      options={options}
      isMulti={isMulti}
      isSearchable={isSearchable || isOnlyStaticSearchable}
      onInputChange={(label: string) => setSearch(label || undefined)}
      disableStaticFilter={!isOnlyStaticSearchable}
      {...rest}
    />
  );
}

export default SelectDynamic;
