import React from 'react';
import Content from 'components/common/content/Content';

function Fallback() {
  return (
    <Content isLoading />
  );
}

export default Fallback;
