import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Portal from 'components/common/portal/Portal';
import { PortalSelector } from 'helpers/portals';
import useSelector from 'hooks/redux/useSelector';
import StateName from 'helpers/stateNames';
import useDispatch from 'hooks/redux/useDispatch';
import { searchSlice } from 'components/services/search/searchService';
import Animation from 'components/common/animation/Animation';
import Mask from 'components/common/mask/Mask';
import { SearchGlobalElement } from './SearchGlobalStyles';

function SearchGlobal() {
  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const authState = useSelector((state) => state[StateName.AUTH]);
  const isAuth = authState.verify;

  const searchState = useSelector((state) => state[StateName.SEARCH]);
  const { isOpen } = searchState;

  const handleClose = () => dispatch(searchSlice.actions.close());

  useEffect(() => {
    const listener = (ev: KeyboardEvent) => {
      if (isAuth) {
        if (ev.altKey && ev.code === 'KeyK') {
          ev.preventDefault();
          dispatch(searchSlice.actions.toggle());
        }
        if (ev.code === 'Escape') {
          ev.preventDefault();
          dispatch(searchSlice.actions.close());
        }
      }
    };

    window.addEventListener('keydown', listener);

    return () => {
      window.removeEventListener('keydown', listener);
    };
  }, [ isAuth, isOpen, pathname ]);

  if (isOpen) {
    return (
      <Portal selector={PortalSelector.SEARCH}>
        <Mask onClick={handleClose} />
        <Animation>
          <SearchGlobalElement autoFocus />
        </Animation>
      </Portal>
    );
  }
  return null;
}

export default SearchGlobal;
