import Alt from 'helpers/alt';

enum StaffType {
  HEAD = 'head',
  ACCOUNTANT = 'accountant',
  PERSON_IN_CHARGER = 'person_in_charge',
}
enum StaffPosition {
  HEAD = 'CEO',
  ACCOUNTANT = 'accountant',
  PERSON_IN_CHARGER = 'Manager',
}

interface IStaffItemLabel {
  primary: string
  form: string
}
interface IStaffItem {
  label: IStaffItemLabel
  type: StaffType
  position: StaffPosition
}
type IStaffsList = IStaffItem[];

const staffsList: IStaffsList = [
  {
    label: {
      primary: 'Руководитель',
      form: 'Имя руководителя',
    },
    type: StaffType.HEAD,
    position: StaffPosition.HEAD,
  },
  {
    label: {
      primary: 'Бухгалтер',
      form: 'Имя бухгалтера',
    },
    type: StaffType.ACCOUNTANT,
    position: StaffPosition.ACCOUNTANT,
  },
  {
    label: {
      primary: 'Ответственный по работе',
      form: 'Имя ответственного по работе',
    },
    type: StaffType.PERSON_IN_CHARGER,
    position: StaffPosition.PERSON_IN_CHARGER,
  },
];

const getLabelByType = (type?: StaffType | string | null): string => {
  const currentLabel = staffsList?.find(({ type: t }) => t === type)?.label.primary;

  return currentLabel ?? Alt.DATA_FEMININE;
};

export type {
  IStaffsList,
};
export {
  StaffType,
  StaffPosition,
  staffsList,
  getLabelByType,
};
