import React from 'react';
import Alt from 'helpers/alt';
import { NoDataWrap } from './NoDataStyles';

function NoData() {
  return (
    <NoDataWrap>{Alt.DATA}</NoDataWrap>
  );
}

export default NoData;
