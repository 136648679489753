import Alt from 'helpers/alt';
import { ICompaniesAddressResponse } from 'types/response';

const AddressType = {
  LEGAL: 'legal',
  ACTUAL: 'actual',
} as const;
type IAddressType = typeof AddressType[keyof typeof AddressType];

interface IAddressItem {
  label: string
  type: IAddressType
}
type IAddressesList = IAddressItem[];

const addressesList: IAddressesList = [
  {
    label: 'Юридический адрес',
    type: AddressType.LEGAL,
  },
  {
    label: 'Фактический адрес',
    type: AddressType.ACTUAL,
  },
];

const getAddressTypeLabel = (type: ICompaniesAddressResponse['addressType']) => {
  const current = addressesList.find(({ type: t }) => t === type);
  return current?.label || Alt.ADDRESS_TYPE;
};

export type {
  IAddressType,
  IAddressesList,
};
export {
  AddressType,
  addressesList,
  getAddressTypeLabel,
};
