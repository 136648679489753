import React from 'react';
import { IStatusLabelProps } from './StatusLabelTypes';
import {
  StatusLabelWrap,
  StatusLabelMark,
  StatusLabelLabel,
} from './StatusLabelStyles';

function StatusLabel(props: IStatusLabelProps) {
  const {
    label,
    state,
  } = props;

  return (
    <StatusLabelWrap>
      <StatusLabelMark
        state={state}
      />
      <StatusLabelLabel
        state={state}
        data-tooltip={label}
      >
        {label}
      </StatusLabelLabel>
    </StatusLabelWrap>
  );
}

export default StatusLabel;
