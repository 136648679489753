type IValue = string | number | null | undefined;

const toNumber = (value: IValue, alt: number = 0): number => {
  const number = value ? Number(value) : alt;
  return !Number.isNaN(number) ? number : alt;
};

const toNumberFormatted = (value: IValue, fixCount: number | undefined = undefined, alt: number = 0): string => {
  const number = toNumber(value, alt);
  const formatter = new Intl.NumberFormat('ru-RU', {
    minimumFractionDigits: fixCount,
    maximumFractionDigits: fixCount,
  });
  return formatter.format(number).replace(',', '.');
};

export {
  toNumber,
  toNumberFormatted,
};
