import styled from 'styled-components/macro';
import {
  IStatusLabelLabel,
  IStatusLabelMark,
} from './StatusLabelTypes';

const StatusLabelWrap = styled.div`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 0 15px;
`;
const StatusLabelMark = styled.b<IStatusLabelMark>`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  content: "";
  width: 8px;
  min-width: 8px;
  height: 8px;
  min-height: 8px;
  border: ${({ theme, state }) => `1px solid ${theme.fixed.status.label.border[state]}`};
  border-radius: 100%;
  background: ${({ theme, state }) => theme.fixed.status.label.background[state]};
`;
const StatusLabelLabel = styled.span<IStatusLabelLabel>`
  color: ${({ theme, state }) => theme.fixed.status.label.color[state]};
  font-size: 14px;
  line-height: 1.2;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export {
  StatusLabelWrap,
  StatusLabelMark,
  StatusLabelLabel,
};
