import {
  curry,
  keys,
  map,
  values,
  zipObj,
} from 'ramda';

const mapKeys = (obj: Object) => curry(
  (fn, o) => zipObj(
    // @ts-ignore
    map(fn, keys(o)),
    values(o),
  ),
)(obj);

export {
  mapKeys,
};
