const PortalSelector = {
  ERROR: 'error-portal',
  FRAME: 'frame-portal',
  FORM_MODAL: 'form-modal-portal',
  MODAL: 'modal-portal',
  COMPANIES_PRODUCTS_CONTROLS: 'companies-products-controls-portal',
  SEARCH: 'search-portal',
  TOASTS: 'toasts-portal',
  TOOLTIP: 'tooltip-portal',
} as const;

export type IPortalSelector = typeof PortalSelector[keyof typeof PortalSelector];

const portalSelectors: IPortalSelector[] = [
  PortalSelector.ERROR,
  PortalSelector.FRAME,
  PortalSelector.FORM_MODAL,
  PortalSelector.MODAL,
  PortalSelector.COMPANIES_PRODUCTS_CONTROLS,
  PortalSelector.SEARCH,
  PortalSelector.TOASTS,
  PortalSelector.TOOLTIP,
];

const portalsInit = () => {
  portalSelectors.map((item: string) => {
    const div = document.createElement('div');
    div.id = item;
    document.body.appendChild(div);
    return null;
  });
  return null;
};

export {
  PortalSelector,
  portalSelectors,
  portalsInit,
};
