import { createApi } from '@reduxjs/toolkit/query/react';
import StateName from 'helpers/stateNames';
import { RequestAPI } from 'helpers/requests';
import { axiosBaseQuery } from 'helpers/axios';
import {
  ICompaniesByStatusResponse,
  IFinancesIncomeResponse,
  INewCompaniesByYearsResponse,
  ICompaniesByBusinessTypesResponse,
  INewCompaniesByMonthsResponse,
  IProductsByBranchResponse,
} from 'types/response';
import { dataToSnakeCase } from 'helpers/register';
import { vsprintf } from 'sprintf-js';
import {
  IFinancesIncomeArgs,
  INewCompaniesByYearsArgs,
  INewCompaniesByMonthsArgs,
  IProductsByBranchArgs,
} from './statisticsTypes';

const statisticsApi = createApi({
  reducerPath: StateName.STATISTICS_API,
  baseQuery: axiosBaseQuery(),
  // Время жизни данных в кеше, когда они не используются в сек. Не мало ли 0?!
  keepUnusedDataFor: 0,
  endpoints: (build) => ({
    companiesByStatus: build.query<ICompaniesByStatusResponse, void>({
      query() {
        return {
          url: RequestAPI.STATISTICS_COMPANIES_BY_STATUS,
        };
      },
    }),
    financesIncome: build.query<IFinancesIncomeResponse, IFinancesIncomeArgs>({
      query(args) {
        return {
          url: RequestAPI.STATISTICS_FINANCES_INCOME,
          params: dataToSnakeCase(args),
        };
      },
    }),
    newCompaniesByYears: build.query<INewCompaniesByYearsResponse, INewCompaniesByYearsArgs>({
      query(args) {
        return {
          url: RequestAPI.STATISTICS_NEW_COMPANIES_BY_YEARS,
          params: dataToSnakeCase(args),
        };
      },
    }),
    newCompaniesByMonths: build.query<INewCompaniesByMonthsResponse, INewCompaniesByMonthsArgs>({
      query({ year, ...args }) {
        return {
          url: vsprintf(RequestAPI.STATISTICS_NEW_COMPANIES_BY_MONTHS, [ year ]),
          params: dataToSnakeCase(args),
        };
      },
    }),
    companiesByBusinessTypes: build.query<ICompaniesByBusinessTypesResponse, void>({
      query(args) {
        return {
          url: RequestAPI.STATISTICS_COMPANIES_BY_BUSINESS_TYPES,
          params: dataToSnakeCase(args),
        };
      },
    }),
    productsByBranch: build.query<IProductsByBranchResponse, IProductsByBranchArgs>({
      query({ year }) {
        return {
          url: vsprintf(RequestAPI.STATISTICS_PRODUCTS_BY_BRANCH, [ year ]),
        };
      },
    }),
  }),
});

export {
  statisticsApi,
};
