import { createSlice } from '@reduxjs/toolkit';
import StateName from 'helpers/stateNames';
import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'helpers/axios';
import { RequestAPI } from 'helpers/requests';
import { dataToSnakeCase } from 'helpers/register';
import {
  IExportState,
  IExportGenerateArgs,
} from 'components/services/export/exportTypes';
import {
  IExportConfigResponse,
  IExportGenerateResponse,
  IExportLastResponse,
} from 'types/response';

const initialState: IExportState = {
  isOpen: false,
};

const exportApi = createApi({
  reducerPath: StateName.EXPORT_API,
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    config: build.query<IExportConfigResponse, void>({
      query() {
        return {
          url: RequestAPI.EXPORT_CONFIG,
        };
      },
    }),
    last: build.query<IExportLastResponse, void>({
      query() {
        return {
          url: RequestAPI.EXPORT,
        };
      },
    }),
    generate: build.mutation<IExportGenerateResponse, IExportGenerateArgs>({
      query(data) {
        return {
          url: RequestAPI.EXPORT,
          data: dataToSnakeCase(data),
          method: 'POST',
        };
      },
    }),
  }),
});

const exportSlice = createSlice({
  name: StateName.EXPORT,
  initialState,
  reducers: {
    open: (state: IExportState) => {
      state.isOpen = true;
    },
    close: (state: IExportState) => {
      state.isOpen = false;
    },
    toggle: (state: IExportState) => {
      state.isOpen = !state.isOpen;
    },
  },
});

export {
  exportApi,
  exportSlice,
};
