import styled, { css } from 'styled-components/macro';
import { FormModalType, IFormModalWrapSC } from './FormModalTypes';

const getWrapParamsByType = (type: FormModalType) => {
  switch (type) {
    case FormModalType.PRIMARY: return css`
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `;
    default: return css`
      top: 18px;
      right: 15px;
      bottom: 18px;
    `;
  }
};

const FormModalWrap = styled.form<IFormModalWrapSC>`
  position: fixed;
  display: flex;
  flex-flow: column nowrap;
  flex-shrink: 0;
  padding: 38px 0 25px;
  width: ${({ theme }) => theme.width.modal};
  min-height: 398px;
  max-height: calc(100vh - 36px);
  background: ${({ theme }) => theme.palette.main[70]};
  border-radius: 10px;
  z-index: 1000;
  ${({ type }) => getWrapParamsByType(type)}
`;
const FormModalTitle = styled.h2`
  font-weight: 600;
  font-size: 26px;
  line-height: 1;
  letter-spacing: -0.01em;
  margin-bottom: 30px;
  padding: 0 70px 0 25px;
`;
const FormModalCloseButton = styled.div`
  position: absolute;
  top: 17px;
  right: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  & > svg {
    width: 20px;
    height: 20px;
  }
`;
const FormModalButtons = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin-top: auto;
  padding: 0 25px;
  & > button {
    flex-grow: 1;
  }
`;
const FormModalBody = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 0 25px 30px;
  flex-grow: 1;
  overflow: hidden auto;
  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export {
  FormModalWrap,
  FormModalTitle,
  FormModalCloseButton,
  FormModalButtons,
  FormModalBody,
};
