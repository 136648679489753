import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as ThemeIcon } from 'media/icons/menu-theme.svg';
import { ReactComponent as ThemeSystemIcon } from 'media/icons/theme-system.svg';
import { ReactComponent as ThemeDarkIcon } from 'media/icons/theme-dark.svg';
import { ReactComponent as ThemeLightIcon } from 'media/icons/theme-light.svg';
import themeSystemImg from 'media/images/theme-system.jpg';
import themeDarkImg from 'media/images/theme-dark.jpg';
import themeLightImg from 'media/images/theme-light.jpg';
import { SidebarMenuItemLabel, SidebarMenuItemWrap } from 'components/features/sidebar/menu/item/SidebarMenuItemStyles';
import useDispatch from 'hooks/redux/useDispatch';
import useSelector from 'hooks/redux/useSelector';
import { themesSlice } from 'components/services/themes/themesService';
import { IThemeAlias, ThemeAlias, ThemeBase } from 'helpers/themes';
import StateName from 'helpers/stateNames';
import Checkbox from 'components/common/fields/checkbox/Checkbox';
import { ISidebarThemeProps } from './SidebarThemeTypes';
import {
  SidebarThemeDropdown,
  SidebarThemeDropdownBlock,
  SidebarThemeDropdownItem,
  SidebarThemeWrap,
} from './SidebarThemeStyles';

function SidebarTheme(props:ISidebarThemeProps) {
  const {
    isShort = false,
    isCompressed = false,
    handleToggleCompressed: handleToggleCompressedBase,
  } = props;

  const buttonRef = useRef<HTMLAnchorElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();

  const [ isOpen, setIsOpen ] = useState<boolean>(false);

  const themeState = useSelector((state) => state[StateName.THEMES]);
  const themeAlias = themeState.alias;
  const themeBase = themeState.base;

  const isSystem = themeBase === ThemeBase.SYSTEM;

  const handleToggle = () => setIsOpen(!isOpen);
  const handleClose = () => setIsOpen(false);
  const handleSystem = () => {
    handleClose();
    dispatch(themesSlice.actions.setThemeBase(ThemeBase.SYSTEM));
  };
  const handleAlias = (alias: IThemeAlias) => {
    handleClose();
    dispatch(themesSlice.actions.setTheme({ alias }));
  };
  const handleToggleCompressed = () => {
    handleToggleCompressedBase();
    handleClose();
  };

  useEffect(() => {
    const listener = (ev: MouseEvent) => {
      const target = ev.target as HTMLElement;

      if (buttonRef.current && dropdownRef.current) {
        if (
          !buttonRef.current.contains(target)
          && !dropdownRef.current.contains(target)
        ) {
          handleClose();
        }
      }
    };
    window.addEventListener('click', listener);
    return () => window.removeEventListener('click', listener);
  }, [ isOpen ]);

  return (
    <SidebarThemeWrap isShort={isShort}>
      <SidebarMenuItemWrap
        ref={buttonRef}
        state={{ isActive: isOpen, isShort }}
        to="#"
        onClick={handleToggle}
      >
        <ThemeIcon />
        {!isShort ? (
          <SidebarMenuItemLabel>
            Оформление
          </SidebarMenuItemLabel>
        ) : null}
      </SidebarMenuItemWrap>
      {isOpen ? (
        <SidebarThemeDropdown ref={dropdownRef}>
          <SidebarThemeDropdownBlock>
            <SidebarThemeDropdownItem
              onClick={handleSystem}
              isActive={isSystem}
            >
              <img src={themeSystemImg} alt="" />
              <span>
                <ThemeSystemIcon />
                Системная
              </span>
            </SidebarThemeDropdownItem>
            <SidebarThemeDropdownItem
              onClick={() => handleAlias(ThemeAlias.DARK)}
              isActive={!isSystem && themeAlias === ThemeAlias.DARK}
            >
              <img src={themeDarkImg} alt="" />
              <span>
                <ThemeDarkIcon />
                Тёмная тема
              </span>
            </SidebarThemeDropdownItem>
            <SidebarThemeDropdownItem
              onClick={() => handleAlias(ThemeAlias.LIGHT)}
              isActive={!isSystem && themeAlias === ThemeAlias.LIGHT}
            >
              <img src={themeLightImg} alt="" />
              <span>
                <ThemeLightIcon />
                Светлая тема
              </span>
            </SidebarThemeDropdownItem>
          </SidebarThemeDropdownBlock>
          <SidebarThemeDropdownBlock>
            <Checkbox
              label="Компактное меню"
              checked={isCompressed}
              onClick={handleToggleCompressed}
            />
          </SidebarThemeDropdownBlock>
        </SidebarThemeDropdown>
      ) : null}
    </SidebarThemeWrap>
  );
}

export default SidebarTheme;
