import styled from 'styled-components/macro';
import { ICheckboxWrap } from './CheckboxTypes';

const CheckboxLabel = styled.label`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.01em;
  cursor: pointer;
  user-select: none;
  & > *:not(:last-child) {
    margin-right: 8px;
  }
`;
const CheckboxWrap = styled.div<ICheckboxWrap>`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 18px;
  height: 18px;
`;
const CheckboxInput = styled.input`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  border-radius: 5px;
  color: ${({ theme }) => theme.palette.main[10]};
  border: ${({ theme }) => `1px solid ${theme.palette.main[240]}`};
  cursor: pointer;
  transition: background .2s, border .2s, opacity .2s;
  &:checked {
    border: ${({ theme }) => `1px solid ${theme.palette.orange[20]}`};
    background: ${({ theme }) => theme.palette.orange[20]};
  }
  &:hover {
    transition: background .2s, border .2s, opacity .2s;
    &:checked {
      opacity: 0.7;
    }
    &:not(:checked) {
      border: ${({ theme }) => `1px solid ${theme.palette.orange[20]}`};
      background: ${({ theme }) => theme.palette.main[10]};
    }
  }
  & + svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    max-width: 12px;
    max-height: 12px;
    color: ${({ theme }) => theme.palette.main[10]};
    z-index: 10;
    pointer-events: none;
  }
  &:checked {
    & + svg {
      display: block;
    }
  }
`;

export {
  CheckboxLabel,
  CheckboxWrap,
  CheckboxInput,
};
