import { useDispatch as useD } from 'react-redux';
import { store } from 'helpers/redux';

type IDispatch = typeof store.dispatch;

const useDispatch = () => useD<IDispatch>();

export type { IDispatch };

export default useDispatch;
