import React from 'react';
import { ReactComponent as LoaderIcon } from 'media/svg/loader.svg';
import { LoaderWrap } from './LoaderStyles';
import { ILoaderProps, LoaderSize } from './LoaderTypes';

function Loader(props: ILoaderProps) {
  const {
    size = LoaderSize.LARGE,
    isInvert = false,
    className,
  } = props;

  return (
    <LoaderWrap
      isInvert={isInvert}
      size={size}
      className={className}
    >
      <LoaderIcon />
    </LoaderWrap>
  );
}

export default Loader;
