import styled, { css } from 'styled-components/macro';
import { blinkKeyframes } from 'helpers/keyframes';
import {
  ButtonSize,
  ButtonThemeType,
  IButtonWrap,
} from './ButtonTypes';

const ButtonWrap = styled.button<IButtonWrap>`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: ${({ theme, themeType }) => theme.fixed.button.background[themeType]};
  color: ${({ theme, themeType }) => theme.fixed.button.color[themeType]};
  transition: opacity .2s;
  border: ${({ theme, themeType }) => `1px solid ${theme.fixed.button.border[themeType]}`};
  line-height: 1.2;
  letter-spacing: 0.01em;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  text-align: center;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
  &:disabled {
    transition: opacity .2s;
    opacity: 0.5;
    cursor: not-allowed;
  }
  ${({ size }) => {
    switch (size) {
      case ButtonSize.MEDIUM: return css`
        height: 46px;
        font-size: 14px;
        font-weight: 600;
        padding: 5px 20px;
      `;
      case ButtonSize.SMALL: return css`
        height: 33px;
        font-size: 14px;
        font-weight: 600;
        padding: 8px 10px;
      `;
      default: return css`
        height: 58px;
        font-size: 15px;
        font-weight: 600;
        padding: 10px 25px;
      `;
    }
  }}
  ${({ themeType }) => {
    switch (themeType) {
      case ButtonThemeType.MAIN_SECONDARY: return css`
        border-radius: 100px;
      `;
      default: return css`
        border-radius: 10px;
      `;
    }
  }}
  ${({ isLoading }) => isLoading && css`
    animation: ${blinkKeyframes} 1s linear infinite;
  `}
`;
const ButtonIcon = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-right: 6px;
  width: 24px;
  height: 100%;
  & > svg {
    width: 22px;
    height: 22px;
  }
`;

export {
  ButtonWrap,
  ButtonIcon,
};
