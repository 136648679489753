import { isNil } from 'ramda';

const StorageName = {
  THEME_ALIAS: 'themeAlias',
  THEME_BASE: 'themeBase',
  GLOBAL: 'global',
  SIDEBAR_COMPRESSED: 'sidebarCompressed',
} as const;
type IStorageName = typeof StorageName[keyof typeof StorageName];

type IValue = string | number | boolean | Array<IValue> | Object;
const storage = (key: IStorageName | string) => ({
  setValue: (value: IValue) => {
    localStorage.setItem(key, JSON.stringify(value));
  },
  getValue: () => {
    const value = localStorage.getItem(key);
    try {
      if (!isNil(value)) {
        return JSON.parse(value);
      }
      return value;
    } catch (er) {
      return value;
    }
  },
});

export type {
  IStorageName,
};
export {
  StorageName,
  storage,
};
