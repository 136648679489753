import { PropsWithChildren } from 'react';
import { IButtonProps } from 'components/common/button/ButtonTypes';

const ContentBackground = {
  TRANSPARENT: 'transparent',
  SECONDARY: 'secondary',
} as const;
type IContentBackground = typeof ContentBackground[keyof typeof ContentBackground];
type IContentProps = PropsWithChildren<{
  background?: IContentBackground
  isLoading?: boolean
  button?: IButtonProps
  className?: string
}>;
interface IContentWrap {
  background?: string
  hasButton?: boolean
}

export type {
  IContentProps,
  IContentWrap,
};

export {
  ContentBackground,
};
