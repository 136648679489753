import React from 'react';
import Alt from 'helpers/alt';
import { INameTileProps } from './NameTileTypes';
import {
  NameTileWrap,
  NameTileAvatar,
  NameTileCaption,
  NameTileName,
  NameTileDescription,
} from './NameTileStyles';

function NameTile(props: INameTileProps) {
  const {
    name,
    description,
    id,
    src,
    icon,
    withAvatar = true,
    className,
  } = props;

  // TODO Норм ли решение typeof name === ReactElement | string?!
  const avatarEl = withAvatar ? (
    <NameTileAvatar
      name={typeof name === 'string' ? name : ''}
      id={id}
      src={src}
      icon={icon}
      size="42px"
    />
  ) : null;

  return (
    <NameTileWrap className={className}>
      {avatarEl}
      <NameTileCaption withAvatar={withAvatar}>
        <NameTileName data-tooltip={typeof name === 'string' ? name : undefined}>
          {name || Alt.NAME}
        </NameTileName>
        <NameTileDescription data-tooltip={typeof description === 'string' ? description : undefined}>
          {description || Alt.DATA}
        </NameTileDescription>
      </NameTileCaption>
    </NameTileWrap>
  );
}

export default NameTile;
