import { createApi } from '@reduxjs/toolkit/query/react';
import StateName from 'helpers/stateNames';
import { RequestAPI } from 'helpers/requests';
import { axiosBaseQuery } from 'helpers/axios';
import { IPermissionsResponse, IProfileDetailResponse } from 'types/response';

const profileApi = createApi({
  reducerPath: StateName.PROFILE_API,
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    detail: build.query<IProfileDetailResponse, void>({
      query() {
        return {
          url: RequestAPI.PROFILE,
        };
      },
    }),
    permissions: build.query<IPermissionsResponse, void>({
      query() {
        return {
          url: RequestAPI.PERMISSIONS,
        };
      },
    }),
  }),
});

export {
  profileApi,
};
