import styled from 'styled-components/macro';
import hexToRGBA from 'helpers/hexToRGBA';

const CheckboxTileFieldContainer = styled.label`
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
`;
const CheckboxTileFieldTitle = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.14px;
  border-radius: 62px;
  padding: 10px 15px;
  width: 100%;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.palette.main[190]};
  background: ${({ theme }) => theme.palette.main[50]};
  & > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    user-select: none;
  }
`;
const CheckboxTileFieldInput = styled.input`
  display: none;
  &:checked + ${CheckboxTileFieldTitle} {
    border-color: ${({ theme }) => theme.palette.orange[20]};
    background: ${({ theme }) => hexToRGBA(theme.palette.orange[20], 0.04)};
  }
`;

export {
  CheckboxTileFieldContainer,
  CheckboxTileFieldTitle,
  CheckboxTileFieldInput,
};
