import styled from 'styled-components/macro';
import { emergenceFromOpacityKeyframes } from 'helpers/keyframes';
import { ITooltipWrap } from './TooltipTypes';

const TooltipWrap = styled.div<ITooltipWrap>`
  position: fixed;
  width: max-content;
  max-width: 500px;
  font-size: 13px;
  line-height: 1.2;
  padding: 6px 11px 7px;
  border-radius: 6px;
  background: ${({ theme }) => theme.palette.grey[30]};
  color: ${({ theme }) => theme.palette.white[10]};
  transform: ${({
    isLeft,
  }) => (isLeft ? 'translateY(calc(-100% - 7px))' : 'translate(-100%, calc(-100% - 7px))')};
  pointer-events: none;
  animation: ${emergenceFromOpacityKeyframes} 1.5s linear;
  z-index: 1000;
`;

export {
  TooltipWrap,
};
