import styled from 'styled-components/macro';
import { IExportConfigFieldsToggleSC } from './ExportConfigFieldsTypes';

const ExportConfigFieldsContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 4px;
`;
const ExportConfigFieldsTop = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  background: ${({ theme }) => theme.palette.main[50]};
  border-radius: 6px;
  min-height: 48px;
  padding: 0 12px;
  gap: 14px;
`;
const ExportConfigFieldsToggle = styled.div<IExportConfigFieldsToggleSC>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-left: 14px;
  padding-right: 12px;
  margin-right: -12px;
  cursor: pointer;
  &:before {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    content: "";
    width: 1px;
    height: 16px;
    background: ${({ theme }) => theme.palette.main[240]};
  }
  & > span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    background: ${({ theme }) => theme.palette.main[240]};
    transform: ${({ isOpen }) => isOpen && 'rotate(180deg)'};
    & > svg {
      width: 14px;
      height: 14px;
      color: ${({ theme }) => theme.palette.main[10]};
    }
  }
`;
const ExportConfigFieldsBottom = styled.div`
  padding: 0 12px;
  & > * {
    padding: 12px 0;
    border-bottom: ${({ theme }) => `1px solid ${theme.palette.main[120]}`};
  }
`;

export {
  ExportConfigFieldsContainer,
  ExportConfigFieldsTop,
  ExportConfigFieldsBottom,
  ExportConfigFieldsToggle,
};
