const hexToRGBA = (hex: string, alpha = 1) => {
  const fullHex = `${hex}`.length === 4
    ? `#${hex.slice(1, 4).repeat(2)}`
    : hex;

  const r = parseInt(fullHex.slice(1, 3), 16);
  const g = parseInt(fullHex.slice(3, 5), 16);
  const b = parseInt(fullHex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export default hexToRGBA;
