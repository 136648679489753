import styled, { css } from 'styled-components/macro';
import { ITextFieldInput, TextTheme } from './TextFieldTypes';

const TextFieldWrap = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  flex-shrink: 0;
  width: 100%;
  font-size: 14px;
`;
const TextFieldInputCSS = css<ITextFieldInput>`
  width: 100%;
  height: ${({ theme, textTheme }) => theme.height.field[textTheme]};
  background: ${({ theme }) => theme.palette.main[50]};
  border-radius: 10px;
  border: ${({ theme }) => `1px solid ${theme.palette.main[190]}`};
  padding: ${({
    textTheme,
    hasPrefix,
    // eslint-disable-next-line no-nested-ternary
  }) => (textTheme === TextTheme.SECONDARY ? (hasPrefix ? '12px 14px 12px 36px' : '12px 14px') : '33px 14px 12px')};
  resize: none;
  &::placeholder {
    font-size: inherit;
    color: ${({ theme }) => theme.palette.main[270]};
    transition: opacity .2s;
  }
  &:hover {
    background: ${({ theme }) => theme.palette.main[50]};
    border: ${({ theme }) => `1px solid ${theme.palette.main[190]}`};
  }
  &:focus {
    background: ${({ theme }) => theme.palette.main[10]};
    border: ${({ theme }) => `1px solid ${theme.palette.main[360]}`};
    &::placeholder {
      opacity: 0;
      transition: opacity .2s;
    }
  }
  &:placeholder-shown:not(:focus) {
    // Поле ввода без значения
    & + label {
      top: ${({ theme }) => `calc(${theme.height.field.primary} / 2)`};
      transform: translateY(-50%);
      color: ${({ theme }) => theme.palette.main[270]};
      transition: color .2s, top .2s, transform .2s, font-size .2s;
      font-size: inherit;
    }
    & + div > svg, & + label + div > svg {
      color: ${({ theme }) => theme.palette.main[270]};
    }
  }
  &, &:hover, &:focus {
    transition: background .2s, border .2s;
  }
  &:disabled {
    background: ${({ theme }) => theme.palette.main[50]};
    color: ${({ theme }) => theme.palette.main[250]};
    border: 1px solid transparent;
    cursor: not-allowed;
    & + label {
      color: ${({ theme }) => theme.palette.main[250]} !important;
    }
  }
`;
const TextFieldInput = styled.input<ITextFieldInput>`
  ${TextFieldInputCSS};
`;
const TextFieldLabel = styled.label`
  // Поле ввода со значением
  position: absolute;
  top: 12px;
  left: 14px;
  transform: unset;
  color: ${({ theme }) => theme.palette.main[340]};
  transition: color .2s, top .2s, transform .2s, font-size .2s;
  font-size: 13px;
  line-height: 1.2;
  pointer-events: none;
`;
const TextFieldPrefix = styled.div`
  position: absolute;
  top: calc(50% + 2px);
  left: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  & > svg {
    width: 17px;
    height: 17px;
    color: ${({ theme }) => theme.palette.main[340]};
  }
`;
const TextFieldCaption = styled.div`
  color: ${({ theme }) => theme.palette.main[270]};
  font-weight: 400;
  font-size: 14px;
  line-height: 1.35;
  letter-spacing: 0.01em;
  margin: 14px 0 10px;
`;

export {
  TextFieldWrap,
  TextFieldInputCSS,
  TextFieldInput,
  TextFieldLabel,
  TextFieldPrefix,
  TextFieldCaption,
};
