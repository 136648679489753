import React, { PropsWithChildren, useEffect } from 'react';
import useDispatch from 'hooks/redux/useDispatch';
import { ThemeProvider as Provider } from 'styled-components/macro';
import { darkTheme, lightTheme } from 'helpers/theme';
import NormalizeStyles from 'components/styles/NormalizeStyles';
import MainStyles from 'components/styles/MainStyles';
import useSelector from 'hooks/redux/useSelector';
import StateName from 'helpers/stateNames';
import { ThemeAlias, ThemeBase } from 'helpers/themes';
import { themesSlice } from 'components/services/themes/themesService';
import 'media/fonts/fonts.css';
import 'swiper/css';
import 'react-datepicker/dist/react-datepicker.css';

type IThemeProviderProps = PropsWithChildren<{}>;

function ThemeProvider(props: IThemeProviderProps) {
  const { children } = props;

  const dispatch = useDispatch();

  const themeState = useSelector((state) => state[StateName.THEMES]);
  const themeAlias = themeState.alias;
  const themeBase = themeState.base;

  const isSystemTheme = themeBase === ThemeBase.SYSTEM;

  const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

  const setSystemTheme = (matches: MediaQueryListEvent['matches']) => {
    dispatch(themesSlice.actions.setTheme({
      alias: matches ? ThemeAlias.DARK : ThemeAlias.LIGHT,
      base: ThemeBase.SYSTEM,
    }));
  };

  useEffect(() => {
    if (isSystemTheme) {
      setSystemTheme(darkModeMediaQuery.matches);
    }

    const listener = ({ matches }: MediaQueryListEvent) => {
      if (isSystemTheme) setSystemTheme(matches);
    };

    darkModeMediaQuery.addEventListener('change', listener);
    return () => darkModeMediaQuery.removeEventListener('change', listener);
  }, [ themeBase ]);

  const theme = (() => {
    switch (themeAlias) {
      case ThemeAlias.LIGHT: return lightTheme;
      case ThemeAlias.DARK: return darkTheme;
      default: return lightTheme;
    }
  })();

  return (
    <Provider theme={theme}>
      <NormalizeStyles />
      <MainStyles />
      {children}
    </Provider>
  );
}

export default ThemeProvider;
