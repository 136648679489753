import React from 'react';
import ReactDOM from 'react-dom/client';
import Root from 'components/app/Root';
import { portalsInit } from 'helpers/portals';

portalsInit();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(<Root />);
