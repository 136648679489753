import React from 'react';
import { ILabelProps } from './LabelTypes';
import { LabelContainer, LabelPrefix, LabelText } from './LabelStyles';

function Label(props: ILabelProps) {
  const {
    prefix,
    separator = ':',
    isInline = false,
    children,
  } = props;

  return (
    <LabelContainer isInline={isInline}>
      {prefix ? (
        <LabelPrefix>
          {`${prefix}${separator}`}
        </LabelPrefix>
      ) : null}
      <LabelText>
        {children}
      </LabelText>
    </LabelContainer>
  );
}

export default Label;
