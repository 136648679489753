import styled from 'styled-components/macro';
import { ILoaderWrap } from './LoaderTypes';

const LoaderWrap = styled.div<ILoaderWrap>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  margin: 5px auto;
  grid-column: 1 / -1;
  & > svg {
    width: ${({ size }) => size};
    height: ${({ size }) => size};
    color: ${({ theme, isInvert }) => (isInvert ? theme.palette.main[10] : theme.palette.main[360])};
  }
`;

export {
  LoaderWrap,
};
