import styled from 'styled-components/macro';
import { ILabelContainerSC } from './LabelTypes';

const LabelContainer = styled.div<ILabelContainerSC>`
  display: ${({ isInline }) => (isInline ? 'inline-flex' : 'flex')};
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
`;
const LabelPrefix = styled.div`
  font-weight: 700;
  color: ${({ theme }) => theme.palette.red[10]};
  margin-right: 5px;
  flex-shrink: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  user-select: none;
`;
const LabelText = styled.div`
  flex-shrink: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  user-select: none;
`;

export {
  LabelContainer,
  LabelPrefix,
  LabelText,
};
