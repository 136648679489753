import styled from 'styled-components/macro';
import Button from 'components/common/button/Button';
import { IContentWrap } from './ContentTypes';

const ContentWrap = styled.div<IContentWrap>`
  position: ${({ hasButton }) => (hasButton ? 'relative' : 'static')};
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  width: 100%;
  max-width: 100%;
  background: ${({ background }) => background};
  padding-bottom: ${({ hasButton }) => (hasButton ? '100px' : 0)};
  overflow-y: auto;
`;
const ContentButton = styled(Button)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 27px;
`;

export {
  ContentWrap,
  ContentButton,
};
