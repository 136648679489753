import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useDispatch from 'hooks/redux/useDispatch';
import useSelector from 'hooks/redux/useSelector';
import StateName from 'helpers/stateNames';
import RoutePath from 'helpers/routePath';
import { toastsSlice } from 'components/services/toasts/toastsService';
import { ToastItemType } from 'components/common/toasts/item/ToastsItemTypes';

function useProtectPath() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { pathname, search } = useLocation();

  const authState = useSelector((state) => state[StateName.AUTH]);
  const isAuth = authState.verify;

  useEffect(() => {
    const isAuthPath = pathname === RoutePath.AUTH;

    if (!isAuth && !isAuthPath) {
      dispatch(toastsSlice.actions.add({
        title: 'Вы не авторизованы',
        description: 'Войдите в свой аккаунт',
        type: ToastItemType.ERROR,
      }));

      navigate({
        pathname: RoutePath.AUTH,
        search: `?redirect=${encodeURIComponent(`${pathname}${search}`)}`,
      }, {
        replace: true,
      });
    }
  }, [ isAuth, pathname ]);
}

export default useProtectPath;
