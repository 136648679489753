import React from 'react';
import { profileApi } from 'components/services/profile/profileService';
import { modalSlice } from 'components/services/modal/modalService';
import { authApi } from 'components/services/auth/authService';
import Alt from 'helpers/alt';
import useSelector from 'hooks/redux/useSelector';
import StateName from 'helpers/stateNames';
import useDispatch from 'hooks/redux/useDispatch';
import { useNavigate } from 'react-router-dom';
import RoutePath from 'helpers/routePath';
import { ISidebarProfileProps } from './SidebarProfileTypes';
import {
  SidebarProfileWrap,
  SidebarProfileAvatar,
  SidebarProfileFirstName,
  SidebarProfileUsername,
} from './SidebarProfileStyles';

function SidebarProfile(props: ISidebarProfileProps) {
  const { isShort } = props;

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const authState = useSelector((state) => state[StateName.AUTH]);
  const isAuth = authState.verify;

  const { useLogoutMutation } = authApi;

  const [ logout ] = useLogoutMutation();

  const { useDetailQuery } = profileApi;

  const { data } = useDetailQuery(undefined, { skip: !isAuth });

  const firstName = data?.firstName || '';
  const lastName = data?.lastName || '';

  const fullName = `${firstName} ${lastName}`.trim() || Alt.FULL_NAME;
  const username = data?.username || Alt.USERNAME;

  const handleLogout = () => dispatch(modalSlice.actions.open({
    title: 'Вы точно хотите выйти?',
    onConfirm: () => {
      logout();
      navigate(RoutePath.AUTH);
    },
  }));

  return (
    <SidebarProfileWrap
      onClick={handleLogout}
    >
      <SidebarProfileAvatar
        name={fullName}
        size="38px"
      />
      {!isShort ? (
        <div>
          <SidebarProfileFirstName>{fullName}</SidebarProfileFirstName>
          <SidebarProfileUsername>{username}</SidebarProfileUsername>
        </div>
      ) : null}
    </SidebarProfileWrap>
  );
}

export default SidebarProfile;
