import { IStatus, StatusState } from 'helpers/statuses';
import { ITabsList } from 'components/common/tabs/TabsTypes';

enum ContractStatusAlias {
  ALL = '',
  PAID = 'paid',
  NOT_PAID = 'not_paid',
  PARTIAL_PAID = 'partial_paid',
  OVERPAID = 'overpaid',
}

const ContractDetailAlias = {
  INFO: 'info',
  INVOICES: 'invoices',
} as const;
type IContractDetailAlias = typeof ContractDetailAlias[keyof typeof ContractDetailAlias];

type IContractStatus = IStatus<ContractStatusAlias>;

const contractStatuses: IContractStatus[] = [
  {
    label: {
      single: 'Оплаченный',
      multi: 'Оплаченные',
    },
    alias: ContractStatusAlias.PAID,
    state: StatusState.ACTIVE,
  },
  {
    label: {
      single: 'Не\u00A0оплаченный',
      multi: 'Не\u00A0оплаченные',
    },
    alias: ContractStatusAlias.NOT_PAID,
    state: StatusState.INACTIVE,
  },
  {
    label: {
      single: 'Оплачен\u00A0частично',
      multi: 'Оплачены\u00A0частично',
    },
    alias: ContractStatusAlias.PARTIAL_PAID,
    state: StatusState.PARTIALLY_ACTIVE,
  },
  {
    label: {
      single: 'Оплачен\u00A0больше',
      multi: 'Оплачены\u00A0больше',
    },
    alias: ContractStatusAlias.OVERPAID,
    state: StatusState.ACTIVE,
  },
];

const contractStatusesWithAll: IContractStatus[] = [
  {
    label: {
      single: 'Все договора',
      multi: 'Все договора',
    },
    alias: ContractStatusAlias.ALL,
    state: StatusState.ACTIVE,
  },
  ...contractStatuses,
];

const contractDetailAliases: ITabsList<IContractDetailAlias> = [
  {
    label: 'Инфо. о договоре',
    value: ContractDetailAlias.INFO,
  },
  {
    label: 'Счета',
    value: ContractDetailAlias.INVOICES,
  },
];

// TODO Норм ли через pathname.split?!
const getContractsDetailAlias = (pathname: string): string | undefined => pathname.split('/')?.[3];

export type {
  IContractDetailAlias,
  IContractStatus,
};

export {
  contractDetailAliases,
  ContractStatusAlias,
  contractStatuses,
  contractStatusesWithAll,
  ContractDetailAlias,
  getContractsDetailAlias,
};
