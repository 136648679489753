import styled from 'styled-components/macro';

const MainLayoutWrap = styled.div`
  display: flex;
  flex-flow: row nowrap;
  height: 100%;
  overflow: hidden;
  flex-grow: 1;
`;
const MainLayoutInner = styled.div`
  display: flex;
  flex-flow: row nowrap;
  height: 100%;
  overflow: hidden;
  flex-grow: 1;
`;

export {
  MainLayoutWrap,
  MainLayoutInner,
};
