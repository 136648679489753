import { useEffect, useRef, useLayoutEffect } from 'react';

function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef(callback);

  useLayoutEffect(() => {
    savedCallback.current = callback;
  }, [ callback ]);

  useEffect(() => {
    const id = (delay || delay === 0)
      ? setInterval(() => savedCallback.current(), delay)
      : null;

    return () => {
      if (id) clearInterval(id);
    };
  }, [ delay ]);
}

export default useInterval;
