import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isNil } from 'ramda';
import StateName from 'helpers/stateNames';
import useSelector from 'hooks/redux/useSelector';
import useDispatch from 'hooks/redux/useDispatch';
import { navigateSlice } from 'components/services/navigate/navigateService';

// Hook позволяющий использовать перенаправления вне react компонентов через redux
function useRedirect() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const navigateState = useSelector((state) => state[StateName.NAVIGATE]);
  const navigatePath = navigateState.path;
  const navigateReplace = navigateState.replace;

  // TODO Rou 22/06/24 Не перенаправлять пользователя, если текущий url auth и перенавляет в auth?!
  useEffect(() => {
    if (!isNil(navigatePath)) {
      navigate(navigatePath, { replace: navigateReplace });
      dispatch(navigateSlice.actions.clear());
    }
  }, [ navigatePath ]);
}

export default useRedirect;
