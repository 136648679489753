import styled from 'styled-components/macro';

const CheckboxSwitchFieldContainer = styled.label`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 0;
  cursor: pointer;
`;
const CheckboxSwitchFieldTitle = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.14px;
  padding: 12px 0;
  width: 100%;
  & > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    user-select: none;
  }
`;
const CheckboxSwitchFieldSwitcher = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 42px;
  height: 24px;
  border-radius: 57px;
  background: ${({ theme }) => theme.palette.main[240]};
  margin-left: auto;
  & > span {
    position: absolute;
    left: 2px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: ${({ theme }) => theme.palette.white[10]};
  }
`;
const CheckboxSwitchFieldInput = styled.input`
  display: none;
  &:checked + ${CheckboxSwitchFieldSwitcher} {
    background: ${({ theme }) => theme.palette.orange[20]};
    & > span {
      left: unset;
      right: 2px;
    }
  }
`;

export {
  CheckboxSwitchFieldContainer,
  CheckboxSwitchFieldTitle,
  CheckboxSwitchFieldSwitcher,
  CheckboxSwitchFieldInput,
};
