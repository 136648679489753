import { createApi } from '@reduxjs/toolkit/query/react';
import StateName from 'helpers/stateNames';
import { RequestAPI } from 'helpers/requests';
import { axiosBaseQuery } from 'helpers/axios';
import { IUploadFileArgs } from 'components/services/upload/uploadTypes';
import { IUploadFileResponse } from 'types/response';

const uploadApi = createApi({
  reducerPath: StateName.UPLOAD_API,
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    file: build.mutation<IUploadFileResponse, IUploadFileArgs>({
      query(args) {
        return {
          url: RequestAPI.UPLOAD,
          method: 'POST',
          data: args,
        };
      },
    }),
  }),
});

export {
  uploadApi,
};
