import React from 'react';
import { ReactComponent as PendingIcon } from 'media/icons/document-load.svg';
import { ReactComponent as FinishedIcon } from 'media/icons/check-circle-filled.svg';
import moment from 'moment';
import Alt from 'helpers/alt';
import { exportApi } from 'components/services/export/exportService';
import { ExportStatus } from 'helpers/export';
import useInterval from 'hooks/core/useInterval';
import Date from 'components/common/date/Date';
import { IExportTooltipProps } from './ExportTooltipTypes';
import {
  ExportTooltipContainer,
  ExportTooltipIcon,
  ExportTooltipCaption,
  ExportTooltipCaptionTitle,
  ExportTooltipCaptionDescription,
  ExportTooltipCaptionProgress,
  ExportTooltipButton,
} from './ExportTooltipStyles';

function ExportTooltip(props: IExportTooltipProps) {
  const { isGlobal = false } = props;

  const { useLastQuery } = exportApi;
  const {
    data: lastExportData,
    refetch: lastExportRefetch,
  } = useLastQuery();

  const archiveFile = lastExportData?.archiveFile;
  const status = lastExportData?.status;
  const createdDate = lastExportData?.createdDate;
  const willFinished = lastExportData?.willFinished;
  const totalDurationMinutes = createdDate && willFinished
    ? moment(willFinished).diff(moment(createdDate), 'minutes')
    : 0;
  const currentDurationMinutes = createdDate
    ? moment().diff(moment(createdDate), 'minutes')
    : 0;
  const leftMinutes = totalDurationMinutes - currentDurationMinutes;
  const totalDurationSeconds = createdDate && willFinished
    ? moment(willFinished).diff(moment(createdDate), 'seconds')
    : 0;
  const currentDurationSeconds = createdDate
    ? moment().diff(moment(createdDate), 'seconds')
    : 0;
  const progress = Math.ceil((100 / totalDurationSeconds) * currentDurationSeconds);

  const isInProgress = status === ExportStatus.IN_PROGRESS;
  const isFinished = status === ExportStatus.FINISHED;
  const refetchDelay = isInProgress ? 5000 : null;

  useInterval(() => {
    lastExportRefetch();
  }, refetchDelay);

  const Icon = isFinished ? FinishedIcon : PendingIcon;
  const title = isFinished ? 'Файл готов' : 'Подготовка к экспорту';
  const description = isFinished
    ? <Date date={createdDate} prefix="От" />
    : `Осталось ~${leftMinutes ?? Alt.DATE_DIFF_MINUTES} мин.`;

  return (
    <ExportTooltipContainer isGlobal={isGlobal}>
      <ExportTooltipIcon isFinished={isFinished}>
        <Icon />
      </ExportTooltipIcon>
      <ExportTooltipCaption>
        <ExportTooltipCaptionTitle>{title}</ExportTooltipCaptionTitle>
        <ExportTooltipCaptionDescription>{description}</ExportTooltipCaptionDescription>
        {isFinished ? null : (
          <ExportTooltipCaptionProgress progress={progress} />
        )}
      </ExportTooltipCaption>
      {isFinished && archiveFile ? (
        <ExportTooltipButton
          href={archiveFile}
          target="_blank"
          download
        >
          Скачать
        </ExportTooltipButton>
      ) : null}
    </ExportTooltipContainer>
  );
}

export default ExportTooltip;
