import { FunctionComponent, PropsWithChildren, SVGProps } from 'react';

const ButtonThemeType = {
  MAIN_PRIMARY: 'main-primary',
  MAIN_SECONDARY: 'main-secondary',
  SECONDARY: 'secondary',
  MINOR_PRIMARY: 'minor-primary',
  MINOR_SECONDARY: 'minor-secondary',
} as const;
type IButtonThemeType = typeof ButtonThemeType[keyof typeof ButtonThemeType];
const ButtonSize = {
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small',
} as const;
type IButtonSize = typeof ButtonSize[keyof typeof ButtonSize];
type IButtonProps = PropsWithChildren<{
  type?: 'submit' | 'button' | 'reset'
  htmlFor?: string
  as?: 'button' | 'label'
  themeType?: IButtonThemeType
  size?: IButtonSize
  onClick?: () => void
  fullWidth?: boolean
  icon?: FunctionComponent<SVGProps<SVGSVGElement>> | null
  disabled?: boolean
  isLoading?: boolean
  className?: string
}>;
interface IButtonWrap {
  isLoading: boolean
  fullWidth: boolean
  themeType: IButtonThemeType
  size: IButtonSize
}

export {
  ButtonThemeType,
  ButtonSize,
};
export type {
  IButtonSize,
  IButtonThemeType,
  IButtonProps,
  IButtonWrap,
};
