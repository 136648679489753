import styled from 'styled-components/macro';

const ToastsWrap = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  flex-flow: column nowrap;
  width: 265px;
  z-index: 1000;
  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export {
  ToastsWrap,
};
