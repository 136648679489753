import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import StateName from 'helpers/stateNames';
import { INavigateState, INavigateRedirectPayload } from 'components/services/navigate/navigateTypes';

const initialState: INavigateState = {
  path: null,
  replace: false,
};

const navigateSlice = createSlice({
  name: StateName.NAVIGATE,
  initialState,
  reducers: {
    redirect: (state: INavigateState, action: PayloadAction<INavigateRedirectPayload>) => {
      const { path, replace } = action.payload;
      state.path = path;
      state.replace = replace;
    },
    clear: () => initialState,
  },
});

export {
  navigateSlice,
};
