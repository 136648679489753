import { useLocation, useNavigate } from 'react-router-dom';

interface IUseBack {
  goBack: () => void
}

// Возврат на прошлую страницу
function useBack(): IUseBack {
  const navigate = useNavigate();
  const location = useLocation();

  const goBack = () => {
    if (location?.key === 'default') {
      navigate('..', { relative: 'route', replace: true });
    } else {
      navigate(-1);
    }
  };

  return { goBack };
}

export default useBack;
