import React from 'react';
import {
  IStatusBoolProps,
  StatusBoolState,
  StatusBoolSubPosition,
  StatusBoolType,
} from './StatusBoolTypes';
import {
  StatusBoolLabel,
  StatusBoolMark,
  StatusBoolWrap,
  StatusBoolDropdown,
  StatusBoolDropdownItem,
  StatusBoolDropdownItemBool,
} from './StatusBoolStyles';

const getLabel = (state: IStatusBoolProps['state']) => {
  switch (state) {
    case StatusBoolState.ACTIVE: return 'On';
    case StatusBoolState.INACTIVE: return 'Off';
    default: return 'n/a';
  }
};

function StatusBool(props: IStatusBoolProps) {
  const { state, sub, type = StatusBoolType.MAIN } = props;

  const subList = sub?.list;
  const subPosition = sub?.position || StatusBoolSubPosition.TOP;
  const isMain = type === StatusBoolType.MAIN;

  const dropdownEl = Array.isArray(subList) && subList.length > 0 ? (
    <StatusBoolDropdown position={subPosition}>
      {subList?.map((item, index) => {
        const { label: itemLabel, state: itemState } = item;

        return (
          <StatusBoolDropdownItem key={index}>
            <span>{itemLabel}</span>
            <StatusBoolDropdownItemBool state={itemState}>
              {getLabel(itemState)}
            </StatusBoolDropdownItemBool>
          </StatusBoolDropdownItem>
        );
      })}
    </StatusBoolDropdown>
  ) : null;

  return (
    <StatusBoolWrap isMain={isMain} state={state}>
      <StatusBoolMark isMain={isMain} state={state} />
      <StatusBoolLabel state={state}>
        {getLabel(state)}
      </StatusBoolLabel>
      {dropdownEl}
    </StatusBoolWrap>
  );
}

export default StatusBool;
