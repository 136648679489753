import React, {
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useState,
} from 'react';
import { ReactComponent as SearchIcon } from 'media/icons/search.svg';
import useQuery from 'hooks/router/useQuery';
import useDebounce from 'hooks/core/useDebounce';
import TextField from 'components/common/fields/text/TextField';
import { TextFieldType } from 'components/common/fields/text/TextFieldTypes';
import { isNil } from 'ramda';
import SearchQuery from 'helpers/searchQuery';
import { ISearchFieldProps } from './SearchFieldTypes';

function SearchField(props: ISearchFieldProps) {
  const {
    searchQueryName,
    pageQueryName = SearchQuery.PAGE,
    isPagination = true,
    ...rest
  } = props;

  const { getQueryByKey, setQueryByKey } = useQuery();

  const defaultSearchValue = getQueryByKey(searchQueryName);

  const [ searchValue, setSearchValue ] = useState<string | null>(null);
  const debouncedValue = useDebounce(searchValue, 1000);

  const handleSetSearchQuery = (value: string | null) => {
    if (!isNil(value)) {
      setQueryByKey(
        searchQueryName,
        value,
        {
          additionalQueries: isPagination ? { [pageQueryName]: 1 } : undefined,
          replace: true,
        },
      );
    }
  };

  useEffect(() => {
    handleSetSearchQuery(debouncedValue);
  }, [ debouncedValue ]);

  const handleChange = (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setSearchValue(ev.target.value);
  const handleKeyDown = (ev: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (ev.key.toLowerCase() === 'enter') handleSetSearchQuery(searchValue);
  };

  return (
    <TextField
      prefix={SearchIcon}
      placeholder="Поиск..."
      inputMode="text"
      defaultValue={defaultSearchValue}
      type={TextFieldType.TEXT}
      name={searchQueryName}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      {...rest}
    />
  );
}

export default SearchField;
