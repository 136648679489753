import styled from 'styled-components/macro';
import hexToRGBA from 'helpers/hexToRGBA';
import { emergenceFromOpacityLinerKeyframes } from 'helpers/keyframes';
import { IMaskSC } from './MaskTypes';

const Mask = styled.div<IMaskSC>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ theme, opacity = 0.7 }) => hexToRGBA(theme.palette.black[20], opacity)};
  z-index: 100;
  animation: ${emergenceFromOpacityLinerKeyframes} .3s linear;
`;

export default Mask;
