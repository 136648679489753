import {
  TypedUseSelectorHook,
  useSelector as useS,
} from 'react-redux';
import { store } from 'helpers/redux';

type IRootState = ReturnType<typeof store.getState>;

const useSelector: TypedUseSelectorHook<IRootState> = useS;

export type { IRootState };

export default useSelector;
