import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import StateName from 'helpers/stateNames';
import { IToastsAddPayload, IToastsItem, IToastsState } from 'components/services/toasts/toastsTypes';

const initialState: IToastsState = [];

const toastsSlice = createSlice({
  name: StateName.TOASTS,
  initialState,
  reducers: {
    add: (state: IToastsState, action: PayloadAction<IToastsAddPayload>) => {
      const { title, type, description } = action.payload;

      const errorLength = state.length || 0;
      const lastToastsId = state[errorLength - 1]?.id || 0;
      const id: IToastsItem['id'] = lastToastsId + 1;

      state.push({
        title,
        description,
        type,
        id,
      });
    },
    removeById: (state: IToastsState, action: PayloadAction<IToastsItem['id']>) => {
      const rejectedId = action.payload;

      return state.filter(({ id }) => id !== rejectedId);
    },
  },
});

export {
  toastsSlice,
};
