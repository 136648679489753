import styled, { css } from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import Count from 'components/common/count/Count';
import { emergenceFromOpacityKeyframes } from 'helpers/keyframes';
import hexToRGBA from 'helpers/hexToRGBA';
import {
  ISidebarMenuItemWrap,
  ISidebarMenuItemArrow,
  ISidebarMenuItemCount,
  ISidebarMenuItemLabel,
} from './SidebarMenuItemTypes';

const SidebarMenuItemWrap = styled(NavLink)<ISidebarMenuItemWrap>`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  flex-shrink: 0;
  align-items: center;
  height: 40px;
  font-size: 15px;
  font-weight: ${({ state }) => (state?.isChildren ? '500' : '600')};
  padding: ${({ state }) => (state?.isShort ? '10px' : '10px 33px 10px 46px')};
  margin: 0 0 2px;
  border-radius: 10px;
  background: ${({ theme, state }) => state?.isActive && theme.palette.main[160]};
  color: ${({ theme }) => theme.palette.main[360]};
  transition: height .2s;
  cursor: pointer;
  & > svg {
    position: absolute;
    left: 13px;
    top: 50%;
    transform: translateY(-50%);
    width: 22px;
    height: 22px;
    color: inherit;
  }
  &:hover {
    background: ${({ theme, state }) => !state?.isActive && hexToRGBA(theme.palette.main[160])};
  }
  ${({ state }) => state?.isChildren && css`
    &:before {
      position: absolute;
      left: 22px;
      top: 50%;
      transform: translateY(-50%);
      content: "";
      width: 6px;
      height: 6px;
      border-radius: 100%;
      border: ${({ theme }) => `1px solid ${theme.palette.main[360]}`}};
    }
  `}
`;
const SidebarMenuItemArrow = styled.b<ISidebarMenuItemArrow>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 35px;
  color: inherit;
  & > svg {
    transform: ${({ isOpen }) => isOpen && 'rotate(180deg)'};
  }
`;
const SidebarMenuItemLabel = styled.span<ISidebarMenuItemLabel>`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: ${({ hasChildren }) => (hasChildren ? '100px' : '125px')};
`;
const SidebarMenuItemCount = styled(Count)<ISidebarMenuItemCount>`
  position: absolute;
  top: 50%;
  right: ${({ hasChildren }) => (hasChildren ? '55px' : '25px')};
  transform: translateY(-50%);
  transition: opacity .2s;
  animation: ${emergenceFromOpacityKeyframes} .2s linear;
`;
const SidebarMenuItemChildren = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 0 0 25px;
  margin: 0 0 13px;
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.main[140]}`};
`;

export {
  SidebarMenuItemWrap,
  SidebarMenuItemArrow,
  SidebarMenuItemLabel,
  SidebarMenuItemCount,
  SidebarMenuItemChildren,
};
