import { createApi } from '@reduxjs/toolkit/query/react';
import StateName from 'helpers/stateNames';
import { axiosBaseQuery } from 'helpers/axios';
import { dataToSnakeCase } from 'helpers/register';
import { IPagination } from 'types/response';
import { IFiltersOptionsArgs } from 'components/services/filters/filtersTypes';

const filtersApi = createApi({
  reducerPath: StateName.FILTERS_API,
  baseQuery: axiosBaseQuery(),
  // Время жизни данных в кеше, когда они не используются в сек. Не мало ли 0?!
  keepUnusedDataFor: 0,
  endpoints: (build) => ({
    options: build.query<IPagination<Object>, IFiltersOptionsArgs>({
      query({ api, params }) {
        return {
          url: api,
          params: dataToSnakeCase(params),
        };
      },
    }),
  }),
});

export {
  filtersApi,
};
