import { matchRoutes, RouteMatch, useLocation } from 'react-router-dom';
import routes, { IRoute } from 'helpers/routes';

interface IRouteMatch extends RouteMatch {
  route: IRoute
}
interface IUseMathPath {
  matchRoute: IRouteMatch | undefined
}

const useMatchPath = (): IUseMathPath => {
  const location = useLocation();

  const matchRoute = matchRoutes(routes, location)?.find(({ pathname }) => pathname === location.pathname);

  return {
    matchRoute,
  };
};

export default useMatchPath;
