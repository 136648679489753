import React from 'react';
import { ICountProps } from './CountTypes';
import { CountWrap } from './CountStyles';

function Count(props: ICountProps) {
  const {
    maxCount = 100000,
    count = 0,
    className,
  } = props;

  const countText = count >= maxCount ? `${maxCount - 1}+` : count;

  if (count > 0) {
    return (
      <CountWrap
        className={className}
        data-tooltip={count}
      >
        {countText}
      </CountWrap>
    );
  }

  return null;
}

export default Count;
