import React, { ChangeEvent, useState } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { toPairs } from 'ramda';
import { ReactComponent as ToggleIcon } from 'media/icons/chevron-bottom.svg';
import CheckboxSwitchField from 'components/common/fields/checkboxSwitch/CheckboxSwitchField';
import Checkbox from 'components/common/fields/checkbox/Checkbox';
import useDeepEffect from 'hooks/core/useDeepEffect';
import { IExportConfigFieldsProps } from './ExportConfigFieldsTypes';
import {
  ExportConfigFieldsContainer,
  ExportConfigFieldsTop,
  ExportConfigFieldsToggle,
  ExportConfigFieldsBottom,
} from './ExportConfigFieldsStyles';

const exportConfigName = 'config';
const exportConfigAliasAll = 'all';
const genName = (alias: string, sub: string) => `${exportConfigName}.${alias}.${sub}`;

function ExportConfigFields(props: IExportConfigFieldsProps) {
  const { list } = props;
  const subName = list[0];
  const { label, fields } = list[1];
  const fieldsArr = toPairs(fields);

  const [ open, setOpen ] = useState<boolean>(false);

  const handleToggle = () => setOpen(!open);

  const { change: formChange } = useForm();
  const { values } = useFormState();

  const currentValues = values?.[exportConfigName]?.[subName] || {};

  useDeepEffect(() => {
    const hasChecked = fieldsArr?.some(([ alias ]) => currentValues?.[alias]);
    formChange(genName(subName, exportConfigAliasAll), hasChecked);
  }, [ fieldsArr, currentValues ]);

  const onChangeAll = (ev: ChangeEvent<HTMLInputElement>) => {
    const isChecked = ev?.target?.checked;
    fieldsArr?.forEach(([ itemAlias ]) => {
      formChange(genName(subName, itemAlias), isChecked);
    });
  };

  return (
    <ExportConfigFieldsContainer>
      <ExportConfigFieldsTop>
        <Field
          component={CheckboxSwitchField}
          name={genName(subName, exportConfigAliasAll)}
          onChange={onChangeAll}
          label={label}
          type="checkbox"
        />
        <ExportConfigFieldsToggle isOpen={open} onClick={handleToggle}>
          <span>
            <ToggleIcon />
          </span>
        </ExportConfigFieldsToggle>
      </ExportConfigFieldsTop>
      {open ? (
        <ExportConfigFieldsBottom>
          {fieldsArr?.map((item) => {
            const itemLabel = item?.[1];
            const itemAlias = item?.[0];

            return (
              <Field
                component={Checkbox}
                label={itemLabel}
                name={genName(subName, itemAlias)}
                type="checkbox"
                key={itemAlias}
              />
            );
          })}
        </ExportConfigFieldsBottom>
      ) : null}
    </ExportConfigFieldsContainer>
  );
}

export {
  exportConfigName,
  exportConfigAliasAll,
};

export default ExportConfigFields;
