import React from 'react';
import { useNavigate } from 'react-router-dom';
import { vsprintf } from 'sprintf-js';
import { companiesApi } from 'components/services/companies/companiesService';
import useQuery from 'hooks/router/useQuery';
import SearchQuery from 'helpers/searchQuery';
import Loader from 'components/common/loader/Loader';
import { LoaderSize } from 'components/common/loader/LoaderTypes';
import NoData from 'components/common/noData/NoData';
import Alt from 'helpers/alt';
import SearchResultsItem from 'components/features/search/results/item/SearchResultsItem';
import RoutePath from 'helpers/routePath';
import { getLabelByAlias, getStateByAlias } from 'helpers/statuses';
import { companyStatuses } from 'helpers/companies';
import StatusLabel from 'components/common/status/label/StatusLabel';
import useSync from 'hooks/etc/useSync';
import StatusBool from 'components/common/status/bool/StatusBool';
import { StatusBoolSubPosition, StatusBoolType } from 'components/common/status/bool/StatusBoolTypes';
import Date from 'components/common/date/Date';
import Label from 'components/common/label/Label';
import {
  SearchResultsCategoryCompaniesItem,
  SearchResultsCategoryCompaniesItemTile,
  SearchResultsCategoryCompaniesItemStatus,
  SearchResultsCategoryCompaniesItemEndDate,
  SearchResultsCategoryCompaniesItemSync,
} from './SearchResultsCategoryCompaniesStyles';

function SearchResultsCategoryCompanies() {
  const navigate = useNavigate();

  const { syncByPlatformResults } = useSync();

  const { getQueryByKey } = useQuery();

  const searchQueryName = SearchQuery.GLOBAL_SEARCH;

  const searchQuery = getQueryByKey(searchQueryName);

  const { useSearchQuery } = companiesApi;
  const { data, isLoading, isFetching } = useSearchQuery({ search: searchQuery });

  const results = data?.results || [];
  const loading = isLoading || isFetching;

  if (loading) {
    return (
      <SearchResultsItem>
        <Loader size={LoaderSize.MEDIUM} />
      </SearchResultsItem>
    );
  }
  if (Array.isArray(results) && results.length > 0) {
    return (
      <>
        {results.map((item, index) => {
          const {
            id,
            name,
            gcp,
            status,
            platformResults,
            endDate,
            isDeleted,
            photo,
          } = item;

          const label = isDeleted ? (
            <Label prefix="Удалённая">
              {name}
            </Label>
          ) : name;

          const statusLabel = getLabelByAlias({
            statuses: companyStatuses,
            alias: status,
            isSingle: true,
          });

          const statusState = getStateByAlias({
            statuses: companyStatuses,
            alias: status,
          });

          const statusEl = (
            <StatusLabel
              label={statusLabel}
              state={statusState}
            />
          );

          const { list: syncList, state: syncState } = syncByPlatformResults({ platformResults });

          const subPosition = index >= 2 ? StatusBoolSubPosition.TOP : StatusBoolSubPosition.BOTTOM;

          const syncEl = (
            <StatusBool
              state={syncState}
              sub={{ list: syncList, position: subPosition }}
              type={StatusBoolType.SECONDARY}
            />
          );

          const handleClick = () => navigate(vsprintf(RoutePath.COMPANIES_DETAIL, [ id ]));

          return (
            <SearchResultsCategoryCompaniesItem
              onClick={handleClick}
              key={id}
            >
              <SearchResultsCategoryCompaniesItemTile
                name={label}
                description={statusEl}
                src={photo?.file}
                id={id}
              />
              <SearchResultsCategoryCompaniesItemStatus
                name={gcp || Alt.DATA_MASCULINE}
                description="GCP"
                withAvatar={false}
              />
              <SearchResultsCategoryCompaniesItemEndDate
                name={<Date date={endDate} />}
                description="ДАТА ОКОНЧАНИЯ"
                withAvatar={false}
              />
              <SearchResultsCategoryCompaniesItemSync
                name={syncEl}
                description="СИНХРОНИЗАЦИЯ"
                withAvatar={false}
              />
            </SearchResultsCategoryCompaniesItem>
          );
        })}
      </>
    );
  }
  return (
    <SearchResultsItem>
      <NoData />
    </SearchResultsItem>
  );
}

export default SearchResultsCategoryCompanies;
