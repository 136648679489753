import axiosCore, { AxiosRequestConfig, AxiosError } from 'axios';
import { responseToCamelCase } from 'helpers/register';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import {
  baseUrl,
  RequestHeader,
  RequestContentType,
  RequestTransformType,
  IRequestTransformType,
  IRequestContentType,
} from 'helpers/requests';

interface IAxiosProps {
  contentType?: IRequestContentType
  transformType?: IRequestTransformType
}
interface IAxiosBaseQueryArgs {
  transformType?: IAxiosProps['transformType']
}
type IAxiosBaseQuery = BaseQueryFn<AxiosRequestConfig>;

const axios = (props?: IAxiosProps) => {
  const transformType = props?.transformType || RequestTransformType.CAMEL_CASE;
  const contentType = props?.contentType || RequestContentType.JSON;

  axiosCore.defaults.baseURL = baseUrl;
  axiosCore.defaults.timeout = 100000;
  axiosCore.defaults.withCredentials = true;

  axiosCore.defaults.headers.common[RequestHeader.ACCEPT] = contentType;

  if (transformType === RequestTransformType.CAMEL_CASE) {
    axiosCore.defaults.transformResponse = [ responseToCamelCase ];
  } else {
    axiosCore.defaults.transformResponse = undefined;
  }

  return axiosCore;
};

const axiosBaseQuery = (args?: IAxiosBaseQueryArgs): IAxiosBaseQuery => async (config: AxiosRequestConfig) => {
  try {
    const result = await axios(args).request(config);
    return {
      data: result.data,
    };
  } catch (axiosError) {
    const er = axiosError as AxiosError;
    return {
      error: {
        status: er.response?.status,
        data: er.response?.data,
      },
    };
  }
};

export {
  axios,
  axiosBaseQuery,
};
