const StatusBoolState = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  NOT_AVAILABLE: 'not-available',
} as const;
type IStatusBoolState = typeof StatusBoolState[keyof typeof StatusBoolState];
const StatusBoolSubPosition = {
  TOP: 'top',
  BOTTOM: 'bottom',
} as const;
type IStatusBoolSubPosition = typeof StatusBoolSubPosition[keyof typeof StatusBoolSubPosition];
const StatusBoolType = {
  MAIN: 'main',
  SECONDARY: 'secondary',
} as const;
type IStatusBoolType = typeof StatusBoolType[keyof typeof StatusBoolType];
interface IStatusBoolSubItem {
  label: string
  state: IStatusBoolProps['state']
}
interface IStatusBoolSub {
  list: IStatusBoolSubItem[]
  position?: IStatusBoolSubPosition
}
interface IStatusBoolProps {
  state: IStatusBoolState
  sub?: IStatusBoolSub
  type?: IStatusBoolType
}
interface IStatusBoolWrap {
  state: IStatusBoolProps['state']
  isMain: boolean
}
interface IStatusBoolMark {
  state: IStatusBoolProps['state']
  isMain: boolean
}
interface IStatusBoolLabel {
  state: IStatusBoolProps['state']
}
interface IStatusBoolDropdownItemBoolSC {
  state: IStatusBoolProps['state']
}
interface IStatusBoolDropdownSC {
  position: IStatusBoolSubPosition
}

export {
  StatusBoolState,
  StatusBoolSubPosition,
  StatusBoolType,
};
export type {
  IStatusBoolState,
  IStatusBoolSubItem,
  IStatusBoolSubPosition,
  IStatusBoolProps,
  IStatusBoolWrap,
  IStatusBoolMark,
  IStatusBoolLabel,
  IStatusBoolDropdownSC,
  IStatusBoolDropdownItemBoolSC,
};
