import {
  ChangeEvent,
  FunctionComponent,
  KeyboardEvent,
  SVGProps,
} from 'react';
import { FieldRenderProps } from 'react-final-form';

const TextFieldType = {
  TEXT: 'text',
  NUMBER: 'number',
  AREA: 'area',
  PASSWORD: 'password',
  EMAIL: 'email',
  POST_CODE: 'postCode',
  BANK_REQUISITES: 'bankRequisites',
  TIN: 'tin',
  COMPANY_NAME: 'companyName',
} as const;
type ITextFieldType = typeof TextFieldType[keyof typeof TextFieldType];
const TextTheme = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  AREA: 'area',
} as const;
type ITextTheme = typeof TextTheme[keyof typeof TextTheme];
type ITextFieldProps = Partial<FieldRenderProps<string | number, HTMLInputElement | HTMLTextAreaElement>> & {
  label?: string
  type?: ITextFieldType
  defaultValue?: HTMLInputElement['defaultValue'] | HTMLTextAreaElement['defaultValue']
  prefix?: FunctionComponent<SVGProps<SVGSVGElement>>
  required?: HTMLInputElement['required'] | HTMLTextAreaElement['required']
  placeholder?: string
  className?: string
  caption?: string
  onChange?: (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onKeyDown?: (ev: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  maxLength?: number
  unmountClear?: boolean
  pattern?: HTMLInputElement['pattern']
  textTheme?: ITextTheme
};
interface ITextFieldInput {
  hasPrefix?: boolean
  hasLabel?: boolean
  isPrimary?: boolean
  textTheme: ITextTheme
}

export {
  TextTheme,
  TextFieldType,
};
export type {
  ITextTheme,
  ITextFieldProps,
  ITextFieldInput,
};
