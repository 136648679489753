const Cookie = {
  VERIFY: 'verify',
} as const;
type ICookie = typeof Cookie[keyof typeof Cookie];

export type {
  ICookie,
};
export {
  Cookie,
};
