import React from 'react';
import Loader from 'components/common/loader/Loader';
import { LoaderSize } from 'components/common/loader/LoaderTypes';
import { ButtonIcon, ButtonWrap } from './ButtonStyles';
import { ButtonSize, ButtonThemeType, IButtonProps } from './ButtonTypes';

function Button(props: IButtonProps) {
  const {
    type = 'submit',
    themeType = ButtonThemeType.MAIN_PRIMARY,
    size = ButtonSize.LARGE,
    fullWidth = false,
    icon: Icon,
    children,
    onClick,
    disabled = false,
    isLoading = false,
    className,
    htmlFor,
    as = 'button',
  } = props;

  const isLabel = as === 'label';

  const isDisabled = isLoading || disabled;

  // Массив тем, в которых loader isInvert
  const isInvertTheme = !![ ButtonThemeType.MAIN_PRIMARY ].find((th) => th === themeType);

  const loaderSize = (() => {
    switch (size) {
      case ButtonSize.LARGE: return LoaderSize.MEDIUM;
      default: return LoaderSize.SMALL;
    }
  })();

  const iconEl = Icon ? (
    <ButtonIcon>
      {isLoading
        ? (
          <Loader
            isInvert={isInvertTheme}
            size={loaderSize}
          />
        ) : <Icon />}
    </ButtonIcon>
  ) : null;

  return (
    <ButtonWrap
      onClick={onClick}
      type={!isLabel ? type : undefined}
      themeType={themeType}
      size={size}
      fullWidth={fullWidth}
      disabled={isDisabled}
      isLoading={isLoading}
      as={isDisabled ? 'button' : as}
      htmlFor={(isLabel && !isDisabled) ? htmlFor : undefined}
      className={className}
    >
      {iconEl}
      <span>{children}</span>
    </ButtonWrap>
  );
}

export default Button;
