import styled from 'styled-components/macro';
import SearchResultsItem from 'components/features/search/results/item/SearchResultsItem';
import NameTile from 'components/common/nameTile/NameTile';
import { NameTileCaptionText } from 'components/common/nameTile/NameTileStyles';

const SearchResultsCategoryProductsItem = styled(SearchResultsItem)`
  gap: 20px;
`;
const SearchResultsCategoryProductsItemTileName = styled(NameTile)`
  width: calc(40% - 60px);
  margin-right: auto;
`;
const SearchResultsCategoryProductsItemTileGTIN = styled(NameTile)`
  width: 20%;
`;
const SearchResultsCategoryProductsItemTileBrickCode = styled(NameTile)`
  width: 20%;
`;
// TODO Вынести в какой-то отдельный элемент код с ${NameTileCaptionText}?!
const SearchResultsCategoryProductsItemTileSync = styled(NameTile)`
  width: 20%;
  ${NameTileCaptionText} {
    overflow: unset;
  }
`;

export {
  SearchResultsCategoryProductsItem,
  SearchResultsCategoryProductsItemTileName,
  SearchResultsCategoryProductsItemTileGTIN,
  SearchResultsCategoryProductsItemTileBrickCode,
  SearchResultsCategoryProductsItemTileSync,
};
