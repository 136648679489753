import Alt from 'helpers/alt';

type IStatusLabelString = string;

interface IStatusLabel {
  single: IStatusLabelString
  multi: IStatusLabelString
}
interface IStatus<A = string> {
  label: IStatusLabel
  alias: A
  state: IStatusState
}
interface IGetStatusByAliasArgs {
  statuses: IStatus[],
  alias: IStatus['alias'],
}
interface IGetLabelByAliasArgs {
  statuses: IStatus[],
  alias: IStatus['alias'],
  isSingle?: boolean,
}

const StatusState = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  PARTIALLY_ACTIVE: 'partially-active',
  PENDING: 'pending',
} as const;
type IStatusState = typeof StatusState[keyof typeof StatusState];

const getStatusByAlis = (args: IGetStatusByAliasArgs): IStatus | undefined => {
  const {
    statuses,
    alias,
  } = args;

  return statuses?.find(
    ({ alias: cAlias }) => cAlias === alias,
  );
};

const getLabelByAlias = (args: IGetLabelByAliasArgs): IStatusLabelString => {
  const {
    statuses,
    alias,
    isSingle = true,
  } = args;

  const currentStatus = getStatusByAlis({
    statuses,
    alias,
  });

  const labelSingle = currentStatus?.label.single;
  const labelMulti = currentStatus?.label.multi;

  return (isSingle ? labelSingle : labelMulti) || Alt.DATA_MASCULINE;
};

const getStateByAlias = (args: IGetStatusByAliasArgs): IStatus['state'] => {
  const {
    statuses,
    alias,
  } = args;

  const currentStatus = getStatusByAlis({
    statuses,
    alias,
  });

  return currentStatus?.state || StatusState.INACTIVE;
};

export type {
  IStatusState,
  IStatusLabelString,
  IStatusLabel,
  IStatus,
};

export {
  StatusState,
  getLabelByAlias,
  getStateByAlias,
};
