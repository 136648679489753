import styled from 'styled-components/macro';

const ModalWrap = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-flow: column nowrap;
  flex-shrink: 0;
  padding: 38px 0 25px;
  width: ${({ theme }) => theme.width.modal};
  min-height: 278px;
  max-height: calc(100vh - 36px);
  background: ${({ theme }) => theme.palette.main[70]};
  border-radius: 10px;
  z-index: 1000;
`;
const ModalTitle = styled.h2`
  font-weight: 600;
  font-size: 26px;
  line-height: 1.25;
  letter-spacing: -0.01em;
  margin-bottom: 30px;
  padding: 0 70px 0 25px;
`;
const ModalButtons = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: stretch;
  margin-top: auto;
  padding: 0 25px;
  & > button {
    flex-grow: 1;
    width: calc(50% - 7px);
  }
  & > *:not(:last-child) {
    margin-right: 14px;
  }
`;

export {
  ModalWrap,
  ModalTitle,
  ModalButtons,
};
