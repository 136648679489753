import { PropsWithChildren } from 'react';

const TableBodyRowSize = {
  MEDIUM: 'medium',
  SMALL: 'small',
} as const;
type ITableBodyRowSize = typeof TableBodyRowSize[keyof typeof TableBodyRowSize];
type ITableBodyProps = PropsWithChildren<{
  size?: ITableBodyRowSize
  isLoading?: boolean
}>;
interface ITableBodyWrapSC {
  size: ITableBodyRowSize
}

export {
  TableBodyRowSize,
};
export type {
  ITableBodyProps,
  ITableBodyWrapSC,
};
