import React, { ChangeEvent } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { companyStatusAll, companyStatuses } from 'helpers/companies';
import CheckboxTileField from 'components/common/fields/checkboxTile/CheckboxTileField';
import useDeepEffect from 'hooks/core/useDeepEffect';
import { ExportCompaniesTypesFieldsContainer } from './ExportCompaniesTypesFieldsStyles';

const exportCompaniesTypesName = 'status';
const exportCompaniesTypesAliasAll = 'all';
const genName = (alias: string) => `${exportCompaniesTypesName}.${alias}`;

function ExportCompaniesTypesFields() {
  const { change: formChange } = useForm();
  const { values } = useFormState();

  const currentValues = values?.[exportCompaniesTypesName] || {};

  useDeepEffect(() => {
    const hasNotChecked = companyStatuses?.some(({ alias }) => !currentValues?.[alias]);
    formChange(genName(exportCompaniesTypesAliasAll), !hasNotChecked);
  }, [ companyStatuses, currentValues ]);

  const onChangeAll = (ev: ChangeEvent<HTMLInputElement>) => {
    const isChecked = ev?.target?.checked;
    companyStatuses?.forEach(({ alias }) => {
      formChange(genName(alias), isChecked);
    });
  };

  return (
    <ExportCompaniesTypesFieldsContainer>
      <Field
        component={CheckboxTileField}
        label={companyStatusAll.label.multi}
        name={genName(exportCompaniesTypesAliasAll)}
        type="checkbox"
        onChange={onChangeAll}
      />
      {companyStatuses?.map((item) => {
        const { label: { multi: label }, alias } = item;

        return (
          <Field
            component={CheckboxTileField}
            label={label}
            name={genName(alias)}
            type="checkbox"
            key={alias}
          />
        );
      })}
    </ExportCompaniesTypesFieldsContainer>
  );
}

export {
  exportCompaniesTypesName,
  exportCompaniesTypesAliasAll,
};

export default ExportCompaniesTypesFields;
