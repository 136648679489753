import React from 'react';
import { ICheckboxSwitchFieldProps } from './CheckboxSwitchFieldTypes';
import {
  CheckboxSwitchFieldContainer,
  CheckboxSwitchFieldTitle,
  CheckboxSwitchFieldSwitcher,
  CheckboxSwitchFieldInput,
} from './CheckboxSwitchFieldStyles';

function CheckboxSwitchField(props: ICheckboxSwitchFieldProps) {
  const { label = '?', input, onChange } = props;

  return (
    <CheckboxSwitchFieldContainer>
      <CheckboxSwitchFieldTitle data-tooltip={label}>
        <span>{label}</span>
      </CheckboxSwitchFieldTitle>
      <CheckboxSwitchFieldInput
        {...input}
        type="checkbox"
        onChange={(ev) => {
          if (onChange) onChange(ev);
          input?.onChange(ev);
        }}
      />
      <CheckboxSwitchFieldSwitcher>
        <span />
      </CheckboxSwitchFieldSwitcher>
    </CheckboxSwitchFieldContainer>
  );
}

export default CheckboxSwitchField;
