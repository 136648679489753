import { createSlice } from '@reduxjs/toolkit';
import StateName from 'helpers/stateNames';
import { storage } from 'helpers/storage';
import {
  MenuKey,
  IMenuState,
  IMenuTogglePayload,
  IMenuKey,
} from 'components/services/menu/menuTypes';

const getArray = (arr: string[] | string | null) => (Array.isArray(arr) ? arr : []);
const getStorageByMenuKey = (menuKey: IMenuKey): string => `menu__${menuKey}`;

const initialState: IMenuState = {
  [MenuKey.COMPANIES]: getArray(storage(getStorageByMenuKey(MenuKey.COMPANIES)).getValue()),
  [MenuKey.CONTRACTS]: getArray(storage(getStorageByMenuKey(MenuKey.CONTRACTS)).getValue()),
};

const menuSlice = createSlice({
  name: StateName.MENU,
  initialState,
  reducers: {
    toggle: (state: IMenuState, action: IMenuTogglePayload) => {
      const { alias, menuKey } = action.payload;

      const hasInCurrent = state[menuKey].find((fAlias) => fAlias === alias);

      const newAliases = () => {
        if (hasInCurrent) {
          return state[menuKey].filter((fAlias) => fAlias !== alias);
        }
        return [
          ...state[menuKey],
          alias,
        ];
      };

      storage(getStorageByMenuKey(menuKey)).setValue(newAliases());

      state[menuKey] = newAliases();
    },
  },
});

export {
  MenuKey,
  menuSlice,
};
