import {
  combineReducers,
  configureStore,
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI,
} from '@reduxjs/toolkit';
import { GetDefaultMiddleware } from '@reduxjs/toolkit/src/getDefaultMiddleware';
import RoutePath from 'helpers/routePath';
import { RequestStatus } from 'helpers/requests';
import { errorsSlice } from 'components/services/errors/errorsService';
import { authSlice, authApi } from 'components/services/auth/authService';
import { themesSlice } from 'components/services/themes/themesService';
import { modalSlice } from 'components/services/modal/modalService';
import { menuSlice } from 'components/services/menu/menuService';
import { searchSlice } from 'components/services/search/searchService';
import { exportSlice, exportApi } from 'components/services/export/exportService';
import { toastsSlice } from 'components/services/toasts/toastsService';
import { navigateSlice } from 'components/services/navigate/navigateService';
import { uploadApi } from 'components/services/upload/uploadService';
import { filtersApi } from 'components/services/filters/filtersService';
import { profileApi } from 'components/services/profile/profileService';
import { messagesApi } from 'components/services/messages/messagesService';
import { companiesApi } from 'components/services/companies/companiesService';
import { contractsApi } from 'components/services/contracts/contractsService';
import { productsApi } from 'components/services/products/productsService';
import { settingsApi } from 'components/services/settings/settingsService';
import { statisticsApi } from 'components/services/statistics/statisticsService';

const errorsMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (action: any) => {
  const { dispatch } = api;

  if (isRejectedWithValue(action)) {
    const { payload: { status, data } } = action;

    if (status === RequestStatus.NOT_AUTH) {
      dispatch(authSlice.actions.logout());
    } else if (status === RequestStatus.NO_PERMISSION) {
      dispatch(navigateSlice.actions.redirect({
        path: RoutePath.ERROR_NO_PERMISSION,
        replace: true,
      }));
    } else if (status === RequestStatus.NOT_FOUND) {
      dispatch(navigateSlice.actions.redirect({
        path: RoutePath.ERROR_NOT_FOUND,
        replace: true,
      }));
    } else if (status >= RequestStatus.SERVER) {
      dispatch(navigateSlice.actions.redirect({
        path: RoutePath.ERROR_SERVER,
        replace: true,
      }));
    }
    dispatch(errorsSlice.actions.add({ status, data }));
  }

  return next(action);
};

const reducers = combineReducers({
  [errorsSlice.name]: errorsSlice.reducer,
  [authSlice.name]: authSlice.reducer,
  [themesSlice.name]: themesSlice.reducer,
  [modalSlice.name]: modalSlice.reducer,
  [menuSlice.name]: menuSlice.reducer,
  [searchSlice.name]: searchSlice.reducer,
  [exportSlice.name]: exportSlice.reducer,
  [toastsSlice.name]: toastsSlice.reducer,
  [navigateSlice.name]: navigateSlice.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [uploadApi.reducerPath]: uploadApi.reducer,
  [filtersApi.reducerPath]: filtersApi.reducer,
  [profileApi.reducerPath]: profileApi.reducer,
  [messagesApi.reducerPath]: messagesApi.reducer,
  [companiesApi.reducerPath]: companiesApi.reducer,
  [contractsApi.reducerPath]: contractsApi.reducer,
  [productsApi.reducerPath]: productsApi.reducer,
  [exportApi.reducerPath]: exportApi.reducer,
  [settingsApi.reducerPath]: settingsApi.reducer,
  [statisticsApi.reducerPath]: statisticsApi.reducer,
});

const middlewares = (getDefaultMiddleware: GetDefaultMiddleware) => getDefaultMiddleware({
  serializableCheck: false,
}).concat([
  errorsMiddleware,
  authApi.middleware,
  uploadApi.middleware,
  filtersApi.middleware,
  profileApi.middleware,
  messagesApi.middleware,
  companiesApi.middleware,
  contractsApi.middleware,
  productsApi.middleware,
  exportApi.middleware,
  settingsApi.middleware,
  statisticsApi.middleware,
]);

const store = configureStore({
  reducer: reducers,
  middleware: middlewares,
});

export {
  errorsMiddleware,
  store,
};
