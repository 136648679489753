import React from 'react';
import { ICheckboxTileFieldProps } from './CheckboxTileFieldTypes';
import {
  CheckboxTileFieldContainer,
  CheckboxTileFieldTitle,
  CheckboxTileFieldInput,
} from './CheckboxTileFieldStyles';

function CheckboxTileField(props: ICheckboxTileFieldProps) {
  const { label = '?', input, onChange } = props;

  return (
    <CheckboxTileFieldContainer>
      <CheckboxTileFieldInput
        {...input}
        type="checkbox"
        onChange={(ev) => {
          if (onChange) onChange(ev);
          input?.onChange(ev);
        }}
      />
      <CheckboxTileFieldTitle data-tooltip={label}>
        <span>{label}</span>
      </CheckboxTileFieldTitle>
    </CheckboxTileFieldContainer>
  );
}

export default CheckboxTileField;
