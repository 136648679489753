import { Props } from 'react-select';
import { FieldRenderProps } from 'react-final-form';

const SelectTheme = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  MINI: 'mini',
} as const;
type ISelectTheme = typeof SelectTheme[keyof typeof SelectTheme];
interface ISelectStaticCustomProps extends Props {
  selectTheme: ISelectTheme
  required?: boolean
  name?: string
  caption?: string
  unmountClear?: boolean
  disableStaticFilter?: boolean
  prefixCreateLabel?: string
}
type ISelectStaticProps = Partial<FieldRenderProps<string | number, HTMLInputElement, ISelectStaticCustomProps>>;

export type {
  ISelectTheme,
  ISelectStaticCustomProps,
  ISelectStaticProps,
};

export {
  SelectTheme,
};
