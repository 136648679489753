import React, { useEffect, useState } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import { isNil, toPairs } from 'ramda';
import moment from 'moment';
import { ReactComponent as DownloadIcon } from 'media/icons/download.svg';
import useDispatch from 'hooks/redux/useDispatch';
import useSelector from 'hooks/redux/useSelector';
import FormModal from 'components/common/form/modal/FormModal';
import { FormModalType } from 'components/common/form/modal/FormModalTypes';
import { exportApi, exportSlice } from 'components/services/export/exportService';
import { useLocation } from 'react-router-dom';
import StateName from 'helpers/stateNames';
import ExportBlock from 'components/features/export/block/ExportBlock';
import ExportCompaniesTypesFields from 'components/features/fields/export/companiesTypes/ExportCompaniesTypesFields';
import ExportConfigFields from 'components/features/fields/export/config/ExportConfigFields';
import { IExportCRUDFieldsValues, exportCRUDSerializer } from 'helpers/formSerializers';
import { toastsSlice } from 'components/services/toasts/toastsService';
import { ToastItemType } from 'components/common/toasts/item/ToastsItemTypes';
import { ExportStatus } from 'helpers/export';
import useInterval from 'hooks/core/useInterval';
import SelectStatic from 'components/common/fields/select/static/SelectStatic';
import { SelectTheme } from 'components/common/fields/select/static/SelectStaticTypes';
import { ISelectOption } from 'components/common/fields/select/SelectTypes';
import { getInitOption } from 'helpers/select';
import SelectDynamic from 'components/common/fields/select/dynamic/SelectDynamic';
import { RequestAPI } from 'helpers/requests';
import ExportTooltip from './tooltip/ExportTooltip';

function Export() {
  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const [ hideGlobal, setHideGlobal ] = useState<boolean>(false);

  const authState = useSelector((state) => state[StateName.AUTH]);
  const isAuth = authState.verify;

  const exportState = useSelector((state) => state[StateName.EXPORT]);
  const { isOpen } = exportState;

  const { useConfigQuery, useGenerateMutation, useLastQuery } = exportApi;
  const {
    data: configData,
    isLoading: configIsLoading,
    isFetching: configIsFetching,
  } = useConfigQuery(undefined, { skip: !isOpen || !isAuth });
  const {
    data: lastExportData,
    isLoading: lastExportIsLoading,
    refetch: lastExportRefetch,
  } = useLastQuery(undefined, { skip: !isAuth });
  const [ generate, { isLoading: generateIsLoading } ] = useGenerateMutation();

  const configLoading = configIsLoading || configIsFetching;
  const isLoading = configLoading || generateIsLoading;

  const configList = configData?.config;
  const configListArr = isNil(configList) ? [] : toPairs(configList);
  const configAvailableFormats = configData?.availableFormats;
  const availableFormatsOptions = configAvailableFormats?.map((i) => getInitOption(i?.toUpperCase(), i)) as ISelectOption<string>[];

  const status = lastExportData?.status;
  const isInProgress = status === ExportStatus.IN_PROGRESS;
  const isFinished = status === ExportStatus.FINISHED;
  const finishedAt = lastExportData?.finishedAt;
  const finishedDifMinutes = finishedAt
    ? moment().diff(moment(finishedAt), 'minutes')
    : 0;
  const finishedMaxLeftMinutes = 120;
  const finishedLeftMinutes = finishedMaxLeftMinutes - finishedDifMinutes;
  const showTooltip = (isInProgress || (isFinished && (finishedDifMinutes < finishedMaxLeftMinutes))) && !lastExportIsLoading;
  const showTooltipGlobal = showTooltip && !hideGlobal && !isOpen;

  const refetchDelay = isFinished && showTooltip ? 100000 : null;

  useInterval(() => {
    lastExportRefetch();
  }, refetchDelay);

  useEffect(() => {
    if (isOpen && isFinished) {
      setHideGlobal(true);
    }
  }, [ isFinished, isOpen ]);

  useEffect(() => {
    const listener = (ev: KeyboardEvent) => {
      if (isAuth) {
        if (ev.altKey && ev.code === 'KeyE') {
          ev.preventDefault();
          dispatch(exportSlice.actions.toggle());
        }
        if (ev.code === 'Escape') {
          ev.preventDefault();
          dispatch(exportSlice.actions.close());
        }
      }
    };

    window.addEventListener('keydown', listener);

    return () => {
      window.removeEventListener('keydown', listener);
    };
  }, [ isAuth, isOpen, pathname ]);

  const onClose = () => dispatch(exportSlice.actions.close());

  const initialValues: Partial<IExportCRUDFieldsValues> = {
    format: availableFormatsOptions?.[0],
  };

  const onSubmit = (values: IExportCRUDFieldsValues) => {
    generate(exportCRUDSerializer(values))
      .then(() => {
        setHideGlobal(false);
        lastExportRefetch();
        onClose();
        dispatch(toastsSlice.actions.add({
          title: 'Подготовка к экспорту запущена',
          description: 'Пожалуйста подождите',
          type: ToastItemType.SUCCESS,
        }));
      })
      .catch(() => {
        dispatch(toastsSlice.actions.add({
          title: 'Неудача при подготовке к экспорту',
          description: 'Попробуйте еще раз',
          type: ToastItemType.ERROR,
        }));
      });
  };

  const formEl = isOpen ? (
    <FinalForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, ...rest }) => (
        <FormModal
          title="Экспорт"
          type={FormModalType.SECONDARY}
          handleSubmit={handleSubmit}
          onClose={onClose}
          isLoading={isLoading}
          submitButton={{ text: 'Экспорт', icon: DownloadIcon }}
          {...rest}
        >
          {showTooltip ? (
            <ExportBlock
              description={isFinished ? `Ссылка будет доступна ещё ~${finishedLeftMinutes} мин.` : null}
              withUnderline
            >
              <ExportTooltip isGlobal={false} />
            </ExportBlock>
          ) : null}
          <ExportBlock title="Тип компаний">
            <ExportCompaniesTypesFields />
          </ExportBlock>
          <ExportBlock title="Категория компаний">
            <Field
              component={SelectDynamic}
              selectTheme={SelectTheme.SECONDARY}
              api={RequestAPI.BUSINESS_TYPE}
              placeholder="Выберите тип"
              name="businessType"
              isClearable
              isSearchable
              isPagination
            />
          </ExportBlock>
          <ExportBlock title="Данные для экспорта">
            {configListArr?.map((item) => (
              <ExportConfigFields
                list={item}
                key={item[0]}
              />
            ))}
          </ExportBlock>
          <ExportBlock title="Формат экспорта">
            <Field
              component={SelectStatic}
              selectTheme={SelectTheme.SECONDARY}
              placeholder="Выберите тип"
              name="format"
              options={availableFormatsOptions}
              required
              isSearchable
            />
          </ExportBlock>
        </FormModal>
      )}
    />
  ) : null;

  const tooltipEl = showTooltipGlobal ? (
    <ExportTooltip isGlobal />
  ) : null;

  return (
    <>
      {formEl}
      {tooltipEl}
    </>
  );
}

export default Export;
