import styled from 'styled-components/macro';
import SearchResultsItem from 'components/features/search/results/item/SearchResultsItem';
import NameTile from 'components/common/nameTile/NameTile';

const SearchResultsCategoryContractsItem = styled(SearchResultsItem)`
  gap: 20px;
`;
const SearchResultsCategoryContractsItemTile = styled(NameTile)`
  width: 100%;
  margin-right: auto;
`;

export {
  SearchResultsCategoryContractsItem,
  SearchResultsCategoryContractsItemTile,
};
