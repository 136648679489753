import { IStatus, StatusState } from 'helpers/statuses';
import { ISelectOption } from 'components/common/fields/select/SelectTypes';

enum ProductStatusAlias {
  APPROVED = 'approved',
  REJECTED = 'rejected',
  PENDING = 'waiting_approve',
}

type IProductStatus = IStatus<ProductStatusAlias>;

const productStatuses: IProductStatus[] = [
  {
    label: {
      single: 'Одобрен',
      multi: 'Одобренные',
    },
    alias: ProductStatusAlias.APPROVED,
    state: StatusState.ACTIVE,
  },
  {
    label: {
      single: 'Отклонен',
      multi: 'Отклоненные',
    },
    alias: ProductStatusAlias.REJECTED,
    state: StatusState.INACTIVE,
  },
  {
    label: {
      single: 'В ожидании',
      multi: 'В ожидании',
    },
    alias: ProductStatusAlias.PENDING,
    state: StatusState.PENDING,
  },
];

enum ProductsPrintedAnswer {
  YES = '2',
  NO = '3',
}

const productStatusesOptions: ISelectOption[] = productStatuses?.map((item) => ({ label: item.label.multi, value: item.alias }));

const getProductsStatusOptionByValue = (value: ISelectOption['value']): ISelectOption | undefined => productStatusesOptions
  ?.find(({ value: v }) => v === value);

const productsPrintedOptions: ISelectOption[] = [
  {
    label: 'Да',
    value: ProductsPrintedAnswer.YES,
  },
  {
    label: 'Нет',
    value: ProductsPrintedAnswer.NO,
  },
];

const getProductsPrintedByValue = (value: ISelectOption['value']): ISelectOption | undefined => productsPrintedOptions
  ?.find(({ value: v }) => v === value);

export type {
  IProductStatus,
};
export {
  ProductStatusAlias,
  ProductsPrintedAnswer,
  productStatuses,
  productStatusesOptions,
  getProductsStatusOptionByValue,
  productsPrintedOptions,
  getProductsPrintedByValue,
};
