const ExportStatus = {
  IN_PROGRESS: 'in_progress',
  FINISHED: 'finished',
  DELETED: 'deleted',
  STOPPED: 'stopped',
} as const;
type IExportStatus = typeof ExportStatus[keyof typeof ExportStatus];

export type {
  IExportStatus,
};
export {
  ExportStatus,
};
