import { useState, useEffect } from 'react';

type IValue = string | number | undefined | null;

function useDebounce<V = IValue>(value: V, delay: number) {
  const [ debouncedValue, setDebouncedValue ] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [ value ]);

  return debouncedValue;
}

export default useDebounce;
