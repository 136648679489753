const LoaderSize = {
  SMALL: '18px',
  MEDIUM: '26px',
  LARGE: '50px',
} as const;
type ILoaderSize = typeof LoaderSize[keyof typeof LoaderSize];
interface ILoaderProps {
  size?: ILoaderSize
  isInvert?: boolean
  className?: string
}
interface ILoaderWrap {
  size: ILoaderProps['size']
  isInvert?: ILoaderProps['isInvert']
}

export {
  LoaderSize,
};
export type {
  ILoaderSize,
  ILoaderProps,
  ILoaderWrap,
};
