import styled from 'styled-components/macro';
import { IAvatarWrap } from './AvatarTypes';

const AvatarWrap = styled.div<IAvatarWrap>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  text-transform: uppercase;
  font-size: ${({ size, letterLength }) => `calc(${size} / ${letterLength === 1 ? 2 : 2.5})`};
  line-height: 1;
  font-weight: 600;
  letter-spacing: -1px;
  text-align: center;
  color: #515e68;
  background: ${({ theme, palette }) => theme.fixed.avatar[palette]};
  border-radius: 100%;
  & > svg {
    width: ${({ size }) => `calc(${size} / 2)`};
    height: ${({ size }) => `calc(${size} / 2)`};
  }
`;
const AvatarImage = styled.img.attrs({ alt: '' })`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
  border: ${({ theme }) => `1px solid ${theme.palette.main[200]}`};
`;

export {
  AvatarWrap,
  AvatarImage,
};
