import React, { useEffect, useState, MouseEvent } from 'react';
import ReactSelect, {
  ActionMeta,
  components as Components,
  ControlProps,
  InputProps,
  OptionProps,
  OnChangeValue,
} from 'react-select';
import ReactSelectCreatable from 'react-select/creatable';
import { useTheme } from 'styled-components/macro';
import Alt from 'helpers/alt';
import { is, isNil } from 'ramda';
import { generateCreatedOption, getOptionByValue } from 'helpers/select';
import { ISelectStaticProps, SelectTheme } from './SelectStaticTypes';
import { defaultStyles } from './SelectStaticStyles';

function Control(props: ControlProps) {
  const { children, ...rest } = props;

  const { selectProps: { placeholder } } = rest;

  return (
    <Components.Control {...rest}>
      {children}
      {placeholder ? (
        <label>{placeholder}</label>
      ) : null}
    </Components.Control>
  );
}

function Input(props: InputProps) {
  const { children, ...rest } = props;

  const { selectProps: { placeholder }, hasValue } = rest;

  const placeholderLastSymbol = `${placeholder}`.slice(-1);
  const isRequired = !hasValue && (placeholderLastSymbol === '*');

  return (
    <Components.Input {...rest} required={isRequired}>
      {children}
    </Components.Input>
  );
}

function Option(props: OptionProps) {
  const { children, ...rest } = props;

  const handleClick = (ev: MouseEvent<HTMLDivElement>) => ev.stopPropagation();

  return (
    <div onClick={handleClick}>
      <Components.Option {...rest}>
        {children}
      </Components.Option>
    </div>
  );
}

function Placeholder() {
  return null;
}

function SelectStatic(props: ISelectStaticProps) {
  const {
    selectTheme = SelectTheme.PRIMARY,
    placeholder: placeholderBase,
    isClearable = false,
    isSearchable = false,
    isCreatable = false,
    isLoading = false,
    isMulti = false,
    required = false,
    unmountClear = true,
    disableStaticFilter = false,
    defaultValue,
    prefixCreateLabel,
    onChange: onChangeBase,
    input,
    options,
    ...rest
  } = props;

  useEffect(() => () => {
    if (unmountClear) input?.onChange(undefined);
  }, []);

  const initValue = is(Object, defaultValue) ? defaultValue : getOptionByValue(options, defaultValue);
  const [ value, setValue ] = useState(null);

  useEffect(() => {
    if (!isNil(defaultValue) && !isLoading && is(Object, initValue)) {
      setValue(initValue);
    }
  }, [ isLoading ]);

  const theme = useTheme();

  const isPrimary = selectTheme === SelectTheme.PRIMARY;

  const requiredText = `${required ? ' *' : ''}`;
  const placeholder = `${placeholderBase ?? Alt.SELECT}${requiredText}`;

  const componentsBase = { Input, Option };
  const components = isPrimary ? { ...componentsBase, Control, Placeholder } : { ...componentsBase };

  const onChange = (option: OnChangeValue<any, boolean>, actionMeta?: ActionMeta<any>) => {
    setValue(option);
    if (onChangeBase) {
      onChangeBase(option, actionMeta);
    }
  };

  const getNewOptionData = (text: string) => generateCreatedOption(text, prefixCreateLabel);
  const isValidNewOption = (text: string) => !!text?.trim();

  const Select = isCreatable ? ReactSelectCreatable : ReactSelect;

  return (
    <Select
      components={components}
      onChange={onChange}
      styles={defaultStyles(theme, selectTheme)}
      placeholder={placeholder}
      isClearable={isClearable}
      isSearchable={isSearchable}
      isMulti={isMulti}
      blurInputOnSelect
      menuPlacement="auto"
      menuPosition="absolute"
      maxMenuHeight={300}
      value={value}
      isLoading={isLoading}
      options={options}
      filterOption={disableStaticFilter ? () => true : undefined}
      createOptionPosition={isCreatable ? 'first' : undefined}
      getNewOptionData={isCreatable ? getNewOptionData : undefined}
      isValidNewOption={isCreatable ? isValidNewOption : undefined}
      {...input}
      {...rest}
    />
  );
}

export default SelectStatic;
