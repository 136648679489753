import styled from 'styled-components/macro';
import { ISearchResultsItemWrapSC } from './SearchResultsItemTypes';

const SearchResultsItemWrap = styled.div<ISearchResultsItemWrapSC>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  flex-shrink: 0;
  height: 68px;
  cursor: ${({ hasClick }) => hasClick && 'pointer'};
  padding: 12px 16px;
  &:hover {
    background: ${({ theme, hasClick }) => hasClick && theme.palette.main[100]};
  }
`;

export {
  SearchResultsItemWrap,
};
