import { CompanyDetailAlias } from 'helpers/companies';
import { ContractDetailAlias } from 'helpers/contracts';
import { SettingsAlias, SettingsSubAlias } from 'helpers/settings';

const RoutePath = {
  ETC: '*',
  ERROR_NOT_FOUND: '/error/not-found',
  ERROR_SERVER: '/error/server',
  ERROR_NO_PERMISSION: '/error/no-permission',
  ROOT: '/',
  AUTH: '/auth',
  PROFILE: '/profile',
  COMPANIES: '/companies',
  COMPANIES_BY_STATUS: '/companies/s/%s',
  COMPANIES_DETAIL: '/companies/%s',
  COMPANIES_DETAIL_BY_ALIAS: '/companies/%s/%s',
  COMPANIES_INFO_MERGE: `/companies/%s/${CompanyDetailAlias.INFO}/merge`,
  COMPANIES_PRODUCTS_DETAIL: `/companies/%s/${CompanyDetailAlias.PRODUCTS}/%s`,
  COMPANIES_PRODUCTS_DETAIL_BY_ALIAS: `/companies/%s/${CompanyDetailAlias.PRODUCTS}/%s/%s`,
  COMPANIES_PRODUCTS_CREATE: `/companies/%s/${CompanyDetailAlias.PRODUCTS}/create`,
  COMPANIES_PRODUCTS_EDIT: `/companies/%s/${CompanyDetailAlias.PRODUCTS}/%s/edit`,
  COMPANIES_CONTRACTS_DETAIL: `/companies/%s/${CompanyDetailAlias.CONTRACTS}/%s`,
  COMPANIES_CONTRACTS_HISTORY: `/companies/%s/${CompanyDetailAlias.CONTRACTS}/history`,
  COMPANIES_CONTRACTS_REGISTER: `/companies/%s/${CompanyDetailAlias.CONTRACTS}/register`,
  COMPANIES_CONTRACTS_ACTIVATE: `/companies/%s/${CompanyDetailAlias.CONTRACTS}/%s/activate`,
  COMPANIES_CONTRACTS_COMMENT_CREATE: `/companies/%s/${CompanyDetailAlias.CONTRACTS}/%s/comment/create`,
  COMPANIES_CONTRACTS_PAY: `/companies/%s/${CompanyDetailAlias.CONTRACTS}/%s/pay`,
  COMPANIES_CONTRACTS_EDIT: `/companies/%s/${CompanyDetailAlias.CONTRACTS}/%s/edit`,
  COMPANIES_CONTRACTS_INVOICE_CREATE: `/companies/%s/${CompanyDetailAlias.CONTRACTS}/%s/invoices/create`,
  COMPANIES_CONTRACTS_INVOICE_EDIT: `/companies/%s/${CompanyDetailAlias.CONTRACTS}/%s/invoices/%s/edit`,
  COMPANIES_CONTRACTS_INVOICE_ACTIVATE: `/companies/%s/${CompanyDetailAlias.CONTRACTS}/%s/invoices/%s/activate`,
  COMPANIES_CONTRACTS_INVOICE_PAY: `/companies/%s/${CompanyDetailAlias.CONTRACTS}/%s/invoices/%s/pay`,
  COMPANIES_HISTORY_CREATE: `/companies/%s/${CompanyDetailAlias.HISTORY}/create`,
  COMPANIES_CREATE: '/companies/create',
  COMPANIES_EDIT: '/companies/%s/edit',
  CONTRACTS: '/contracts',
  CONTRACTS_BY_STATUS: '/contracts/s/%s',
  CONTRACTS_DETAIL: '/contracts/%s',
  CONTRACTS_DETAIL_BY_ALIAS: '/contracts/%s/%s',
  CONTRACTS_CREATE: '/contracts/create',
  CONTRACTS_EDIT: '/contracts/%s/edit',
  CONTRACTS_ACTIVATE: `/contracts/%s/${ContractDetailAlias.INFO}/activate`,
  CONTRACTS_PAY: `/contracts/%s/${ContractDetailAlias.INFO}/pay`,
  CONTRACTS_INVOICE_ACTIVATE: `/contracts/%s/${ContractDetailAlias.INVOICES}/%s/activate`,
  CONTRACTS_INVOICE_PAY: `/contracts/%s/${ContractDetailAlias.INVOICES}/%s/pay`,
  CONTRACTS_INVOICE_CREATE: `/contracts/%s/${ContractDetailAlias.INVOICES}/create`,
  CONTRACTS_INVOICE_EDIT: `/contracts/%s/${ContractDetailAlias.INVOICES}/%s/edit`,
  CONTRACTS_COMMENT_CREATE: `/contracts/%s/${ContractDetailAlias.INFO}/comment/create`,
  PRODUCTS: '/products',
  PRODUCTS_BY_STATUS: '/products/s/%s',
  PRODUCTS_CREATE: '/products/create',
  PRODUCTS_EDIT: '/products/%s/edit',
  STATISTICS: '/statistics',
  LOGS: '/logs',
  SETTINGS: '/settings',
  SETTINGS_BY_ALIAS: '/settings/%s',
  SETTINGS_BY_SUB_ALIAS: '/settings/%s/%s',
  SETTINGS_USERS_GROUPS: `/settings/${SettingsAlias.USERS}/${SettingsSubAlias.USERS_GROUPS}`,
  SETTINGS_USERS_GROUPS_CREATE: `/settings/${SettingsAlias.USERS}/${SettingsSubAlias.USERS_GROUPS}/create`,
  SETTINGS_USERS_GROUPS_EDIT: `/settings/${SettingsAlias.USERS}/${SettingsSubAlias.USERS_GROUPS}/%s/edit`,
  SETTINGS_USERS_USERS: `/settings/${SettingsAlias.USERS}/${SettingsSubAlias.USERS_USERS}`,
  SETTINGS_USERS_USERS_CREATE: `/settings/${SettingsAlias.USERS}/${SettingsSubAlias.USERS_USERS}/create`,
  SETTINGS_USERS_USERS_EDIT: `/settings/${SettingsAlias.USERS}/${SettingsSubAlias.USERS_USERS}/%s/edit`,
  SETTINGS_USERS_USERS_PASSWORD_EDIT: `/settings/${SettingsAlias.USERS}/${SettingsSubAlias.USERS_USERS}/%s/password/edit`,
  SETTINGS_BILLING_CONTRIBUTIONS: `/settings/${SettingsAlias.BILLING}/${SettingsSubAlias.BILLING_CONTRIBUTIONS}`,
  SETTINGS_BILLING_CONTRIBUTIONS_CREATE: `/settings/${SettingsAlias.BILLING}/${SettingsSubAlias.BILLING_CONTRIBUTIONS}/create`,
  SETTINGS_BILLING_CONTRIBUTIONS_EDIT: `/settings/${SettingsAlias.BILLING}/${SettingsSubAlias.BILLING_CONTRIBUTIONS}/%s/edit`,
  SETTINGS_BILLING_COMPANY_CATEGORIES: `/settings/${SettingsAlias.BILLING}/${SettingsSubAlias.BILLING_COMPANY_CATEGORIES}`,
  SETTINGS_BILLING_COMPANY_CATEGORIES_CREATE: `/settings/${SettingsAlias.BILLING}/${SettingsSubAlias.BILLING_COMPANY_CATEGORIES}/create`,
  SETTINGS_BILLING_COMPANY_CATEGORIES_EDIT: `/settings/${SettingsAlias.BILLING}/${SettingsSubAlias.BILLING_COMPANY_CATEGORIES}/%s/edit`,
  SETTINGS_GEO_COUNTRIES: `/settings/${SettingsAlias.GEO}/${SettingsSubAlias.GEO_COUNTRIES}`,
  SETTINGS_GEO_COUNTRIES_CREATE: `/settings/${SettingsAlias.GEO}/${SettingsSubAlias.GEO_COUNTRIES}/create`,
  SETTINGS_GEO_COUNTRIES_EDIT: `/settings/${SettingsAlias.GEO}/${SettingsSubAlias.GEO_COUNTRIES}/%s/edit`,
  SETTINGS_GEO_REGIONS: `/settings/${SettingsAlias.GEO}/${SettingsSubAlias.GEO_REGIONS}`,
  SETTINGS_GEO_REGIONS_CREATE: `/settings/${SettingsAlias.GEO}/${SettingsSubAlias.GEO_REGIONS}/create`,
  SETTINGS_GEO_REGIONS_EDIT: `/settings/${SettingsAlias.GEO}/${SettingsSubAlias.GEO_REGIONS}/%s/edit`,
  SETTINGS_GEO_CITIES: `/settings/${SettingsAlias.GEO}/${SettingsSubAlias.GEO_CITIES}`,
  SETTINGS_GEO_CITIES_CREATE: `/settings/${SettingsAlias.GEO}/${SettingsSubAlias.GEO_CITIES}/create`,
  SETTINGS_GEO_CITIES_EDIT: `/settings/${SettingsAlias.GEO}/${SettingsSubAlias.GEO_CITIES}/%s/edit`,
  SETTINGS_GEO_DISTRICTS: `/settings/${SettingsAlias.GEO}/${SettingsSubAlias.GEO_DISTRICTS}`,
  SETTINGS_GEO_DISTRICTS_CREATE: `/settings/${SettingsAlias.GEO}/${SettingsSubAlias.GEO_DISTRICTS}/create`,
  SETTINGS_GEO_DISTRICTS_EDIT: `/settings/${SettingsAlias.GEO}/${SettingsSubAlias.GEO_DISTRICTS}/%s/edit`,
  SETTINGS_GEO_BRANCHES: `/settings/${SettingsAlias.GEO}/${SettingsSubAlias.GEO_BRANCHES}`,
  SETTINGS_GEO_BRANCHES_CREATE: `/settings/${SettingsAlias.GEO}/${SettingsSubAlias.GEO_BRANCHES}/create`,
  SETTINGS_GEO_BRANCHES_EDIT: `/settings/${SettingsAlias.GEO}/${SettingsSubAlias.GEO_BRANCHES}/%s/edit`,
  SETTINGS_SYSTEM_AGREEMENT: `/settings/${SettingsAlias.SYSTEM}/${SettingsSubAlias.SYSTEM_AGREEMENT}`,
  SETTINGS_SYSTEM_AGREEMENT_EDIT: `/settings/${SettingsAlias.SYSTEM}/${SettingsSubAlias.SYSTEM_AGREEMENT}/edit`,
  SETTINGS_SYSTEM_CONTRACTS: `/settings/${SettingsAlias.SYSTEM}/${SettingsSubAlias.SYSTEM_CONTRACTS}`,
  SETTINGS_SYSTEM_CONTRACTS_CREATE: `/settings/${SettingsAlias.SYSTEM}/${SettingsSubAlias.SYSTEM_CONTRACTS}/create`,
  SETTINGS_SYSTEM_CONTRACTS_EDIT: `/settings/${SettingsAlias.SYSTEM}/${SettingsSubAlias.SYSTEM_CONTRACTS}/%s/edit`,
  SETTINGS_SYSTEM_OWNERSHIPS: `/settings/${SettingsAlias.SYSTEM}/${SettingsSubAlias.SYSTEM_OWNERSHIPS}`,
  SETTINGS_SYSTEM_OWNERSHIPS_CREATE: `/settings/${SettingsAlias.SYSTEM}/${SettingsSubAlias.SYSTEM_OWNERSHIPS}/create`,
  SETTINGS_SYSTEM_OWNERSHIPS_EDIT: `/settings/${SettingsAlias.SYSTEM}/${SettingsSubAlias.SYSTEM_OWNERSHIPS}/%s/edit`,
  SETTINGS_SYSTEM_BUSINESS_ACTIVITIES: `/settings/${SettingsAlias.SYSTEM}/${SettingsSubAlias.SYSTEM_BUSINESS_ACTIVITIES}`,
  SETTINGS_SYSTEM_BUSINESS_ACTIVITIES_CREATE: `/settings/${SettingsAlias.SYSTEM}/${SettingsSubAlias.SYSTEM_BUSINESS_ACTIVITIES}/create`,
  SETTINGS_SYSTEM_BUSINESS_ACTIVITIES_EDIT: `/settings/${SettingsAlias.SYSTEM}/${SettingsSubAlias.SYSTEM_BUSINESS_ACTIVITIES}/%s/edit`,
  SETTINGS_SYSTEM_ECONOMIC_ACTIVITIES: `/settings/${SettingsAlias.SYSTEM}/${SettingsSubAlias.SYSTEM_ECONOMIC_ACTIVITIES}`,
  SETTINGS_SYSTEM_ECONOMIC_ACTIVITIES_CREATE: `/settings/${SettingsAlias.SYSTEM}/${SettingsSubAlias.SYSTEM_ECONOMIC_ACTIVITIES}/create`,
  SETTINGS_SYSTEM_ECONOMIC_ACTIVITIES_EDIT: `/settings/${SettingsAlias.SYSTEM}/${SettingsSubAlias.SYSTEM_ECONOMIC_ACTIVITIES}/%s/edit`,
  SETTINGS_SYSTEM_PRODUCT_TYPES: `/settings/${SettingsAlias.SYSTEM}/${SettingsSubAlias.SYSTEM_PRODUCT_TYPES}`,
  SETTINGS_SYSTEM_BANKS: `/settings/${SettingsAlias.SYSTEM}/${SettingsSubAlias.SYSTEM_BANKS}`,
  SETTINGS_SYSTEM_BANKS_CREATE: `/settings/${SettingsAlias.SYSTEM}/${SettingsSubAlias.SYSTEM_BANKS}/create`,
  SETTINGS_SYSTEM_BANKS_EDIT: `/settings/${SettingsAlias.SYSTEM}/${SettingsSubAlias.SYSTEM_BANKS}/%s/edit`,
} as const;
type IRoutePath = typeof RoutePath[keyof typeof RoutePath];

export type {
  IRoutePath,
};
export default RoutePath;
