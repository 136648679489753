import { useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { IPortalProps } from 'components/common/portal/PortalTypes';

function Portal(props: IPortalProps) {
  const { children, selector } = props;

  const [ mounted, setMounted ] = useState<boolean>(false);

  const ref = useRef<HTMLElement | null>(null);

  const refCurrent = ref.current || null;

  useLayoutEffect(() => {
    ref.current = document.querySelector(`#${selector}`);
    setMounted(true);
  }, [ selector ]);

  return (mounted && refCurrent) ? createPortal(children, refCurrent) : null;
}

export default Portal;
